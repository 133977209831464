import React, { useState } from 'react';
import useGlobal from '../store';
import LoadingSpinner from '../components/UI/LoadingSpinner';

const AltidollarsFixer = () => {
  const [globalState, globalActions] = useGlobal();
  const { text, channels, agentEmail } = globalState;
  const [loading, setLoading] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);

    const email = encodeURIComponent(e.target.email.value);
    const channel = e.target.channel.value;

    const response = await globalActions.db.postAltidollarsBalanceFix({
      agent: agentEmail.split('@')[0],
      channel,
      customer_email: email
    });
    console.log(response.status);
    if (response.status === 'SUCCESS') {
      globalActions.notification.showMessage(text.altidollars_fixer.success, 'success');
    } else {
      globalActions.notification.showMessage(text.altidollars_fixer.fail + response.error, 'error');
    }

    setLoading(false);
  }

  return (
    <>
      <main className="container">
        <section className="section">
          <h1 className="title is-4 has-text-left">{text.altidollars_fixer.title}</h1>

          {!loading && (
            <div className="columns is-centered mt-3">
              <div className="column is-6-widescreen is-8-desktop is-8-tablet">
                <div className="card">
                  <div className="card-header">
                    <p className="card-header-title">{text.altidollars_fixer.subtitle}</p>
                  </div>
                  <div className="card-content">
                    <form onSubmit={handleSubmit}>
                      <div className="field has-addons has-addons-centered">
                        <div className="control is-expanded">
                          <input
                            className="input"
                            name="email"
                            type="email"
                            required
                            autoFocus={true}
                            placeholder={text.altidollars_fixer.customer_email}
                          />
                        </div>
                        <div className="control">
                          <div className="select">
                            <select name="channel">
                              {Object.keys(channels).map(name => {
                                return channels[name].store_credits_policy ? (
                                  <option key={channels[name].channel_id} value={name}>
                                    {name}
                                  </option>
                                ) : null;
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="control">
                          <button className="button is-link">{text.altidollars_fixer.submit}</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          )}

          {loading && <LoadingSpinner />}
        </section>
      </main>
    </>
  );
};

export default AltidollarsFixer;
