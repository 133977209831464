import React, { useState, useEffect } from 'react';
import useGlobal from '../../store';
import ProductImg from '../UI/ProductImg';
import { formatArticleTitle, isFinalSale } from '../../utils/formatters';

const ItemsSelectionItem = ({ item, mode }) => {
  const [globalState, globalActions] = useGlobal();
  const { lang, text, priceMatches, orders } = globalState;
  const [lineItemCurrentPrice, setLineItemCurrentPrice] = useState(null);

  const orderItems = orders[0].line_items.find(el => el.sku === item.sku);
  const productTags = orderItems.tags ? orderItems.tags.split(', ') : '';

  useEffect(() => {
    if (priceMatches.length > 0 && globalActions.priceMatch.getLineItemCurrentPrice(item, priceMatches)) {
      setLineItemCurrentPrice(globalActions.priceMatch.getLineItemCurrentPrice(item, priceMatches));
    } else {
      setLineItemCurrentPrice(item.paid_price);
    }
  }, [item, priceMatches, globalActions]);

  const selectItem = async sku => {
    if (mode === 'price-match') {
      globalActions.items.addSelectedItem(sku, mode, lineItemCurrentPrice);
    } else {
      globalActions.items.addSelectedItem(sku, mode);
    }
  };

  return (
    <li className="items__selection__item" key={item.uniq_id} data-id={item.uniq_id}>
      <div onClick={() => selectItem(item.sku)} className="items__selection__item-content">
        <ProductImg item={item} size="70px" margin="0.25em 0.5em 0.25em 0" />
        <p className={`${isFinalSale(productTags, mode) ? 'has-background-orange px-2 py-1 mb-1 mr-1' : ''}`}>
          {mode === 'return-creating' ? (
            <b>
              {text.items_selection.order}: {item.channel}-{item.order_name}
            </b>
          ) : (
            <b>
              {text.items_selection.paid_price}: ${lineItemCurrentPrice ? lineItemCurrentPrice : item.paid_price}
            </b>
          )}
          {' - '}
          {formatArticleTitle(lang, item)} - {item.sku}
        </p>
      </div>
    </li>
  );
};

export default ItemsSelectionItem;
