import React from 'react';
import useGlobal from '../../store';

const PriceMatchDiscountCode = () => {
  const [globalState, globalActions] = useGlobal();
  const { text, discountCode } = globalState;

  return (
    <div className="price-match-form__client-info has-text-left">
      <h2 className="label">{text.price_match_form.discount_code}</h2>
      <div className="price-match-form__client-info__content">
        <div className="field">
          <div className="control">
            <input
              onChange={e => {
                discountCode.title = e.target.value;
                globalActions.utils.updateStateProperty('discountCode', discountCode);
              }}
              className="input"
              type="text"
              placeholder={text.price_match_form.discount_name_placeholder}
              value={discountCode.title}
            />
          </div>
        </div>

        <div className="field">
          <p className="control">
            <input
              onChange={e => {
                discountCode.value = e.target.value.length > 0 ? parseInt(e.target.value) : '';
                globalActions.utils.updateStateProperty('discountCode', discountCode);
              }}
              className="input"
              type="number"
              placeholder={text.price_match_form.discount_nb_placeholder}
              value={discountCode.value}
            />
          </p>
          {/*  <p className="control">
            <span className="select">
              <select
                onChange={e => {
                  discountCode.value_type = e.target.value;
                  globalActions.utils.updateStateProperty(
                    "discountCode",
                    discountCode
                  );
                }}
                value={discountCode.value_type}
              >
                <option value="percentage">%</option>
                <option value="amount">$</option>
              </select>
            </span>
          </p> */}
        </div>
      </div>
    </div>
  );
};

export default PriceMatchDiscountCode;
