import React, { useState, useEffect } from 'react';
import useGlobal from '../../store';
import PriceMatchClientInfo from './PriceMatchClientInfo';
import ItemsSelection from '../ItemsSelection';
import PriceMatchSkus from './PriceMatchSkus';
import PriceMatchExepectedRefund from './PriceMatchExpectedRefund';
import PriceMatchPast from './PriceMatchPast';
import PriceMatchDiscountCode from './PriceMatchDiscountCode';
import PriceMatchNote from './PriceMatchNote';
import Dialog from '../UI/Dialogs';

const PriceMatch = () => {
  const [globalState, globalActions] = useGlobal();
  const {
    text,
    channels,
    orders,
    selectedItems,
    creditCardException,
    notShippedYet,
    requestStatus,
    discountCode,
    priceMatchNote,
    priceMatches
  } = globalState;

  const [channel, setChannel] = useState(channels['ALS']);

  useEffect(() => {
    if (orders.length > 0) {
      setChannel(channels[orders[0].channel]);
    }
  }, [orders, channels]);

  function toggleCreditCardException(e) {
    e.preventDefault();
    globalActions.priceMatch.toggleCreditCardException();
  }

  function submitPriceMatchForm() {
    if (selectedItems.length === 0) {
      globalActions.notification.showMessage(text.price_match_form.error_no_items, 'error');
    } else if (selectedItems.find(item => !item.requested_price)) {
      globalActions.notification.showMessage(text.price_match_form.error_invalid_price, 'error');
    } else if (selectedItems.find(item => item.price_match_type === 'external' && item.competitor_url === '')) {
      globalActions.notification.showMessage(text.price_match_form.error_missing_competitor_info, 'error');
    } else if (
      (discountCode.title.length > 0 && discountCode.value === '') ||
      (discountCode.value.length > 0 && discountCode.title === '')
    ) {
      globalActions.notification.showMessage(text.price_match_form.error_missing_coupon_info, 'error');
    } else if (discountCode.title !== '' && selectedItems.every(item => !item.discount)) {
      globalActions.notification.showMessage(text.price_match_form.error_apply_coupon, 'error');
    } else if (selectedItems.find(item => item.line_item_current_price - item.requested_price <= 0)) {
      globalActions.notification.showMessage(text.price_match_form.error_negative_amount, 'error');
    } else if (priceMatchNote.length === 0) {
      globalActions.notification.showMessage(text.price_match_form.error_missing_note, 'error');
    } else {
      globalActions.notification.showDialog(
        text.price_match_form.confirm,
        `${text.price_match_form.submit_price_match}${
          selectedItems.find(item => item.requested_price < item.paid_price * 0.4)
            ? '\n ' + text.price_match_form.error_price_too_low
            : ''
        }`,
        async () => {
          const response = await globalActions.priceMatch.processPriceMatchObject();

          if (response.status === 'SUCCESS') {
            globalActions.utils.clearProcessStates();
            globalActions.notification.showMessage(text.price_match_form.success, 'success');
            setTimeout(() => {
              window.location = /price-matches/ + response.insertedId;
            }, 1500);
          } else {
            globalActions.notification.showMessage(text.price_match_form.fail, 'error', response.message);
          }
        }
      );
    }
  }

  return (
    <>
      <div className="columns">
        <div className="column is-4 has-text-left">
          <ItemsSelection mode="price-match" />
          {requestStatus !== 'LOADING' && channel.membership_policy?.has_membership && <PriceMatchClientInfo />}
          <PriceMatchDiscountCode />
          <PriceMatchNote />
        </div>

        <div className="column is-8 ">
          <>
            <PriceMatchSkus />

            {requestStatus !== 'LOADING' && selectedItems.length > 0 && <PriceMatchExepectedRefund />}

            {priceMatches.length > 0 && <PriceMatchPast />}
          </>

          {requestStatus !== 'INITIAL' && requestStatus !== 'LOADING' && priceMatches.length === 0 && (
            <p className="price-match-form__no-past-pm">{text.price_match_form.no_past_pm}</p>
          )}
        </div>
      </div>

      <div className="credit-card-exception">
        {(notShippedYet || channel.price_match_policy.always_money) && (
          <p className="label">{text.price_match_form.order_not_shipped}</p>
        )}

        {!notShippedYet && !channel.price_match_policy.always_money && (
          <label className="checkbox-container" onClick={e => toggleCreditCardException(e)}>
            <input name="credit-card-exception" type="checkbox" readOnly checked={creditCardException} />
            <span className="checkmark" />
            {text.price_match_form.credit_card_refund}
          </label>
        )}
      </div>

      <button
        onClick={() => {
          globalActions.utils.clearProcessStates();
          setChannel(channels['ALS']);

          const $orderNameInput = document.querySelector('[name=order_name]');
          if ($orderNameInput) {
            $orderNameInput.value = '';
          }

          document.querySelector('.price-match-form__note .textarea').value = '';
        }}
        className="button is-dark is-medium"
      >
        {text.price_match_form.void}
      </button>
      <button onClick={submitPriceMatchForm} className="button is-link is-medium price-match-form__submit">
        {text.price_match_form.submit}
      </button>
      <Dialog />
    </>
  );
};

export default PriceMatch;
