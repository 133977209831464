import React, { useState } from 'react';
import useGlobal from '../../store';
import LoadingSpinner from '../UI/LoadingSpinner';

const ProcessingSearchForm = ({ handleCurrentChannel }) => {
  const [globalState, globalActions] = useGlobal();
  const { text, tracking, orders, requestStatus, processing, channels } = globalState;
  const [channel, setChannel] = useState('ALS'); // Just to set Select Box default value
  const [loading, setLoading] = useState(false);

  async function fetchOrderPerReturn(returnsViaTracking) {
    let orders;
    await Promise.all(
      returnsViaTracking.map(async returnObj => {
        await globalActions.db.getPriceMatches({ key: 'order', value: returnObj.order_name }, returnObj.channel);
        return await globalActions.db.getOrders(returnObj.order_name, returnObj.channel);
      })
    ).then(values => {
      globalActions.items.setAvailableItems('processing');
      orders = values.flat();
    });
    return orders;
  }

  async function fetchReturnsViaBundleIDs(returnsViaOrderName) {
    const returnsViaBundleIDs = await Promise.all(
      returnsViaOrderName.map(async returnObj => {
        if (returnObj.bundle_id) {
          const returnObjects = await globalActions.db.getReturns({
            key: 'bundle',
            value: returnObj.bundle_id
          });
          return returnObjects;
        } else {
          return returnObj;
        }
      })
    ).then(values => values);

    return returnsViaBundleIDs;
  }

  function validateOrderCreationThreshold(orders) {
    const now = new Date();
    const daysToReturn = Number(process.env.REACT_APP_RETURN_PERIOD_IN_DAYS);
    if (!daysToReturn) {
      throw new Error('Return period is not configures');
    }
    const ordersDays = orders.map(order => {
      const creationDate = new Date(order.created_at);
      const daysSinceOrderCreation = Math.round((now - creationDate) / (1000 * 60 * 60 * 24));
      return {
        order: order.order_number,
        days: daysSinceOrderCreation,
        canReturn: daysSinceOrderCreation <= daysToReturn,
      };
    });

    return ordersDays.some(el => !el.canReturn) ? ordersDays : null;
  }

  function displayPopUp(returnObj, orders) {
    let popUpContent = [];

    if (returnObj) {
      const receptionNote = returnObj.reception_note;
      const customerTags = returnObj.customer_tags;
      if (receptionNote && receptionNote.length > 0) {
        popUpContent.push(receptionNote);
      }

      if (customerTags && customerTags.includes('RNC')) {
        popUpContent.push(text.return_processing.rnc);
      }
    }

    const beyondReturnPeriod = validateOrderCreationThreshold(orders);
    if (beyondReturnPeriod) {
      beyondReturnPeriod.forEach(el => {
        popUpContent.push(
          `${text.return_processing.order_beyond_return_days_a} ${el.order} ${text.return_processing.order_beyond_return_days_b} ${el.days} ${text.return_processing.order_beyond_return_days_c}`
        );
      });
    }

    popUpContent.length > 0 && globalActions.notification.showDialog('Reception note', popUpContent.join(' | '));
  }

  async function getReturnsAndOrdersViaOrderName(orderName, channel) {
    globalActions.notification.hideMessage();
    const returnsViaOrderName = await globalActions.db.getReturns(
      {
        key: 'order',
        value: orderName
      },
      channel
    );

    if (returnsViaOrderName.length > 0) {
      const returnsViaBundleIDs = await fetchReturnsViaBundleIDs(returnsViaOrderName);
      const returns = returnsViaBundleIDs.flat();

      const orders = await fetchOrderPerReturn(returns);
      orders &&
        displayPopUp(
          returns.find(returnObj => returnObj.status === 'open' || returnObj.status === 'received'),
          orders
        );
    } else {
      const orders = await globalActions.db.getOrders(orderName, channel);
      if (orders.length > 0) {
        globalActions.items.setAvailableItems('processing', channel);
        displayPopUp(false, orders);
      } else {
        globalActions.utils.clearProcessStates();
        globalActions.notification.showMessage(text.return_processing.error_no_order_found, 'error');
      }
      await globalActions.db.getPriceMatches({ key: 'order', value: orderName }, channel);
    }
  }

  async function submitSearchTracking(e) {
    e.preventDefault();

    setLoading(true);

    const tracking = globalActions.utils.parseCarrierTrackingBarcode(e.target.tracking.value);
    globalActions.utils.updateStateProperty('carrier', tracking.carrier);

    if (tracking.number && tracking.number.length > 0) {
      const returnsViaTracking = await globalActions.db.getReturns({
        key: 'tracking',
        value: tracking.number
      });

      if (returnsViaTracking.length > 0) {
        globalActions.processing.setTracking({
          tracking_number: tracking.number
        });

        let orders = await fetchOrderPerReturn(returnsViaTracking);

        if (orders?.length > 0) {
          orders && displayPopUp(returnsViaTracking[0], orders);
          handleCurrentChannel(orders[0].channel);
        } else if (orders && orders.length === 0) {
          globalActions.notification.showMessage(text.return_processing.received_package_found, 'success');
        }
      } else {
        const bundleReturnObjects = await globalActions.db.getReturns({
          key: 'bundle_tracking',
          value: tracking.number
        });

        if (bundleReturnObjects?.length > 0) {
          globalActions.processing.setTracking({
            tracking_number: tracking.number
          });

          const orders = await fetchOrderPerReturn(bundleReturnObjects);

          if (orders?.length > 0) {
            orders && displayPopUp(bundleReturnObjects[0], orders);
          } else if (orders && orders.length === 0) {
            globalActions.notification.showMessage(text.return_processing.received_package_found, 'success');
          }
        } else {
          const trackingObj = await globalActions.db.getTracking(tracking.number);

          if (trackingObj) {
            getReturnsAndOrdersViaOrderName(trackingObj.order_name, trackingObj.channel);
            handleCurrentChannel(channel);
          } else {
            globalActions.notification.showMessage(text.return_processing.error_tracking_not_found, 'error');
          }
        }
      }
    } else {
      globalActions.notification.showMessage(text.return_processing.error_please_enter_tracking, 'error');
    }

    setLoading(false);
    const $orderInput = document.querySelector('[name="order_name"]');
    if ($orderInput) $orderInput.focus();
  }

  function submitSearchOrder(e) {
    e.preventDefault();
    handleCurrentChannel(channel);
    setLoading(true);

    const orderName = e.target.order_name.value;

    if (orderName.length > 0 && channel.length > 0) {
      getReturnsAndOrdersViaOrderName(orderName, channel);
    } else {
      globalActions.notification.showMessage(text.return_processing.error_empty_input, 'error');
    }

    setLoading(false);
  }

  if (loading || requestStatus === 'LOADING' || processing) return <LoadingSpinner margin="0 0 1em 0" />;

  return (
    <>
      {orders.length === 0 && !tracking && (
        <form onSubmit={submitSearchTracking}>
          <div className="field has-addons has-addons-centered">
            <div className="control">
              <input
                className="input"
                name="tracking"
                placeholder={text.return_processing.tracking_number}
                autoComplete="off"
                autoFocus={!loading}
              />
            </div>
            <div className="control">
              <button className="button" type="submit">
                {text.return_processing.search}
              </button>
            </div>
          </div>
        </form>
      )}

      {tracking && tracking.tracking_number && (
        <p className="has-text-weight-bold">
          {text.return_processing.tracking_number}: {tracking.tracking_number}
        </p>
      )}

      {orders.length === 1 && (
        <p className="has-text-weight-bold">
          {text.return_processing.order}: {orders[0].channel} {orders[0].order_number}
        </p>
      )}

      {orders.length > 1 && (
        <div className="processing__title has-text-weight-bold">
          <p>{text.return_processing.order}s: </p>
          <ul>
            {orders.map(order => (
              <li key={order._id}>
                {order.channel} {order.order_number}{' '}
              </li>
            ))}
          </ul>
        </div>
      )}

      {orders.length === 0 && tracking && tracking.tracking_number.length > 0 && (
        <form onSubmit={submitSearchOrder}>
          <div className="field has-addons has-addons-centered">
            <div className="control">
              <input
                className="input"
                name="order_name"
                placeholder={text.return_processing.order_input_placeholder}
                autoComplete="off"
              />
            </div>
            <div className="control">
              <div className="select">
                <select value={channel} onChange={e => setChannel(e.target.value)}>
                  {Object.keys(channels).map(name => (
                    <option key={channels[name].channel_id} value={name}>
                      {name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="control">
              <button className="button" type="submit">
                {text.return_processing.search}
              </button>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default ProcessingSearchForm;
