import React from 'react';
import useGlobal from '../../store';

const ObjectsTableFailsWarning = () => {
  const [globalState, globalActions] = useGlobal();
  const { text, listTags } = globalState;

  function updateList(name, value) {
    globalActions.utils.updateStateProperty('objectsQueryFilters', [])
    globalActions.objectsList.updateObjects([{ name, value, type: 'value' }], 'returns');
  }

  return (
    <>
      {listTags && (
        <div className="objects-table__fails-warning has-text-left">
          {listTags.refundFails.total > 0 && (
            <button onClick={() => updateList('refunded', 'failed')} className="button is-danger">
              {text.objects_table.refund_fails_pending} ({listTags.refundFails.total})
            </button>
          )}

          {listTags.restockFails.total > 0 && (
            <button onClick={() => updateList('restocked', 'failed')} className="button is-danger">
              {text.objects_table.restock_fails_pending} ({listTags.restockFails.total})
            </button>
          )}

          {listTags.exceptions.total > 0 && (
            <button onClick={() => updateList('status', 'exception')} className="button is-orange">
              {text.objects_table.exceptions_pending} ({listTags.exceptions.total})
            </button>
          )}
        </div>
      )}
    </>
  );
};

export default ObjectsTableFailsWarning;
