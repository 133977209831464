import React from 'react';
import useGlobal from '../../store';

const PriceMatchClientInfo = () => {
  const [globalState, globalActions] = useGlobal();
  const { text, membership } = globalState;

  return (
    <div className="price-match-form__client-info has-text-left">
      <h2 className="label">{text.price_match_form.membership_status}</h2>
      {membership !== null && (
        <>
          <div className="field">
            <div className="control">
              <div className="select">
                <select
                  onChange={e => {
                    e.preventDefault();
                    const status = e.target.value === 'member' ? true : false;
                    globalActions.utils.updateStateProperty('membership', status);
                  }}
                  value={membership ? 'member' : 'non-member'}
                >
                  <option value="non-member">{text.price_match_form.non_member}</option>
                  <option value="member">{text.price_match_form.member}</option>
                </select>
              </div>
            </div>
          </div>
          <p className="has-text-grey is-size-7">{text.price_match_form.member_status_note}</p>
        </>
      )}
    </div>
  );
};

export default PriceMatchClientInfo;
