import React, { useState } from 'react';
import { useAuth0 } from '../authService/react-auth0-wrapper';
import useGlobal from '../store';
import userPermissions from '../utils/userPermissions';
import { NavLink } from 'react-router-dom';
import Logo from '../assets/images/cerberus_logo.png';

const Navbar = () => {
  const { isAuthenticated, user, logout, accessToken } = useAuth0();
  const [globalState, globalActions] = useGlobal();
  const { text, lang } = globalState;
  const [navbarCollapsed, setNavbarCollapsed] = useState(false);
  const [returnsMenu, toggleReturnsMenu] = useState(false);
  const [priceMatchesMenu, togglePriceMatchesMenu] = useState(false);
  const [toolsMenu, toggleToolsMenu] = useState(false);
  const [moderationMenu, toggleModerationMenu] = useState(false);
  const [bulkStoreCreditMenu, toggleBulkStoreCreditMenu] = useState(false);

  return (
    <header className={process.env.REACT_APP_ENDPOINT.includes('staging') ? 'staging-bgcolor' : ''}>
      <nav
        className={`navbar is-size-65 container ${
          process.env.REACT_APP_ENDPOINT.includes('staging') ? 'staging-bgcolor' : ''
        }`}
        role="navigation"
        aria-label="main navigation"
      >
        {isAuthenticated && (
          <>
            <div className="navbar-brand">
              <NavLink onClick={() => setNavbarCollapsed(false)} to="/" className="is-flex">
                <img className="navbar__logo navbar-item" src={Logo} alt="Cerberus logo" />

                <h1 className="navbar__title navbar-item title is-6">
                  Cerberus
                  {process.env.REACT_APP_ENDPOINT.includes('staging') && (
                    <span className="navbar__title__version">&nbsp;STAGING</span>
                  )}
                  <span className="navbar__title__version">&nbsp;v 1.15.1</span>
                </h1>
              </NavLink>

              <span
                onClick={() => setNavbarCollapsed(!navbarCollapsed)}
                role="button"
                className={`navbar-burger burger ${navbarCollapsed ? 'is-active' : null}`}
                aria-label="menu"
                aria-expanded="false"
                data-target="navbarBasicExample"
              >
                <span aria-hidden="true" />
                <span aria-hidden="true" />
                <span aria-hidden="true" />
              </span>
            </div>

            <div className={`navbar-menu ${navbarCollapsed ? 'is-active' : null}`}>
              {userPermissions(accessToken, ['admin', 'cx_agent', 'cx_manager', 'return_manager', 'return_agent']) && (
                <>
                  <div
                    onClick={() => toggleReturnsMenu(!returnsMenu)}
                    className={`navbar-item has-dropdown ${returnsMenu ? 'is-active' : null}`}
                  >
                    <div className="navbar-link">{text.navbar.returns}</div>
                    <div className="navbar-dropdown">
                      <NavLink onClick={() => setNavbarCollapsed(false)} className="navbar-item" to="/returns">
                        {text.navbar.returns_list}
                      </NavLink>
                      {userPermissions(accessToken, ['admin', 'cx_agent', 'cx_manager']) && (
                        <NavLink
                          onClick={() => setNavbarCollapsed(false)}
                          className="navbar-item"
                          to="/return-creation"
                        >
                          {text.navbar.return_form}
                        </NavLink>
                      )}
                      {userPermissions(accessToken, ['admin', 'cx_agent', 'cx_manager']) && (
                        <NavLink
                          onClick={() => setNavbarCollapsed(false)}
                          className="navbar-item"
                          to="/return-reception-note"
                        >
                          {text.navbar.return_reception_note}
                        </NavLink>
                      )}
                      {userPermissions(accessToken, ['admin', 'return_agent', 'return_manager']) && (
                        <>
                          <NavLink
                            onClick={() => setNavbarCollapsed(false)}
                            className="navbar-item"
                            to="/return-receiving"
                          >
                            {text.navbar.return_receiving}
                          </NavLink>
                          <NavLink
                            onClick={() => setNavbarCollapsed(false)}
                            className="navbar-item"
                            to="/return-processing"
                          >
                            {text.navbar.return_processing}
                          </NavLink>
                          {/* <NavLink
                            onClick={() => setNavbarCollapsed(false)}
                            className="navbar-item"
                            to="/return-reports"
                          >
                            {text.navbar.returns_reports}
                          </NavLink> */}
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}

              {userPermissions(accessToken, ['admin', 'cx_manager', 'cx_agent']) && (
                <div
                  onClick={() => togglePriceMatchesMenu(!priceMatchesMenu)}
                  className={`navbar-item has-dropdown ${priceMatchesMenu ? 'is-active' : null}`}
                >
                  <div className="navbar-link">{text.navbar.price_matches}</div>
                  <div className="navbar-dropdown">
                    <NavLink onClick={() => setNavbarCollapsed(false)} className="navbar-item" to="/price-matches">
                      {text.navbar.price_matches_list}
                    </NavLink>
                    <NavLink onClick={() => setNavbarCollapsed(false)} className="navbar-item" to="/price-match">
                      {text.navbar.price_match_form}
                    </NavLink>
                  </div>
                </div>
              )}

              {userPermissions(accessToken, [
                'admin',
                'cx_agent',
                'cx_manager',
                'marketing_manager',
                'marketing_agent'
              ]) && (
                <div
                  onClick={() => toggleBulkStoreCreditMenu(!bulkStoreCreditMenu)}
                  className={`navbar-item has-dropdown ${bulkStoreCreditMenu ? 'is-active' : null}`}
                >
                  <div className="navbar-link">{text.navbar.store_credits}</div>

                  <div className="navbar-dropdown">
                    {userPermissions(accessToken, ['admin', 'marketing_manager', 'marketing_agent']) && (
                      <>
                        <NavLink
                          onClick={() => setNavbarCollapsed(false)}
                          className="navbar-item"
                          to="/store-credits-bulk-actions-list"
                        >
                          {text.navbar.batch_store_credits_list}
                        </NavLink>
                        <NavLink
                          onClick={() => setNavbarCollapsed(false)}
                          className="navbar-item"
                          to="/store-credits-bulk-actions"
                        >
                          {text.navbar.bulk_store_credit_tool}
                        </NavLink>
                      </>
                    )}
                    {userPermissions(accessToken, ['admin', 'cx_agent', 'cx_manager']) && (
                      <>
                        <NavLink
                          onClick={() => setNavbarCollapsed(false)}
                          className="navbar-item"
                          to="/altidollars-fixer"
                        >
                          {text.navbar.altidollars_fixer}
                        </NavLink>
                        <NavLink
                          onClick={() => setNavbarCollapsed(false)}
                          className="navbar-item"
                          to="/altidollars-fixer-list"
                        >
                          {text.navbar.altidollars_fixer_list}
                        </NavLink>
                      </>
                    )}
                  </div>
                </div>
              )}

              {userPermissions(accessToken, ['admin', 'cx_manager', 'cx_agent']) && (
                <div
                  onClick={() => toggleToolsMenu(!toolsMenu)}
                  className={`navbar-item has-dropdown ${toolsMenu ? 'is-active' : null}`}
                >
                  <div className="navbar-link">{text.navbar.tools}</div>

                  <div className="navbar-dropdown">
                    <NavLink onClick={() => setNavbarCollapsed(false)} className="navbar-item" to="/sku-price-history">
                      {text.navbar.sku_price_history}
                    </NavLink>
                    {/*  {userPermissions(accessToken, ['admin', 'marketing_manager', 'marketing_agent']) && (
                    <NavLink
                    onClick={() => setNavbarCollapsed(false)}
                    className="navbar-item"
                    to="/altidollars-refund-campaign"
                    >
                    {text.navbar.altidollars_refund_campaign}
                    </NavLink>
                  )} */}
                  </div>
                </div>
              )}
              {/*TODO 'admin', 'cx_manager', 'cx_agent' add to accesses [] when pushing to prod */}
              {userPermissions(accessToken, []) && (
                  <div
                      onClick={() => toggleModerationMenu(!moderationMenu)}
                      className={`navbar-item has-dropdown ${moderationMenu ? 'is-active' : null}`}
                  >
                    <div className="navbar-link">{text.navbar.moderation}</div>

                    <div className="navbar-dropdown">
                      <NavLink onClick={() => setNavbarCollapsed(false)} className="navbar-item" to="/moderation">
                        {text.navbar.reviewsList}
                      </NavLink>
                    </div>
                  </div>
              )}

              <div className="navbar-end">
                {isAuthenticated && <p className="navbar-item">{user.given_name}</p>}
                <div className="navbar-item">
                  <button className="button" onClick={() => logout({ returnTo: process.env.REACT_APP_BASE_URL })}>
                    {text.navbar.logout}
                  </button>
                  <button className="button navbar__lang-switch" onClick={globalActions.utils.toggleLang}>
                    {lang === 'fr' ? 'EN' : 'FR'}
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </nav>
    </header>
  );
};

export default Navbar;
