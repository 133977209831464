import React, { useState, useEffect } from 'react';
import useGlobal from '../../store';
import { formatArticleTitle } from '../../utils/formatters';
import LoadingSpinner from '../UI/LoadingSpinner';
import ReturnedItems from '../ReturnForm/ReturnedItems';
import ReturnPageSkusItem from './ReturnPageSkusItem';

const ReturnPageSkus = ({ returnObj, fetchReturn }) => {
  const [globalState, globalActions] = useGlobal();
  const { lang, text, requestStatus, reasons, availableItems, selectedItems } = globalState;
  const [displayAddSkuModal, setDisplayAddSkuModal] = useState(false);
  const [addSkuError, setAddSkuError] = useState('');
  const [returnObjItems, setReturnObjItems] = useState(returnObj.items);
  const editable = returnObj && (returnObj.status === 'open' || returnObj.status === 'received');

  useEffect(() => {
    if (reasons.length < 1 && editable) {
      globalActions.db.getReasonsList();
    }
  }, [reasons, globalActions.db, editable]);

  useEffect(() => {
    setReturnObjItems(returnObj.items);
  }, [returnObj]);

  async function showAddSkuModal() {
    
    const orders = await globalActions.db.getOrders(returnObj.order_name, returnObj.channel);
    if (orders) {
      await globalActions.items.setAvailableItems('return-creating', returnObj.channel);
    }
 
    setDisplayAddSkuModal(true);
  }

  function closeAddSkuModal() {
    setDisplayAddSkuModal(false);
    globalActions.utils.updateStateProperty('availableItems', []);
    globalActions.utils.updateStateProperty('selectedItems', []);
    setAddSkuError('');
  }

  async function submitAddedSkus() {
    setAddSkuError('');
    if (selectedItems.length < 1) {
      setAddSkuError(text.return_page.error_no_items);
    } else if (selectedItems.find(el => el.reason === null || el.reason._id === null)) {
      setAddSkuError(text.return_page.error_no_reason);
    } else {
      const res = await globalActions.processing.processAddedSkus(returnObj._id, 'creating');
      if (res === 'SUCCESS') {
        fetchReturn();
      } else {
        setDisplayAddSkuModal(false);
        globalActions.notification.showMessage(text.return_page.error_sku_update_fail, 'error');
      }
    }
  }

  return (
    <>
      <div className="return__skus">
        <h2 className="title is-size-5 has-text-left">{text.return_page.returned_articles}</h2>
        {returnObjItems.map(item => (
          <ReturnPageSkusItem key={item.sku} item={item} returnObj={returnObj} setReturnObjItems={setReturnObjItems} />
        ))}
      </div>
      <br />

      {editable && (
        <button onClick={showAddSkuModal} className="button is-link return__add-sku">
          {text.return_page.add_item}
        </button>
      )}

      {displayAddSkuModal && (
        <div className="modal is-active">
          <div onClick={closeAddSkuModal} className="modal-background"></div>
          <div className="modal-content return__add-sku-modal">
            <div className="box">
              {requestStatus === 'LOADING' && <LoadingSpinner />}

              {(requestStatus === 'ERROR' || requestStatus === 'NOT_FOUND') && (
                <p className="has-text-danger">{text.return_page.error_fetch_data}</p>
              )}

              {requestStatus === 'SUCCESS' && (
                <>
                  {availableItems.length === 0 ? (
                    <p className="has-text-weight-bold">
                      {text.return_page.error_no_available_sku} {returnObj.order_name}
                    </p>
                  ) : (
                    <p className="has-text-weight-bold">{text.return_page.select_skus}</p>
                  )}

                  <>
                    <ul className="has-text-left">
                      {availableItems.map(availableItem => (
                        <li className="return__available-sku" key={availableItem.uniq_id}>
                          <div className="return__available-sku__left">
                            <span
                              onClick={() => globalActions.items.addSelectedItem(availableItem.sku)}
                              className="tag is-primary is-medium"
                            >
                              <span className="icon add return_add-sku">
                                <i className="fas fa-plus-circle has-text-white"></i>
                              </span>
                              {formatArticleTitle(lang, availableItem)}
                            </span>
                          </div>
                        </li>
                      ))}
                    </ul>

                    {addSkuError.length > 0 && <p className="has-text-danger">{addSkuError}</p>}

                    {selectedItems.length > 0 && (
                      <>
                        <ReturnedItems section="single-return-page" />
                        <button onClick={submitAddedSkus} className="button is-link return__confirm-add-sku">
                          {text.return_page.confirm}
                        </button>
                      </>
                    )}
                  </>
                </>
              )}
            </div>
          </div>
          <button onClick={closeAddSkuModal} className="modal-close is-large" aria-label="close"></button>
        </div>
      )}
    </>
  );
};

export default ReturnPageSkus;
