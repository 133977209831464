export const toggleCreditCardException = store => {
  store.setState({
    creditCardException: !store.state.creditCardException
  });
};

export const processPriceMatchObject = async store => {
  const order = store.state.orders[0];
  const formattedItems = formatItems(store.state.selectedItems, order);
  const priceMatchObject = formatPriceMatchObject(store, order, formattedItems);
  const result = await store.actions.db.postPriceMatchObject(priceMatchObject);
  return result;
};

export const getLineItemCurrentPrice = (store, item) => {
  const itemPastPM = store.state.priceMatches
    .filter(el => el.status === 'closed' || el.status === 'processing')
    .map(pm => pm.items.filter(el => el.sku === item.sku).reduce((acc, val) => acc.concat(val), []))
    .reduce((acc, val) => acc.concat(val), []);
  if (itemPastPM.length > 0) {
    const mostRecentItemPM = itemPastPM.sort(
      (a, b) => new Date(b.refunded_at).getTime() - new Date(a.refunded_at).getTime()
    )[0];
    return mostRecentItemPM.requested_price;
  } else {
    return null;
  }
};

function toFixed(num, precision) {
  return (+(Math.round(+(num + 'e' + precision)) + 'e' + -precision)).toFixed(precision);
}

function formatItems(items, order) {
  const formattedItems = items.map(item => {
    const orderItem = order.line_items.find(el => el.id.toString() === item.line_item_id);

    const refundedAmount = parseFloat((parseFloat(item.line_item_current_price) - item.requested_price).toFixed(2));

    return {
      competitor_url: item.competitor_url,
      active_discount: item.discount,
      image_src: item.image_src,
      line_item_current_price: item.line_item_current_price,
      line_item_id: item.line_item_id,
      member_type: item.member_type,
      paid_price: Number(toFixed(orderItem.pre_tax_price / item.quantity_ordered, 2)),
      price_match_type: item.price_match_type,
      quantity_ordered: item.quantity_ordered,
      refund_status: null,
      refunded_amount: refundedAmount * item.quantity_ordered,
      refunded_at: null,
      requested_price: item.requested_price,
      sku: item.sku,
      title: item.title,
      variant_title: item.variant_title,
      vendor: item.vendor
    };
  });

  return formattedItems;
}

function formatPriceMatchObject(store, order, items) {
  return {
    agent: store.state.agentEmail,
    agent_note: store.state.priceMatchNote,
    channel: order.channel,
    created_at: null,
    credit_card_refund: store.state.channels[order.channel].price_match_policy.always_money
      ? true
      : store.state.creditCardException,
    customer_id: order.customer_id.toString(),
    customer_tags: order.customer ? order.customer.tags : '',
    discount_code: store.state.discountCode.title.length > 0 ? store.state.discountCode : null,
    items,
    order: order._id.toString(),
    order_name: order.order_number.toString(),
    status: 'processing',
    tax_lines: order.line_items[0].tax_lines.map(el => {
      return { rate: order.total_tax && order.total_tax === '0.00' ? 0 : el.rate, title: el.title };
    })
  };
}
