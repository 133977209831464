import React, { useState } from 'react';
import useGlobal from '../../store';
import { formatArticleTitle } from '../../utils/formatters';
import ProductImg from '../UI/ProductImg';

const ReturnPageSkusItem = ({ item, returnObj, setReturnObjItems }) => {
  const [globalState, globalActions] = useGlobal();
  const { lang, text, reasons } = globalState;
  const editable = returnObj && (returnObj.status === 'open' || returnObj.status === 'received');
  const [editMode, setEditMode] = useState(false);
  const [refundException, setRefundException] = useState(item.refund_exception ? true : false);
  const [altidollarRefund, setAltidollarRefund] = useState(item.altidollar_refund ? true : false);

  function deleteSku(sku) {
    const items = [...returnObj.items];
    let updatedItems;

    if (sku.quantity_received === 1 || sku.quantity_received === 0) {
      updatedItems = [...items.filter(el => el.sku !== sku.sku)];
    } else {
      updatedItems = items.map(item => {
        if (item.sku === sku.sku) {
          item.quantity_received -= 1
        }
        return item;
      });
    }

    async function confirmDeleteSku() {
      const res = await globalActions.db.updateReturnObj({ items: updatedItems }, returnObj._id);
      if (res && res.status === 'SUCCESS') {
        setReturnObjItems(updatedItems);
      } else {
        globalActions.notification.showMessage(text.return_page.fail_removing_item, 'error');
      }
    }

    globalActions.notification.showDialog(text.return_page.confirm, text.return_page.remove_item, confirmDeleteSku);
  }

  function saveItemChanges() {
    async function updateItem() {
      const updatedItems = [...returnObj.items];
      const updatedItemIndex = updatedItems.findIndex(el => el.sku === item.sku);
      updatedItems[updatedItemIndex].altidollar_refund = altidollarRefund;
      updatedItems[updatedItemIndex].refund_exception = refundException;

      const res = await globalActions.db.updateReturnObj({ items: updatedItems }, returnObj._id);

      if (res && res.status === 'SUCCESS') {
        setEditMode(false);
      } else {
        setEditMode(false);
        globalActions.notification.showMessage(text.return_page.fail_updating_item, 'error');
      }
    }

    globalActions.notification.showDialog(
      'Confirm',
      <>
        <span>Save these settings?</span>
        <ul className="has-text-weight-normal">
          <li>
            {text.return_page.refund_exception}: {refundException ? text.return_page.yes : text.return_page.no}
          </li>
          <li>
            {text.return_page.altidollar_refund}: {altidollarRefund ? text.return_page.yes : text.return_page.no}
          </li>
        </ul>
      </>,
      () => {
        updateItem();
      }
    );
  }

  function getReason(id) {
    const reason = reasons.find(el => el._id === Number(id));
    return lang === 'fr' ? reason.title_fr : reason.title;
  }

  return (
    <>
      {reasons.length > 0 && (
        <div key={item.sku} className="message return__sku">
          <div className="message-header">
            <div>
              <span>
                {formatArticleTitle(lang, item)} - {item.sku}
              </span>
            </div>

            {editable && (
              <div>
                {!editMode && (
                  <button onClick={() => setEditMode(true)} className="button is-link">
                    {text.return_page.edit}
                  </button>
                )}
                {editMode && (
                  <>
                    <button onClick={saveItemChanges} className="button is-link return__save-btn">
                      {text.return_page.save}
                    </button>
                    <button onClick={() => setEditMode(false)} className="button is-dark">
                      {text.return_page.cancel}
                    </button>
                  </>
                )}
                <button onClick={() => deleteSku(item)} className="button is-danger return__sku__delete">
                  {item.quantity_received > 1 ? text.return_page.remove_one_qty : text.return_page.remove_button}
                </button>
              </div>
            )}
          </div>
          <div className="message-body is-flex centered">
            <ProductImg item={item} size="85px" margin="0 1em 0 0" padding="4px" />
            <ul>
              <li>
                {text.return_page.quantity_ordered}: {item.quantity_ordered}
              </li>
              <li>
                {text.return_page.quantity_received}: {item.quantity_received}
              </li>
              <li>
                {text.return_page.refunded}: {item.refunded_at === null ? text.return_page.no : text.return_page.yes}
              </li>
              <li>
                {text.return_page.refund_exception}:{' '}
                {!editMode && <> {item.refund_exception ? text.return_page.yes : text.return_page.no}</>}
                {editMode && (
                  <div className="select">
                    <select
                      onChange={e => setRefundException(e.target.value === 'true')}
                      value={refundException ? 'true' : 'false'}
                    >
                      <option value="true">{text.return_page.yes}</option>
                      <option value="false">{text.return_page.no}</option>
                    </select>
                  </div>
                )}
              </li>
              <li>
                {text.return_page.restocked}: {item.restocked_at === null ? text.return_page.no : text.return_page.yes}
              </li>
              <li>
                {text.return_page.restock_exception}:{' '}
                {item.restock_exception ? text.return_page.yes : text.return_page.no}
              </li>
              <li>
                {text.return_page.altidollar_refund}:{' '}
                {!editMode && <>{item.altidollar_refund ? text.return_page.yes : text.return_page.no} </>}
                {editMode && (
                  <div className="select">
                    <select
                      onChange={e => setAltidollarRefund(e.target.value === 'true')}
                      value={altidollarRefund ? 'true' : 'false'}
                    >
                      <option value="true">{text.return_page.yes}</option>
                      <option value="false">{text.return_page.no}</option>
                    </select>
                  </div>
                )}
              </li>
              <li>
                {text.return_page.reason}:{' '}
                {!item.reason || item.reason._id === null ? 'null' : getReason(item.reason._id)}
              </li>
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default ReturnPageSkusItem;
