import React, { useState, useEffect } from 'react';
import useGlobal from '../../store';
import ObjectsTableTopbar from './ObjectsTableTopbar';
import ObjectsTablePagination from './ObjectsTablePagination';
import LoadingSpinner from '../../components/UI/LoadingSpinner';

const ObjectsTable = ({ section, ListHeader, ListItem, sortOptions, title }) => {
  const [globalState, globalActions] = useGlobal();
  const { text, listData, requestStatus } = globalState;
  const [loading, setLoading] = useState(false);

  function refreshData() {
    setLoading(true);
    globalActions.utils.updateStateProperty('listData', null);
    globalActions.utils.updateStateProperty('requestStatus', 'INITIAL');
    if (window.location.pathname === '/' + section) {
      if (window.location.search) {
        globalActions.objectsList.browserBackObjects(window.location.search, section);
      } else {
        globalActions.objectsList.browserBackObjects('page=1', section);
      }
      setLoading(false);
    }
  }

  useEffect(() => {
    refreshData();
    // eslint-disable-next-line
  }, [section]);

  useEffect(function setupListener() {
    window.addEventListener('popstate', refreshData);

    return function cleanupListener() {
      window.removeEventListener('popstate', refreshData);
    };
  });

  return (
    <>
      {(requestStatus === 'LOADING' || loading) && <LoadingSpinner />}

      {!loading && (
        <>
          {requestStatus === 'NOT_FOUND' && <p className="subtitle">404 - Not found</p>}

          {requestStatus === 'ERROR' && <p className="subtitle">Failed to fetch data...</p>}

          {requestStatus === 'SUCCESS' && listData && listData.data.items.length === 0 && (
            <>
              <ObjectsTableTopbar section={section} sortOptions={sortOptions} />
              <p className="subtitle">No available {title}</p>
            </>
          )}

          {requestStatus === 'SUCCESS' && listData && listData.data.items.length > 0 && (
            <>
              <h1 className="title is-4 has-text-left capitalize_first">
                {title} {' ' + text.objects_table.list}
              </h1>
              <ObjectsTableTopbar section={section} sortOptions={sortOptions} />

              <ul className="table">
                <ListHeader />
                {listData.data.items.map(tableItemObj => (
                  <ListItem key={tableItemObj._id} tableItemObj={tableItemObj} />
                ))}
              </ul>

              <ObjectsTablePagination objectsMeta={listData.meta} section={section} />
            </>
          )}
        </>
      )}
    </>
  );
};

export default ObjectsTable;
