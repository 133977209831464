import React from 'react';
import useGlobal from '../../store';

const PriceMatchExepectedRefund = () => {
  const [globalState] = useGlobal();
  const { text, selectedItems } = globalState;

  function breakdown(selectedItems) {
    if (selectedItems.length === 1) {
      const refundedAmount = (
        (selectedItems[0].line_item_current_price - selectedItems[0].requested_price) *
        selectedItems[0].quantity_ordered
      ).toFixed(2);

      if (refundedAmount > 0) {
        return (
          <span key={selectedItems[0].line_item_id} className="has-text-primary has-text-weight-bold is-size-5">
            ${refundedAmount} in total before taxes
          </span>
        );
      } else {
        return (
          <span key={selectedItems[0].line_item_id} className="has-text-danger has-text-weight-bold">
            {text.price_match_form.error_negative_amount}
          </span>
        );
      }
    } else if (selectedItems.length > 1) {
      const refundedAmounts = selectedItems.map(item =>
        Number(((item.line_item_current_price - item.requested_price) * item.quantity_ordered).toFixed(2))
      );

      const total = refundedAmounts.reduce((a, b) => a + b, 0);
      const displayedAmounts = refundedAmounts.map(amount => `$${amount}`).join(' + ');
      if (displayedAmounts.includes('-') || refundedAmounts.some(el => el <= 0)) {
        return (
          <span key={selectedItems[0].line_item_id} className="has-text-danger has-text-weight-bold">
            {text.price_match_form.error_negative_amount}
          </span>
        );
      } else {
        return (
          <span key={selectedItems[0].line_item_id} className="has-text-primary has-text-weight-bold is-size-4">
            <span className="is-size-5">{displayedAmounts}</span> = ${total.toFixed(2)}
          </span>
        );
      }
    }
  }

  return (
    <div className="price-match-form__expected-refund">
      <label className="label">{text.price_match_form.expected_refund}</label>
      {selectedItems.length > 0 && breakdown(selectedItems)}
    </div>
  );
};

export default PriceMatchExepectedRefund;
