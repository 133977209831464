import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import useGlobal from '../../store';
import PriceMatchPageTable from './PriceMatchPageTable';
import LoadingSpinner from '../UI/LoadingSpinner';
import PriceMatchPageSkus from './PriceMatchPageSkus';

const PriceMatchPage = () => {
  const { priceMatchID } = useParams();
  const [globalState, globalActions] = useGlobal();
  const { text, priceMatchFetchStatus } = globalState;
  const [priceMatchObj, setPriceMatchObj] = useState(null);

  const fetchPriceMatch = useCallback(async () => {
    const priceMatchObj = await globalActions.db.getPriceMatches({
      key: 'id',
      value: priceMatchID
    });
    setPriceMatchObj(priceMatchObj[0]);
  }, [globalActions, priceMatchID]);

  useEffect(() => {
    globalActions.utils.clearProcessStates();
    fetchPriceMatch();
  }, [globalActions, fetchPriceMatch]);

  return (
    <>
      {priceMatchObj !== null && (
        <>
          <h1 className="title is-size-5">
            {text.price_match_page.title} {priceMatchID}
          </h1>
          <PriceMatchPageTable priceMatchObj={priceMatchObj} />
          <PriceMatchPageSkus priceMatchObj={priceMatchObj} />
        </>
      )}

      {priceMatchFetchStatus === 'LOADING' && <LoadingSpinner />}
    </>
  );
};

export default PriceMatchPage;
