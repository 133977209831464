import React from 'react';
import { Link } from 'react-router-dom';
import useGlobal from '../../../store';

const ReturnsTableItem = ({ tableItemObj }) => {
  const [globalState] = useGlobal();
  const { text, channels } = globalState;

  const skuCount =
    (tableItemObj.items && tableItemObj.items.length) > 0
      ? tableItemObj.items
        .filter(el => el !== null)
        .map(el => parseInt(el.quantity_received))
        .reduce((a, b) => a + b)
      : 0;

  const skusRefundStatusNb =
    tableItemObj.items && tableItemObj.items.length > 0
      ? tableItemObj.items
        .filter(el => el !== null)
        .map(el => el.refund_status === 'processed')
        .map(el => (el ? 1 : 0))
        .reduce((a, b) => a + b)
      : null;

  const skusRestockStatusNb =
    tableItemObj.items && tableItemObj.items.length > 0
      ? tableItemObj.items
        .filter(el => el !== null)
        .map(el => el.restock_status === 'processed')
        .map(el => (el ? 1 : 0))
        .reduce((a, b) => a + b)
      : null;

  const stepStatus = (step, field) => {
    const hasRestockFailure = tableItemObj.items && tableItemObj.items.some(item => item.restock_status === 'failed' && field === 'restock');

    const hasRefundFailure = tableItemObj.items && tableItemObj.items.some(item => item.refund_status === 'failed' && field === 'refund');

    if (hasRestockFailure || hasRefundFailure) {
      return <p className="has-text-danger">{text.objects_table.failed}</p>;
    } else if (step === 0) {
      return <p className="has-text-danger">{text.objects_table.none}</p>;
    } else if (step < tableItemObj.items.length) {
      return <p className="has-text-link">{text.objects_table.partial}</p>;
    } else if (step === tableItemObj.items.length) {
      return <p className="has-text-success">{text.objects_table.full}</p>;
    } else if (step === null) {
      return <p className="has-text-danger">{text.objects_table.no_sku}</p>;
    }
  };

  const receivedAt = () => {
    if (tableItemObj.processed_at && !tableItemObj.received_at) {
      return text.objects_table.no_receiving;
    } else if (tableItemObj.received_at) {
      return tableItemObj.received_at.split('T')[0];
    } else {
      return '';
    }
  };

  return (
    <>
      {tableItemObj.items &&
        <li className="returns-table__item">
          <div className="objects-table__item__cell">
            <Link to={`returns/${tableItemObj._id}`}>
              <span className="icon">
                <i className="fas fa-edit"></i>
              </span>
            </Link>
          </div>
          <div className="objects-table__item__cell">{text.objects_table[tableItemObj.status]}</div>
          <div className="objects-table__item__cell">
            {tableItemObj.order && (
              <a
                href={`${channels[tableItemObj.channel].shop_url}/orders/${tableItemObj.order.split('--')[1]}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {tableItemObj.order_name}
              </a>
            )}
            {!tableItemObj.order && tableItemObj.order_name}
          </div>
          <div className="objects-table__item__cell desktop">{tableItemObj.tracking_number}</div>
          <div className="objects-table__item__cell">{skuCount.toString()}</div>
          <div className="objects-table__item__cell desktop">
            {tableItemObj.created_at ? tableItemObj.created_at.split('T')[0] : null}
          </div>
          <div className="objects-table__item__cell desktop">{receivedAt()}</div>
          <div className="objects-table__item__cell desktop">
            {tableItemObj.processed_at ? tableItemObj.processed_at.split('T')[0] : null}
          </div>
          <div className="objects-table__item__cell desktop">{stepStatus(skusRefundStatusNb, 'refund')}</div>
          <div className="objects-table__item__cell desktop">{stepStatus(skusRestockStatusNb, 'restock')}</div>
          <div className="objects-table__item__cell desktop">{tableItemObj.seller ? tableItemObj.seller : null}</div>
          <div className="objects-table__item__cell desktop">{tableItemObj.channel}</div>
        </li>
      }
    </>

  );
};

export default ReturnsTableItem;
