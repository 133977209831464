import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import useGlobal from '../../store';

const Messages = () => {
  const [globalState, globalActions] = useGlobal();

  const { message } = globalState;

  const messageContent = !message.open
    ? []
    : [
        <div>
          <div className={`notification ${message.type === 'success' ? 'is-link' : 'is-danger'}`}>
            <button onClick={globalActions.notification.hideMessage} className="delete"></button>
              <div style={{display: 'flex', justifyContent: 'center'}}>
                <div>{message.message}</div>
                {message.errorMessage && <div>: {message.errorMessage}</div>}
              </div>
          </div>
        </div>
      ];

  return (
    <TransitionGroup>
      {messageContent.map((item, index) => (
        <CSSTransition key={index} timeout={100} classNames="message-container message">
          {item}
        </CSSTransition>
      ))}
    </TransitionGroup>
  );
};

export default Messages;
