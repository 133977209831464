import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import useGlobal from '../store';
import ObjectsTable from '../components/ObjectsTable';
import BulkStoreCreditsTableHeader from '../components/ObjectsTable/BulkStoreCredits/BulkStoreCreditsTableHeader';
import BulkStoreCreditsTableItem from '../components/ObjectsTable/BulkStoreCredits/BulkStoreCreditsTableItem';

const BatchStoreCreditsList = () => {
  let match = useRouteMatch();
  const [globalState] = useGlobal();
  const { text } = globalState;

  const sortOptions = [
    {
      value: 'created_at.1',
      text: text.objects_table.created_at_asc
    },
    {
      value: 'created_at.-1',
      text: text.objects_table.created_at_desc,
      selected: "selected"
    },
    {
      value: 'expiry_date.1',
      text: text.objects_table.expiry_date_asc
    },
    {
      value: 'expiry_date.-1',
      text: text.objects_table.expiry_date_desc
    },
    {
      value: 'amount.1',
      text: text.objects_table.amount_asc
    },
    {
      value: 'amount.-1',
      text: text.objects_table.amount_desc
    },
    {
      value: 'total_rows.1',
      text: text.objects_table.total_rows_asc
    },
    {
      value: 'total_rows.-1',
      text: text.objects_table.total_rows_desc
    }
  ];

  return (
    <>
      <main className="container">
        <section className="section">
          <Switch>
            <Route path={`${match.path}`}>
              <ObjectsTable
                section="store-credits-bulk-actions-list"
                ListHeader={BulkStoreCreditsTableHeader}
                ListItem={BulkStoreCreditsTableItem}
                sortOptions={sortOptions}
                title={text.objects_table.bulk_store_credits_list_title}
              />
            </Route>
          </Switch>
        </section>
      </main>
    </>
  );
};

export default BatchStoreCreditsList;
