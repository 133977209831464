import React from 'react';
import useGlobal from '../../store';

const PriceMatchPageTable = ({ priceMatchObj }) => {
  const [globalState] = useGlobal();
  const { text, channels } = globalState;

  const formatDate = dateString => {
    return new Date(dateString).toLocaleString('en-CA', {
      timeZone: 'America/New_York'
    });
  };

  return (
    <div className="price-match__table">
      <div className="price-match__table__row">
        <div className="price-match__table__cell">{text.price_match_page.order}:</div>
        <div className="price-match__table__cell">
          <a
            href={`${channels[priceMatchObj.channel].shop_url}/orders/${priceMatchObj.order.split('--')[1]}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {priceMatchObj.order_name}
          </a>
        </div>
      </div>

      <div className="price-match__table__row">
        <div className="price-match__table__cell">{text.price_match_page.channel}:</div>
        <div className="price-match__table__cell">{priceMatchObj.channel}</div>
      </div>

      <div className="price-match__table__row">
        <div className="price-match__table__cell">{text.price_match_page.status}:</div>
        <div className="price-match__table__cell">{text.price_match_page[priceMatchObj.status]}</div>
      </div>

      <div className="price-match__table__row">
        <div className="price-match__table__cell">{text.price_match_page.created_at}:</div>
        <div className="price-match__table__cell">
          {priceMatchObj.created_at === null ? 'null' : formatDate(priceMatchObj.created_at)}
        </div>
      </div>

      <div className="price-match__table__row">
        <div className="price-match__table__cell">{text.price_match_page.credit_card_refund}:</div>
        <div className="price-match__table__cell">
          {priceMatchObj.credit_card_refund ? text.price_match_page.yes : text.price_match_page.no}
        </div>
      </div>

      <div className="price-match__table__row">
        <div className="price-match__table__cell">{text.price_match_page.discount_code}:</div>
        <div className="price-match__table__cell">
          {priceMatchObj.discount_code
            ? `${priceMatchObj.discount_code.title} (${priceMatchObj.discount_code.value}${
                priceMatchObj.discount_code.value_type === 'percentage' ? '%' : '$'
              })`
            : text.price_match_page.no_code}
        </div>
      </div>

      <div className="price-match__table__row">
        <div className="price-match__table__cell">{text.price_match_page.customer_id}:</div>
        <div className="price-match__table__cell">
          <a
            href={`${channels[priceMatchObj.channel].shop_url}/customers/${priceMatchObj.customer_id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {priceMatchObj.customer_id}
          </a>
        </div>
      </div>

      <div className="price-match__table__row">
        <div className="price-match__table__cell">{text.price_match_page.customer_tags}:</div>
        <div className="price-match__table__cell">{priceMatchObj.customer_tags}</div>
      </div>

      <div className="price-match__table__row">
        <div className="price-match__table__cell">{text.price_match_page.agent}:</div>
        <div className="price-match__table__cell">{priceMatchObj.agent ? priceMatchObj.agent : 'null'}</div>
      </div>

      <div className="price-match__table__row">
        <div className="price-match__table__cell">{text.price_match_page.note}:</div>
        <div className="price-match__table__cell">{priceMatchObj.agent_note ? priceMatchObj.agent_note : ''}</div>
      </div>
    </div>
  );
};

export default PriceMatchPageTable;
