import React from 'react';
import useGlobal from '../../store';

const ReturnNotes = () => {
  const [globalState, globalActions] = useGlobal();
  const { text, prepaidLabelNote, receptionNote /* , newReturnType */ } = globalState;

  return (
    <div className="return-form__notes">
      <div className="field">
        <label className="label">{text.return_creation.input_label_note}:</label>
        <div className="control">
          <input
            value={prepaidLabelNote}
            onChange={e => globalActions.utils.updateStateProperty('prepaidLabelNote', e.target.value)}
            className="input"
            type="text"
            placeholder={text.return_creation.label_note_placeholder}
            maxLength="35"
          />
        </div>
      </div>

      <div className="field">
        <label className="label">{text.return_creation.input_reception_note}:</label>
        <div className="control">
          <input
            value={receptionNote}
            onChange={e => globalActions.utils.updateStateProperty('receptionNote', e.target.value)}
            className="input"
            type="text"
            placeholder={text.return_creation.reception_note_placeholder}
          />
        </div>
      </div>
    </div>
  );
};

export default ReturnNotes;
