import React from 'react';

const LoadingSpinner = ({ margin }) => {
  return (
    <div className="lds-spinner" style={{ margin }}>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};

export default LoadingSpinner;
