const en = {
  dialog: {
    cancel: 'Cancel'
  },
  store: {
    error_fetching_returns: 'Error fetching returns. Please contact IT',
    error_fetching_orders: 'Error fetching orders. Please contact IT',
    success_updating_tracking: 'Success updating tracking data.',
    error_invalid_sku: 'Invalid or unavailable sku.',
    error_fetching_sku: 'Failed to fetch sku. Please contact IT.',
    error_empty_reasons_list: 'Returns reasons list is empty.',
    error_fetching_reasons: 'Failed to fetch returns reasons. Please contact IT.',
    error_label_create_fail: 'Failed to create prepaid label. Please contact IT',
    error_fetching_price_matches: 'Error fetching price-matches. Please contact IT',
    item_unavailable: 'Item unavailable - please input new order',
    item_unavailable_in_order: 'Item not available in that order. Please enter a new order number.',
    warranty: 'Warranty. Please process manually.',
    doa: 'DOA. Please process manually.',
    return_post_success: 'Success sending return(s).',
    error_return_post_fail: 'Failed to send return(s)',
    sku_update_success: 'Success updating SKUs.',
    error_no_sku_available: 'No item available for that order.',
    error_marketplace_title: 'Marketplace article',
    error_marketplace:
      'Please contact the Customer Service to process the Marketplace refund. Do not refund on Shopify.'
  },
  homepage: {
    sign_in: 'Sign in',
    sign_out: 'Sign out',
    no_access: "Sorry, you don't have access to this section. Please contact technical support."
  },
  navbar: {
    returns: 'Returns',
    returns_list: 'Returns list',
    return_form: 'Return form',
    return_reception_note: 'Create reception note',
    return_receiving: 'Receive return',
    return_processing: 'Process return',
    returns_reports: 'Returns reports',
    price_matches: 'Price matches',
    price_matches_list: 'Price matches list',
    price_match_form: 'Price match form',
    tools: 'Tools',
    orders: 'Orders',
    sku_price_history: 'SKU price history',
    altidollars_refund_campaign: 'Alti Dollars refund campaign',
    logout: 'Log out',
    store_credits: 'Store Credits',
    bulk_store_credit_tool: 'Bulk actions',
    batch_store_credits_list: 'Bulk actions list',
    altidollars_fixer: 'Altidollars fixer',
    altidollars_fixer_list: 'Altidollars fixer list',
    moderation: 'Moderation',
    reviewsList: 'Reviews List'
  },
  objects_table: {
    ALS: 'Alti',
    TLH: 'TLH',
    returns_list_title: 'returns',
    price_matches_list_title: 'price matches',
    bulk_store_credits_list_title: 'bulk store credits',
    list: 'list',
    edit: 'Edit',
    status: 'Status',
    order: 'Order',
    tracking: 'Tracking',
    bundle: 'Bundle',
    created_at: 'Created',
    received_at: 'Received',
    processed_at: 'Processed',
    total_rows: 'Emails',
    campaign: 'Campaign',
    completed: 'Completed',
    started_at: 'Started',
    finished_at: 'Finished',
    expiry_date: 'Expiry',
    duplicates: 'Duplicate record',
    amount: 'Amount',
    nb_chunks: 'Job completed (%)',
    mode: 'Mode',
    sku_count: 'SKUs',
    refunded: 'Refunded',
    restocked: 'Restocked',
    refund: 'Refund',
    restock: 'Restock',
    failed: 'Failed',
    channel: 'Channel',
    more_info: 'More info',
    select_filter: 'Select a filter',
    open: 'Open',
    processing: 'Processing',
    processed: 'Processed',
    pending: 'Pending',
    add: 'Add',
    remove: 'Remove',
    exception: 'Exception',
    received: 'Received',
    closed: 'Closed',
    none: 'None',
    partial: 'Partial',
    full: 'Full',
    no_sku: 'No SKUs',
    is: 'Is',
    add_filter: 'Add filter',
    add_a_filter: 'Add a filter',
    from: 'From',
    to: 'To',
    previous_page: 'Previous',
    next_page: 'Next',
    search: 'Search',
    sort_by: 'Sort by',
    default: 'Default',
    created_at_desc: 'Creation date (desc)',
    created_at_asc: 'Creation date (asc)',
    completed_at_asc: 'Completion date (asc)',
    completed_at_desc: 'Completion date (desc)',
    received_at_desc: 'Reception date (desc)',
    received_at_asc: 'Reception date (asc)',
    processed_at_desc: 'Process date (desc)',
    processed_at_asc: 'Process date (asc)',
    expiry_date_asc: 'Expiry date (asc)',
    expiry_date_desc: 'Expiry date (desc)',
    amount_asc: 'Amount (asc)',
    amount_desc: 'Amount (desc)',
    total_rows_asc: 'Total rows (asc)',
    total_rows_desc: 'Total rows (desc)',
    no_receiving: 'At processing',
    refund_fails_pending: 'Refund fails pending...',
    restock_fails_pending: 'Restock fails pending...',
    exceptions_pending: 'Unresolved exceptions pending...',
    seller: 'Seller',
    completed_at: 'Completed',
    total_orders: 'Total orders',
    failed_count: 'Failed',
    customer_email: 'Customer email',
    customer_id: 'Customer ID',
    cx_agent: 'CX agent',
    modified_lines: 'Modified',
    new_total_balance: 'New balance',
    old_total_balance: 'Old balance',
    failed_returns_count_error: 'return error(s)',
    failed_returns_count_metrics_refresh: 'This metric is updated every 5 minutes.'
  },
  return_creation: {
    title: 'Return form',
    create: 'Create',
    update: 'Update',
    void: 'Clear',
    error_no_selected_item: 'Please select at least one item to return.',
    error_no_return_reason: 'Please enter a return reason for each item.',
    error_special_characters: 'No special characters are allowed in the prepaid label note.',
    confirm: 'Confirm',
    process_return: 'Process return?',
    input_label_note: 'Input prepaid label note',
    label_note_placeholder: 'Prepaid label note (max 35 characters)',
    input_reception_note: 'Input reception note',
    reception_note_placeholder: 'Reception note',
    order: 'Order',
    items: 'Returned item(s)',
    return_reason: 'Return reason',
    remove: 'Remove',
    please_choose: 'Please choose',
    cond_question_placeholder: 'Additional info',
    submit: 'Submit',
    altidollars_refund_bypass:
      'Some items will be reimbursed in Alti Dollars (sale price under threshold). Check this box for a credit card refund instead',
    altidollar_refund: 'Reimburse in Alti Dollars',
    altidollar_refund_campaign: 'Automatically checked: Alti Dollars refund campaign',
    refund_exception: 'Do not refund',
    return_label_option: 'Return label options:',
    digital_label: 'New label',
    label_in_box: 'Label in the box',
    international: 'International',
    tlh: "TLH client's label",
    add_qr_code: 'Add QR code to email',
    restocking_fee_exception: 'Return shipping fee exception',
    return_to_als: 'Return to Altitude Sports',
    final_sale: 'Final sale item (make sure it is a valid exception)',
    same_return_text: 'If you want to add new items in the same return, select the tracking number of the package below.',
    same_return_button: 'Include item(s) in this return',
    new_return_button: 'Close and create a new return'
  },
  return_expected_refund: {
    expected_refund: 'Expected refund for order',
    item: 'item',
    restocking_fee: 'Return shipping fee',
    subtotal: 'Subtotal',
    tax: 'Tax',
    expired_credits: 'Expired credits balance',
    non_refundable: 'non refundable',
    refunded_cash: 'Refunded on your method of payment',
    refunded_altidollars: 'Refunded in Alti Dollars',
    refresh_order: 'Required order data unavailable. Please update order in Shopify.',
    manual_refund_balance: 'Previously refunded on this purchase'
  },
  return_reception: {
    title: 'Return receiving',
    submit: 'Submit',
    tracking_input_placeholder: 'Tracking number',
    error_invalid_tracking_a: 'Tracking',
    error_invalid_tracking_b: 'is not a known carrier (Canada Post or Fedex). Do you still want to process it?',
    confirm: 'Confirm',
    rnc: 'RNC: abusive client',
    success_creating: 'Success creating and receiving return.',
    failed_creating: 'Failed to create and receive return.',
    success_updating: 'Success receiving return.',
    failed_updating: 'Failed to receive return.',
    reception_note: 'Reception note'
  },
  return_processing: {
    title: 'Return processing',
    confirm: 'Confirm',
    clear_form: 'Clear the form?',
    process_return: 'Process return?',
    error_no_items: 'Please scan item(s) first.',
    process_button: 'Process',
    void: 'Void',
    error_invalid_sku: 'Invalid - a sku or upc should have at least 11 numbers',
    loading: 'Loading...',
    warranty: 'Warranty. Please process manually.',
    doa: 'DOA. Please process manually.',
    looks_different_from_image: 'Looks different from image on site. Please process manually.',
    received_wrong_item: 'Received wrong item. Please process manually.',
    inaccurate_description: 'Inaccurate website description. Please process manually.',
    poor_quality: 'Item of poor quality. Please process manually.',
    input_sku_placeholder: 'Enter UPC or SKU',
    add: 'Add',
    order: 'Order',
    order_input_placeholder: 'Order number',
    no_refund: 'Do not refund',
    altidollars_refund: 'Note: this article will be refunded in Alti Dollars',
    validate: 'Validate',
    no_refund_tlh: 'Not refunded (TLH sku)',
    no_refund_mp: 'Not refunded (Marketplace item)',
    no_refund_instalments: 'Manual refund (Paybright/Sezzle)',
    remove: 'Remove',
    restocking_fee_exception: 'Return shipping fee exception',
    rnc: 'RNC: abusive client',
    order_beyond_return_days_a: 'Order',
    order_beyond_return_days_b: 'was made',
    order_beyond_return_days_c: 'days ago.',
    error_no_order_found: 'No order found.',
    received_package_found: 'Received package found. Please enter order number.',
    error_tracking_not_found: 'Tracking not found. Please enter order number and channel.',
    error_please_enter_tracking: 'Please enter tracking number or order reference.',
    tracking_number: 'Tracking number',
    search: 'Search',
    error_empty_input: 'Please enter valid order number',
    altidollar_refund: 'Reimburse in Alti Dollars',
    cx_agent_exception: 'Please leave checked (CX agent exception)'
  },
  returns_reports: {
    title: 'Returns reports',
    daily_values: 'Daily values',
    historical_values: 'Historical values'
  },
  return_page: {
    title: 'Return',
    order: 'Order',
    status: 'Status',
    bundle_id: 'Bundle ID',
    restocking_fee_exception: 'Return shipping fee exception',
    tracking: 'Tracking',
    label_creation_pending: 'Pending... (waiting for Canada Post)',
    label_creation_failed:
      'Return label creation failed. Please create it manually and enter the tracking number here.',
    please_input_tracking: 'Please enter the return tracking number (if available)...',
    carrier: 'Carrier',
    deposco_id: 'Deposco ID',
    channel: 'Channel',
    customer_id: 'Customer ID',
    customer_tags: 'Customer tags',
    created_at: 'Created at',
    received_at: 'Received at',
    processed_at: 'Processed at',
    prepaid_label_note: 'Prepaid label note',
    reception_note: 'Reception note',
    returned_articles: 'Returned articles',
    quantity_received: 'Quantity expected',
    quantity_ordered: 'Quantity ordered',
    refund: 'Refund',
    refunded: 'Refunded',
    refund_exception: 'Refund exception',
    restock: 'Restock',
    restocked: 'Restocked',
    restock_exception: 'Restock exception',
    reason: 'Reason',
    add_item: 'Add item to return',
    error_no_items: 'Please select at least one item to return.',
    error_no_reason: 'Please enter a return reason for each item.',
    remove_button: 'Remove item',
    remove_one_qty: 'Remove 1 quantity',
    remove_item: 'Remove item?',
    success_removing_item: 'Success removing item.',
    fail_removing_item: 'Failed to remove item.',
    fail_updating_item: 'Failed to update item.',
    error_sku_update_fail: 'Failed to update SKUs.',
    edit: 'Edit',
    retryPrepaidLabel: 'Retry to get label',
    save: 'Save',
    cancel: 'Cancel',
    confirm: 'Confirm',
    set_tracking: 'Set new tracking to',
    set_reception_note: 'Set new reception note to',
    email_status_sent: 'Prepaid return label has been sent to the client.',
    email_status_not_sent: 'Prepaid return label has not been sent to the client yet...',
    open_return_label: 'Open prepaid return label',
    cant_open_pdf: "Still can't open PDF?",
    open_qr_code: 'Open QR code',
    create_new_label: 'Create new prepaid return label',
    success_creating_label: 'Success creating new prepaid return label.',
    failed_creating_label: 'Failed to create new prepaid return label. - Please contact IT',
    new_label_pending: 'Canada Post is down. The return label should be created and sent to the client within 24h.',
    return_label_email_pending_sent: 'An email has been sent to inform the client of the delay.',
    close_return: 'Close return (return to sender resolution or other)',
    success_closing_return: 'Success closing return.',
    failed_closing_return: 'Failed to close return.',
    close_return_confirm: 'Close return?',
    using_included_label: 'The client is using the included prepaid return label. Or his own solution.',
    exception_pending: 'Exception pending...',
    invalid_return_id: 'No return found - Invalid return ID',
    resolve_exception: 'Update refund and/or restock status',
    yes: 'yes',
    no: 'no',
    error_fetch_data: 'Error - failed to fetch data - please contact ITs',
    error_no_available_sku: 'No more available SKUs for order',
    select_skus: 'Select SKU(s) to add',
    fail_handling_button: 'Failed refund and/or restock pending...',
    close_failures: 'Please check resolved failures',
    submit: 'Submit',
    refund_fail: 'refund fail',
    restock_fail: 'restock fail',
    no_receiving: 'At processing',
    altidollar_refund: 'Alti Dollars refund',
    set_fee_exception: 'Make restocking fee exception?',
    charge_restocking_fee: 'Charge restocking fee?',
    update_qr_code: 'Create new QR code and return label',
    seller: 'Sold by',
    label_type: 'Return label type',
    destination_return_policy: 'Return address',
    'digital-label': 'Digital label',
    'label-in-box': 'Label in the box',
    international: 'International',
    tlh: "TLH client's label"
  },
  items_selection: {
    input_order: 'Input order #',
    add: 'Add',
    search: 'Search',
    order_number_placeholder: 'Order #',
    no_order_found: 'No order found',
    loading: 'Loading...',
    select_returned_items: 'Select returned item(s):',
    select_pricematch_items: 'Select price-matched item(s):',
    order: 'Commande',
    paid_price: 'Last paid price',
    no_available_sku: 'No available sku'
  },
  reception_note: {
    title: 'Create return reception note',
    order_number_placeholder: 'Order number',
    tracking_number_placeholder: 'Return tracking number',
    note_placeholder: 'Reception note',
    error_missing_fields: 'Please complete all fields.',
    error_return_exists: 'A return was already created for this tracking number. Please update reception note',
    error_return_exists_link: 'via return page',
    success: 'Success creating return and reception note.',
    fail: 'Failed to create return and reception note.',
    error_order_not_found: 'Order not found.',
    submit: 'Submit'
  },
  price_match_page: {
    title: 'Price match',
    order: 'Order',
    channel: 'Channel',
    status: 'Status',
    created_at: 'Created_at',
    credit_card_refund: 'Credit card refund',
    discount_code: 'Discount code',
    customer_id: 'Client ID',
    customer_tags: 'Customer tags',
    agent: 'Agent',
    note: 'Note',
    price_matched_articles: 'Price-matched articles',
    original_paid_price: 'Original paid price',
    current_paid_price: 'Last paid price',
    requested_price: 'New requested price',
    refunded_amount: 'Refunded amount per qty',
    quantity_ordered: 'Quantity ordered',
    refund_status: 'Refund status',
    refunded_at: 'Refunded at',
    line_item_id: 'Line item ID',
    processed: 'processed',
    pending: 'not processed',
    yes: 'Yes',
    no: 'No',
    no_code: 'No code',
    open: 'Open',
    processing: 'Processing',
    closed: 'Closed',
    price_match_type: 'Type',
    internal: 'internal',
    external: 'competitor',
    active_coupon: 'Active coupon?'
  },
  price_match_form: {
    title: 'Price-match form',
    price_matched_articles: 'Price-matches articles',
    qty: 'Qty',
    available_prices: "Available item's prices:",
    last_paid_price: 'Last paid price (including all discounts):',
    refunded_amount: 'Refunded amount',
    new_paid_price: 'New paid price',
    no_historical_prices: 'No historical price available.',
    in_house: 'In-house price-match',
    external: 'Competitor price match',
    external_note: "Enter the item's URL or the name and phone number of the competitor",
    starting_price: "Item's new price",
    details: 'Details',
    total: 'Total discounted price',
    membership_status: 'Membership status',
    member: 'Member',
    non_member: 'Non-member',
    member_status_note: 'Note: current member status',
    discount_code: 'Discount code',
    discount_name_placeholder: 'Enter discount code name',
    discount_nb_placeholder: 'Amount in percentage',
    apply_coupon: 'Apply discount code',
    note: 'Leave a note',
    note_placeholder: 'Leave a note (Zendesk ticket, etc.)',
    credit_card_refund: 'Reimburse on the credit card',
    order_not_shipped: 'Credit card refund (order is not shipped yet or no store credits available)',
    void: 'Clear',
    submit: 'Submit',
    error_no_items: 'Please select at least one price-matched item.',
    error_invalid_price: 'Please enter a valid requested price for each item.',
    error_missing_competitor_info: 'Please enter the info of the competitor   for each external price match.',
    error_missing_coupon_info: 'Please fill all the discount code informations (name and percentage)',
    error_apply_coupon: 'Apply discount code on at least one item or remove it.',
    error_price_too_low: 'Some of the price matches are too low. Please validate with a supervisor.',
    error_missing_note: 'Please leave a note. (Zendesk ticket number or other).',
    error_negative_amount: "A refunded amount can't be negative or equal zero.",
    confirm: 'Confirm',
    submit_price_match: 'Submit price match?',
    success: 'Success creating price match',
    fail: 'Failed to create price match',
    excluded_vendor: 'Excluded vendor',
    member_5: '5% member discount',
    member_10: '10% member discount',
    member_15: '15% member discount',
    passed_price_matches: 'Past price-matches:',
    no_past_pm: 'No past price matches.',
    expected_refund: 'Expected refund (before taxes):'
  },
  sku_price_history: {
    title: 'SKU price history',
    sku_input_placeholder: 'Enter SKU',
    submit: 'Search',
    no_history_found: 'No historical price found.'
  },
  altidollars_refund_campaign: {
    title: 'Alti Dollars refund campaign',
    current_campaign: 'Current Alti Dollars refund campaign',
    deactivate: 'Deactivate campaign',
    threshold: 'Discount percentage threshold',
    starting: 'Starting at',
    ending: 'Ending at',
    no_campaign: 'No campaign set',
    agent: 'Set by',
    set_new_campaign: 'Set new campaign settings',
    enter_threshold: 'Enter discount threshold',
    threshold_note:
      'Note: if the price paid by the client (before Membership % and coupon) was equal or over this threshold, the refund will be in Alti Dollars',
    select_dates: 'Select dates range',
    dates_range_note: 'Note: leave the end date empty for a permanent campaign.',
    submit: 'Submit',
    always: 'null',
    from: 'From',
    to: 'To',
    error_date: 'A starting date is required.'
  },
  store_credits: {
    title: 'Bulk actions',
    choose_file_to_upload: 'Choose file to upload (CSV)',
    card_header: 'Add / Remove store credits',
    add: 'Add',
    remove: 'Remove',
    reset_form: 'Reset form',
    all_fields_required: 'Please check if all the fields have been filled',
    something_went_wrong: 'Something went wrong. Please try again',
    wrong_file_format: 'Please upload a valid .CSV file',
    expiry_date: 'Expiry date',
    credit_value: 'Credit value',
    invalid_email_csv_data: 'Please check the validity of csv data at line number : ',
    remove_duplicates_only: 'Only remove the duplicate credits',
    negative_value_not_allowed: 'Credit value should be a positive whole number.',
    too_much_credit_value: 'Credit value too large for bulk processing.',
    confirm_value_to_be_added: 'Please confirm if credit value is correct : $',
    date_already_passed: 'Expiry date has already passed. Please choose a future date.',
    post_success: 'Your campaign will be processed soon',
    post_error: 'Error sending store credits bulk action. Please contact IT',
    csv_info: 'Upload a CSV file with a list of the targetted emails. No header required.',
    confirm: 'Confirm',
    confirm_text: 'Please confirm to send the request.',
    campaign_name: 'Campaign name (max 25 characters)'
  },
  altidollars_fixer: {
    title: 'Alti Dollars balance fixer',
    subtitle: "Fix customer's Alti Dollars balance",
    customer_email: "Customer's email",
    submit: 'Submit',
    success: "Success fixing customer's Altidollars balance",
    fail: "Failed to fix customer's Altidollars balance: "
  },
  months: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ],
  weekdays_long: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  weekdays_short: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']
};

export default en;
