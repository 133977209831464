import React, { useState } from 'react';
import useGlobal from '../../../store';

const ReturnTableHeader = () => {
  const [globalState] = useGlobal();
  const { text } = globalState;

  const [headerFields] = useState({
    edit: 'desktop',
    status: 'desktop',
    order: 'desktop',
    tracking: '',
    sku_count: 'desktop',
    created_at: '',
    received_at: '',
    processed_at: '',
    refunded: '',
    restocked: '',
    seller: '',
    channel: ''
  });

  const headList = Object.keys(headerFields).map(field => {
    return (
      <div
        className={
          headerFields[field] === 'desktop' ? 'objects-table__item__cell' : 'objects-table__item__cell desktop'
        }
        key={field}
      >
        {text.objects_table[field]}
      </div>
    );
  });

  return <li className="returns-table__item has-text-weight-bold">{headList}</li>;
};

export default ReturnTableHeader;
