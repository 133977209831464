const permissions = {
  IT: 'admin',
  'warehouse:returnagents': 'return_agent',
  'warehouse:manager': 'return_manager',
  'warehouse:returnsupervisors': 'return_manager',
  'CS:manager': 'cx_manager',
  'CS:supervisor': 'cx_manager',
  'CS:teamleader': 'cx_manager',
  'CS:senior_agent': 'cx_agent',
  'CS:junior_agent': 'cx_agent',
  'CS:order_processors': 'cx_orders_agent',
  'marketing:manager': 'marketing_manager',
  'marketing:senior': 'marketing_agent',
  'marketing:junior': 'marketing_agent'
};

const setUserAccess = (accessToken, accesses) => {
  const permissionsTags = accessToken['http://cerberus.altitude-sports.com/groups'].concat(
    accessToken['http://cerberus.altitude-sports.com/roles']
  );

  const tags = permissionsTags.map(el => permissions[el]).filter(el => el);

  if (accesses.includes('all')) {
    return true;
  } else {
    return tags.find(el => accesses.includes(el)) ? true : false;
  }
};

export default setUserAccess;
