import React from 'react';
import { Link } from 'react-router-dom';
import useGlobal from '../../../store';

const PriceMatchesTableItem = ({ tableItemObj }) => {
  const [globalState] = useGlobal();
  const { text, channels } = globalState;

  const skuCount =
    tableItemObj.items && tableItemObj.items.length > 0
      ? tableItemObj.items.map(el => parseInt(el.quantity_ordered)).reduce((a, b) => a + b)
      : 0;

  const skusRefundStatusNb =
    tableItemObj.items && tableItemObj.items.length > 0
      ? tableItemObj.items
        .map(el => el.refunded_at)
        .map(el => (el ? 1 : 0))
        .reduce((a, b) => a + b)
      : null;

  const stepStatus = step => {
    if (step === 0) {
      return <p className="has-text-danger">{text.objects_table.none}</p>;
    } else if (step < tableItemObj.items.length) {
      return <p className="has-text-link">{text.objects_table.partial}</p>;
    } else if (step === tableItemObj.items.length) {
      return <p className="has-text-success">{text.objects_table.full}</p>;
    } else if (step === null) {
      return <p className="has-text-danger">{text.objects_table.no_sku}</p>;
    }
  };

  return (
    <>
      {tableItemObj.items && <li className="price-match__item">
        <div className="objects-table__item__cell">
          <Link to={`price-matches/${tableItemObj._id}`}>
            <span className="icon">
              <i className="fas fa-edit"></i>
            </span>
          </Link>
        </div>
        <div className="objects-table__item__cell">{text.objects_table[tableItemObj.status]}</div>
        <div className="objects-table__item__cell">
          {tableItemObj.order &&
            <a
              href={`${channels[tableItemObj.channel].shop_url}/orders/${tableItemObj.order.split('--')[1]}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {tableItemObj.order_name}
            </a>
          }
        </div>
        <div className="objects-table__item__cell desktop">
          {tableItemObj.created_at ? tableItemObj.created_at.split('T')[0] : null}
        </div>
        <div className="objects-table__item__cell">{skuCount}</div>
        <div className="objects-table__item__cell desktop">{stepStatus(skusRefundStatusNb)}</div>
        <div className="objects-table__item__cell desktop">{tableItemObj.channel}</div>
      </li>

      }
    </>

  );
};

export default PriceMatchesTableItem;
