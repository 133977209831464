import axios from 'axios';

export const getAltidollarsRefundCurrentCampaign = async store => {
  store.setState({ requestStatus: 'LOADING' });
  try {
    const response = await axios.get(`${process.env.REACT_APP_ENDPOINT}/altidollars_refund_campaign_search`, {
      headers: store.state.requestHeaders
    });
    const currentCampaign = response.data;

    store.setState({ currentCampaign, requestStatus: 'SUCCESS' });
  } catch (error) {
    console.log(error);
    const isError404 = error.response && error.response.status === 404;
    const requestStatus = isError404 ? 'NOT_FOUND' : 'ERROR';
    store.setState({ requestStatus });
  }
};

export const postAltidollarsRefundCurrentCampaign = async (store, payload) => {
  store.setState({ requestStatus: 'LOADING' });

  try {
    await axios.post(`${process.env.REACT_APP_ENDPOINT}/altidollars_refund_campaign_create`, JSON.stringify(payload), {
      headers: store.state.requestHeaders
    });
    store.setState({ requestStatus: 'SUCCESS', currentCampaign: null });
  } catch (error) {
    console.log(error);
    const isError404 = error.response && error.response.status === 404;
    const requestStatus = isError404 ? 'NOT_FOUND' : 'ERROR';
    store.setState({ requestStatus });
  }
};
