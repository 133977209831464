import React, { useEffect } from 'react';
import useGlobal from '../../store';
import ReturnedItemsItem from './ReturnedItemsItem';

const ReturnedItems = ({ section }) => {
  const [globalState, globalActions] = useGlobal();
  const { text, selectedItems, reasons, requestStatus } = globalState;

  useEffect(() => {
    if (reasons.length < 1) {
      globalActions.db.getReasonsList();
    }
  }, [reasons, globalActions.db]);

  return (
    <div className="return-form__returned-items">
      <p className="label">{text.return_creation.items}</p>
      <div className="return-form__returned-items__list">
        {requestStatus !== 'LOADING' &&
          selectedItems.length > 0 &&
          selectedItems.map(item => <ReturnedItemsItem key={item.uniq_id} selectedItem={item} />)}
      </div>
    </div>
  );
};

export default ReturnedItems;
