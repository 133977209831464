import React, { useState, useEffect } from 'react';
import useGlobal from '../../store';
import ItemsSelectionItem from './ItemsSelectionItem';
import LoadingSpinner from '../UI/LoadingSpinner';

const ItemsSelection = ({ mode }) => {
  const [globalState, globalActions] = useGlobal();
  const { text, requestStatus, availableItems, selectedSeller, sellers, channels, orders, channel, selectedOpenedReturn } = globalState;
  const [loading, setLoading] = useState(false);
  const [mpSellerStep, setMPsellerStep] = useState(false);
  const [noOrderFound, setNoOrderFound] = useState(false);
  const [availableReturns, setAvailableReturns] = useState([]);
  const [selectedAvailableReturnOption, setSelectedAvailableReturnOption] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (selectedSeller) setMPsellerStep(false);
  }, [selectedSeller]);

  async function getOrderItems(e) {
    e.preventDefault();

    globalActions.utils.clearProcessStates();

    setNoOrderFound(false);
    setLoading(true);

    const orderName = e.target.order_name.value;
    const orders = await globalActions.db.getOrders(orderName, channel);

    if (selectedSeller) {
      const lineItems = orders[0].line_items.filter(el => el.quantity - el.fulfillable_quantity > 0);
      const sellerBrands = sellers.find(seller => seller.seller_name === selectedSeller).seller_brands;
      const hasSameSellerItem = lineItems.some(item => sellerBrands.map( brand => brand.toLowerCase()).includes(item.seller.toLowerCase()));

      if (!hasSameSellerItem) {
        globalActions.notification.showMessage(
          `The order ${orderName} doesn't include articles bought from ${selectedSeller}.`,
          'success'
        );
        setLoading(false);
        return;
      }
    }

    if (mode === 'price-match' && orders.length > 0) {
      const membership = orders[0].customer ? orders[0].customer.tags.includes('group_member') : false;

      if (orders[0].fulfillment_status !== 'fulfilled' && orders[0].fulfillment_status !== 'partial') {
        globalActions.utils.updateStateProperty('notShippedYet', true);
        globalActions.utils.updateStateProperty('creditCardException', true);
      }

      globalActions.utils.updateStateProperty('membership', membership);

      const priceMatches = await globalActions.db.getPriceMatches({
        key: 'order',
        value: orderName
      }, channel);

      if (priceMatches && orders) {
        await globalActions.items.setAvailableItems(mode, channel);
      }
    } else if (mode === 'return-creating' && orders.length > 0) {
      const priceMatches = await globalActions.db.getPriceMatches({
        key: 'order',
        value: orderName
      }, channel);

      const returns = await globalActions.db.getReturns({
        key: 'order',
        value: orderName
      });

      if (returns && returns.find(returnsItems => returnsItems.status === 'open')) {
        setAvailableReturns(returns);
        await setShowModal(true);
      }

      if (priceMatches && returns && orders && orders.length > 0) {
        if (!selectedSeller) setMPsellerStep(true);
        await globalActions.items.setAvailableItems(mode, channel);
        if (orders[0].shipping_address && orders[0].shipping_address.country_code !== 'CA') {
          globalActions.utils.updateStateProperty('isInternational', true);
        }
      }
    } else {
      setNoOrderFound(true);
    }

    setLoading(false);
  }

  function submitSelectedSeller(e) {
    e.preventDefault();
    const seller = e.target.seller.value;
    globalActions.items.filterBySeller(seller);
    setMPsellerStep(false);
  }

  // Select which return we wan't to add item to it
  async function selectReturn() {
    const selectedOpenedReturn = availableReturns.find(returnItem => returnItem._id === selectedAvailableReturnOption);

    await globalActions.utils.updateStateProperty('selectedOpenedReturn', selectedOpenedReturn);
    await globalActions.utils.updateStateProperty('prepaidLabelNote', selectedOpenedReturn.prepaid_label_note);
    await globalActions.utils.updateStateProperty('receptionNote', selectedOpenedReturn.reception_note);
    await globalActions.utils.updateStateProperty('tracking', selectedOpenedReturn.tracking_number);
    await globalActions.items.setAvailableItems('return-creating', selectedOpenedReturn.channel);
    await globalActions.items.filterBySeller(selectedOpenedReturn.seller);

    const items = selectedOpenedReturn.items.map(selectedOpenedReturnItem => {
      const orderItem = orders[0].line_items.find(line => line.id.toString() === selectedOpenedReturnItem.line_item_id);
      return globalActions.items.formatAvailableItem(orderItem, orders[0], selectedOpenedReturnItem);
    });
    await globalActions.utils.updateStateProperty('selectedItems', items);

    setMPsellerStep(false)
    setShowModal(false);
  }

  function onValueChange(event) {
    setSelectedAvailableReturnOption(event.target.value);
  }

  return (
    <div className={`items__selection ${mode}`}>
      {orders.length === 0 && (
        <form onSubmit={getOrderItems}>
          <label className="label">{text.items_selection.input_order}</label>
          <div className="field has-addons">
            <div className="control">
              <input
                className="input"
                type="text"
                name="order_name"
                placeholder={text.items_selection.order_number_placeholder}
                required
              />
            </div>
            <div className="control">
              <div className="select">
                <select onChange={e => globalActions.utils.updateStateProperty('channel', e.target.value)}>
                  {Object.keys(channels).map(name => (
                    <option key={channels[name].channel_id} value={name}>
                      {name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="control">
              <button
                className="button is-link"
                type="submit"
                disabled={mpSellerStep && !selectedSeller && availableItems.length > 0}
              >
                {mode === 'return-creating' ? text.items_selection.add : text.items_selection.search}
              </button>
            </div>
          </div>
        </form>
      )}

      {orders.length > 0 && <h4 className="title is-6 mb-2">Order # {orders[0].order_number}</h4>}

      {selectedOpenedReturn && <h3 className="title is-7 mb-2">Return with tracking # {selectedOpenedReturn.tracking_number}</h3>}

      {!loading && requestStatus === 'SUCCESS' && selectedSeller && (
        <>
          <h4 className="title is-6 mb-2">Seller: {selectedSeller}</h4>
          <hr style={{ margin: '0.75rem 0' }}></hr>
        </>
      )}

      {noOrderFound && <p className="label has-text-danger">{text.items_selection.no_order_found}</p>}

      {requestStatus === 'LOADING' && (
        <div className="lds-spinner__container">
          <LoadingSpinner margin="1em 0" />
        </div>
      )}

      {availableItems.length === 0 && !loading && requestStatus === 'SUCCESS' && !noOrderFound && (
        <p>{text.items_selection.no_available_sku}</p>
      )}

      {availableItems.length > 0 && !loading && !mpSellerStep && requestStatus !== 'LOADING' && (
        <>
          <h4 className="title is-6">
            {mode === 'return-creating'
              ? text.items_selection.select_returned_items
              : text.items_selection.select_pricematch_items}
          </h4>

          <ul className={mode}>
            {availableItems.map((item, index) => (
              <ItemsSelectionItem key={index} item={item} mode={mode} />
            ))}
          </ul>
        </>
      )}

      {!loading && !selectedSeller && mpSellerStep && availableItems.length > 0 && sellers.length > 0 && (
        <form onSubmit={submitSelectedSeller}>
          <label className="label">Select seller:</label>

          <div className="field">
            <div className="control">
              <div className="select">
                <select name="seller">
                  {sellers.map(seller => (
                    <option key={seller.seller_name} value={seller.seller_name}>
                      {seller.seller_name}{' '}
                      {seller.seller_brands.length > 1 ? `(${seller.seller_brands.join(', ')})` : null}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="control">
              <button type="submit" className="button is-link mt-2">
                Choose
              </button>
            </div>
          </div>
        </form>
      )}
      <div className={`modal ${showModal ? 'is-active' : null}`}>
        <div onClick={() => setShowModal(false)} className="modal-background"></div>
        <div className="modal-content">
          <div className="box">
            <h3>
              {text.return_creation.same_return_text}
            </h3>
            <div>
              <ul className='table'>
              <li className="available-returns-table__item has-text-weight-bold">
                <div className="objects-table__item__cell"></div>
                <div className="objects-table__item__cell">Tracking number</div>
                <div className="objects-table__item__cell">Created</div>
                <div className="objects-table__item__cell">Seller</div>
              </li>
              {availableReturns.filter(item => item.status === 'open').map(
                returnItem => (
                  <li key={returnItem._id} className="available-returns-table__item has-text-weight-bold">
                    <div className="objects-table__item__cell">
                      <input
                        type="radio"
                        value={returnItem._id}
                        checked={selectedAvailableReturnOption === returnItem._id}
                        onChange={onValueChange}
                      />
                    </div>
                    <div className="objects-table__item__cell">{returnItem.tracking_number}</div>
                    <div className="objects-table__item__cell">{new Date(returnItem.created_at).toLocaleDateString('en-CA')}</div>
                    <div className="objects-table__item__cell">{returnItem.seller}</div>
                  </li>
              ))}
              </ul>
            </div>
            <button onClick={selectReturn} className="button is-info mt-2 mr-5">
              {text.return_creation.same_return_button}
            </button>
            <button onClick={() => setShowModal(false)} className="button is-info mt-2">
              {text.return_creation.new_return_button}
            </button>
          </div>
        </div>
        <button onClick={() => setShowModal(false)} className="modal-close is-large" aria-label="close"></button>
      </div>
    </div>
  );
};

export default ItemsSelection;
