import React from 'react';
import useGlobal from '../../../store';
import { formatDate } from '../../../utils/formatters';

const BulkStoreCreditsTableItem = ({ tableItemObj }) => {
  const [globalState] = useGlobal();
  const { text } = globalState;

  function calculateChunkProgress(tableItemObj) {
    return tableItemObj.total_chunks
      ? Math.round((tableItemObj.completed_chunks / tableItemObj.total_chunks) * 100)
      : 0;
  }

  return (
    <li className="bulk-credits-table__item">
      <div className="objects-table__item__cell desktop">{tableItemObj.campaign_name ? tableItemObj.campaign_name : null}</div>

      <div className="objects-table__item__cell">{tableItemObj.amount}$</div>

      <div className="objects-table__item__cell">
        {tableItemObj.expiry_date ? tableItemObj.expiry_date.split('T')[0] : null}
      </div>

      <div className="objects-table__item__cell">{tableItemObj.mode}</div>

      <div className="objects-table__item__cell desktop">{tableItemObj.total_rows}</div>

      <div className="objects-table__item__cell desktop">
        {tableItemObj.status === 'processed'
          ? tableItemObj.total_rows - tableItemObj.duplicates - tableItemObj.failed_emails_count
          : null}
      </div>

      <div className="objects-table__item__cell">{text.objects_table[tableItemObj.status]}</div>

      <div className="objects-table__item__cell desktop">
        {tableItemObj.created_at ? tableItemObj.created_at.split('T')[0] : null}
      </div>

      <div className="objects-table__item__cell desktop">
        {tableItemObj.started_at ? formatDate(tableItemObj.started_at).split(',')[1] : null}
      </div>

      <div className="objects-table__item__cell desktop">
        {tableItemObj.finished_at ? formatDate(tableItemObj.finished_at).split(',')[1] : null}
      </div>

      <div className="objects-table__item__cell desktop">
        <progress className="object-table_progressBar" value={calculateChunkProgress(tableItemObj)} max="100">
          {' '}
          {calculateChunkProgress(tableItemObj)}{' '}
        </progress>
      </div>
    </li>
  );
};

export default BulkStoreCreditsTableItem;
