import { v4 as uuid } from 'uuid';
import { isTransactionInstalmentSuccess } from "./utils";
import fr from '../../locales/fr';
import en from '../../locales/en';

export const addSelectedItem = async (store, skuOrUPC, mode, lineItemCurrentPrice) => {
  store.setState({ requestStatus: 'LOADING' });

  let selectedItem;

  const availableItem = store.state.availableItems.find(item => item.sku.includes(skuOrUPC));

  if (mode === 'processing' && availableItem && availableItem.sku.includes('MK')) {
    store.setState({ requestStatus: 'INITIAL' });
    return store.actions.notification.showDialog(
      store.state.text.store.error_marketplace_title,
      store.state.text.store.error_marketplace
    );
  }

  if (availableItem) {
    if (mode === 'price-match') {
      const priceHistory = await store.actions.db.getPriceHistory(skuOrUPC, availableItem.channel);
      selectedItem = {
        ...availableItem,
        line_item_current_price: parseFloat(lineItemCurrentPrice),
        validated: true,
        requested_price: null,
        price_history: priceHistory,
        member_type: 'none',
        price_match_type: 'internal',
        discount: false,
        competitor_url: ''
      };
    } else {
      selectedItem = { ...availableItem, validated: true };
    }
  } else {
    const skuObj = await store.actions.db.getSkuFromUPC(skuOrUPC);
    if (skuObj) {
      const availableItemFromUPC = store.state.availableItems.find(item => item.sku.includes(skuObj.sku));

      if (mode === 'processing' && availableItem && availableItem.sku.includes('MK')) {
        store.setState({ requestStatus: 'INITIAL' });
        return store.actions.notification.showDialog(
          store.state.text.store.error_marketplace_title,
          store.state.text.store.error_marketplace
        );
      }

      if (availableItemFromUPC) {
        selectedItem = { ...availableItemFromUPC, validated: true };
      } else {
        selectedItem = {
          title: skuObj.handle,
          sku: skuObj.sku,
          uniq_id: uuid(),
          validated: false,
          vendor: skuObj.vendor
        };

        store.actions.notification.showMessage(store.state.text.store.item_unavailable, 'error');
      }
    } else {
      store.setState({ requestStatus: 'SUCCESS' });
    }
  }

  if (selectedItem) {
    const updatedAvailableItems = [...store.state.availableItems];

    if (selectedItem.validated) {
      updatedAvailableItems.splice(
        updatedAvailableItems.findIndex(item => item.uniq_id === selectedItem.uniq_id),
        1
      );
    }

    store.setState({
      availableItems: updatedAvailableItems,
      selectedItems: [selectedItem, ...store.state.selectedItems],
      requestStatus: 'SUCCESS'
    });
  }
};

export const removeSelectedItem = (store, item) => {
  const updatedSelectedItems = [...store.state.selectedItems];
  updatedSelectedItems.splice(
    updatedSelectedItems.findIndex(el => el.uniq_id === item.uniq_id),
    1
  );

  const updatedAvailableItems = [...store.state.availableItems];
  if (item.validated) {
    updatedAvailableItems.push(item);
  }

  store.setState({
    availableItems: updatedAvailableItems,
    selectedItems: updatedSelectedItems
  });
};

export const updateValidatedItem = async (store, item, orderNb, orderChannel) => {
  const updatedSelectedItems = [...store.state.selectedItems];
  store.setState({ selectedItems: [] });

  const orders = await store.actions.db.getOrders(orderNb, orderChannel);

  store.setState({ requestStatus: 'LOADING' });

  if (orders.length > 0) {
    const returns = await store.actions.db.getReturns({
      key: 'order',
      value: orderNb
    });
    returns && store.actions.items.setAvailableItems('processing', orderChannel);

    const updatedAvailableItems = [...store.state.availableItems];

    if (updatedAvailableItems.find(el => el.sku === item.sku)) {
      const validatedItemIndex = updatedAvailableItems.findIndex(
        el => el.sku === item.sku && el.order_name === orderNb
      );

      updatedAvailableItems[validatedItemIndex].validated = true;
      updatedSelectedItems.unshift(updatedAvailableItems[validatedItemIndex]);
      updatedSelectedItems.splice(
        updatedSelectedItems.findIndex(el => el.uniq_id === item.uniq_id),
        1
      );
      updatedAvailableItems.splice(validatedItemIndex, 1);

      store.setState({
        availableItems: updatedAvailableItems,
        selectedItems: updatedSelectedItems,
        requestStatus: 'SUCCESS'
      });
      store.actions.notification.hideMessage();
    } else {
      store.actions.notification.showMessage(store.state.text.store.item_unavailable_in_order, 'error');
      store.setState({
        requestStatus: 'SUCCESS',
        selectedItems: updatedSelectedItems,
        orders: store.state.orders.filter(order => order.order_number !== +orderNb)
      });
    }
  } else {
    store.actions.notification.showMessage('No order found.', 'error');
    store.setState({ requestStatus: 'SUCCESS', selectedItems: updatedSelectedItems });
  }

  return true;
};

export const toggleItemRefundException = (store, e, itemId) => {
  e.preventDefault();
  const updatedSelectedItems = [...store.state.selectedItems];
  const selectedItemIndex = updatedSelectedItems.findIndex(el => el.uniq_id === itemId);
  updatedSelectedItems[selectedItemIndex].refund_exception = !updatedSelectedItems[selectedItemIndex].refund_exception;

  store.setState({ selectedItems: updatedSelectedItems });
};

// AVAILABLE ITEMS FUNCTIONS:
export const setAvailableItems = async (store, mode, channel) => {
  const availableItemsLength = store.state.availableItems.length;

  let ordersItems = formatAvailableOrdersItems(store, mode);
  ordersItems = filterBySelectedSeller(store, ordersItems);
  ordersItems = filterOutSelectedItems(store.state.selectedItems, ordersItems);

  let returnObjects;
  if (channel) {
    returnObjects = store.state.returns.filter(returnObj => returnObj.channel === channel);
  } else {
    returnObjects = store.state.returns;
  }

  if (mode !== 'price-match' && returnObjects.length > 0) {
    returnObjects.forEach(returnObj => {
      if (mode === 'return-creating') {
        removeUnavailableItems(returnObj, ordersItems);
      } else if (mode === 'processing') {
        if (returnObj.status === 'closed' || returnObj.status === 'processing') {
          removeUnavailableItems(returnObj, ordersItems);
        }
      }
    });
    store.setState({ availableItems: ordersItems });
  } else if (mode === 'price-match') {
    const availableItems = setPriceMatchAvailableItems(ordersItems, store.state.orders[0]);
    store.setState({ availableItems });
  } else {
    store.setState({ availableItems: ordersItems });
  }

  const hasEmptyObj =
    returnObjects.length > 0 && returnObjects.find(returnObj => returnObj.received_at && returnObj.items.length === 0);

  if (availableItemsLength === ordersItems.length && !hasEmptyObj && store.selectedOpenedReturn) {
    store.actions.notification.showMessage(store.state.text.store.error_no_sku_available, 'error');
  }

  if (!store.state.selectedSeller) await setSellersOptions(store);

  return;
};

function setPriceMatchAvailableItems(ordersItemsMinusSelectedItems, order) {
  const refunds = order.refunds;
  if (refunds.length > 0) {
    const lineItemsRefunds = refunds.map(refund => refund.refund_line_items).flat();

    lineItemsRefunds.forEach(refund => {
      const item = ordersItemsMinusSelectedItems.find(
        el => el.line_item_id.toString() === refund.line_item_id.toString()
      );
      item.quantity_ordered -= refund.quantity;
    });
  }
  return ordersItemsMinusSelectedItems.filter(item => item.quantity_ordered > 0);
}

function toFixed(num, precision) {
  return (+(Math.round(+(num + 'e' + precision)) + 'e' + -precision)).toFixed(precision);
}

function setAltidollarsRefundField(store, order, paidPriceBeforeMembershipAndCoupon, originalItemPrice) {
  const orderCreationDate = order.created_at.split('T')[0];
  const now = new Date();
  const orderDate = new Date(order.created_at);
  const orderDatePlusBuffer = new Date(orderDate.setTime(orderDate.getTime() + 36 * 60 * 60 * 1000));
  const isOrderCancellation = now <= orderDatePlusBuffer;
  const isInstalmentsGateway = order.transactions.some(isTransactionInstalmentSuccess);

  if (store.state.currentCampaign && !isOrderCancellation && store.state.channels[order.channel].store_credits_policy && !isInstalmentsGateway) {
    const { starting_at, ending_at, threshold } = store.state.currentCampaign;
    const startingDate = starting_at ? starting_at.split('T')[0] : null;
    const endingDate = ending_at ? ending_at.split('T')[0] : null;
    const isAltiRefundCheck = (1 - paidPriceBeforeMembershipAndCoupon / originalItemPrice) * 100 >= Number(threshold);

    if (startingDate && !endingDate && orderCreationDate >= startingDate) {
      return isAltiRefundCheck;
    } else if (startingDate && endingDate && orderCreationDate >= startingDate && orderCreationDate <= endingDate) {
      return isAltiRefundCheck;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

function addReturnObjectDataToItem(store, formattedAvailableItem, order) {
  const returnObj = store.state.returns.find(
    el => el.order_name === order.order_number.toString() && (el.status === 'open' || el.status === 'received')
  );
  const returnObjItems = returnObj ? returnObj.items : null;
  const matchedItem = returnObjItems
    ? returnObjItems.find(el => el.line_item_id.toString() === formattedAvailableItem.line_item_id.toString())
    : null;

  const updatedFormattedAvailableItem = JSON.parse(JSON.stringify(formattedAvailableItem));

  if (matchedItem) {
    Object.keys(matchedItem).forEach(key => {
      if (updatedFormattedAvailableItem[key] !== undefined) {
        updatedFormattedAvailableItem[key] = matchedItem[key];
      }
    });
    if (returnObj && returnObj.bypass_altidollars_refund_campaign) {
      updatedFormattedAvailableItem.altidollar_refund = false;
    }
    return updatedFormattedAvailableItem;
  } else {
    if (returnObj && returnObj.bypass_altidollars_refund_campaign) {
      formattedAvailableItem.altidollar_refund = false;
    }
    return formattedAvailableItem;
  }
}

export const formatAvailableItem = (store, item, order, selectedOpenedReturnItem) => {
  const paidPrice = Number(toFixed(item.pre_tax_price / item.quantity, 2));
  const paidPriceBeforeMembershipAndCoupon = Number(item.price);
  const originalItemPrice = item.original_price ? Number(item.original_price) : paidPrice;

  const isInstalmentsGateway = order.transactions.some(isTransactionInstalmentSuccess);
  const formattedAvailableItem = {
    altidollar_refund: setAltidollarsRefundField(store, order, paidPriceBeforeMembershipAndCoupon, originalItemPrice),
    channel: order.channel,
    image_src: item.image_src ? item.image_src : '',
    line_item_id: item.id.toString(),
    order_name: order.order_number.toString(),
    original_price: originalItemPrice,
    paid_price: paidPrice,
    quantity_ordered: item.quantity,
    refund_exception: false,
    restock_exception: false,
    reason: selectedOpenedReturnItem?.reason || null,
    sale_price: paidPriceBeforeMembershipAndCoupon,
    sku: item.sku,
    title: item.title,
    uniq_id: uuid(),
    variant_title: item.variant_title,
    vendor: item.vendor,
    seller: item.seller ? item.seller : '',
  };

  if (isInstalmentsGateway) formattedAvailableItem.isInstalmentsGateway = true;

  const formattedAvailableItemPlusData = addReturnObjectDataToItem(store, formattedAvailableItem, order);

  return formattedAvailableItemPlusData;
}

export const formatAvailableOrdersItems = (store, mode) => {
  return store.state.orders
    .map(order => {
      const lineItems = order.line_items;

      return lineItems
        .map(item => {
          const quantityAvailableToReturn = item.quantity - item.fulfillable_quantity;

          if (quantityAvailableToReturn === 1 || mode === 'price-match') {
            return formatAvailableItem(store, item, order);
          } else if (quantityAvailableToReturn > 1) {
            const items = [];
            for (let i = 0; i < quantityAvailableToReturn; i++) {
              items.push(formatAvailableItem(store, item, order));
            }
            return items;
          } else {
            return null;
          }
        })
        .flat();
    })
    .flat();
}

function removeUnavailableItems(returnObj, ordersItems) {
  returnObj.items.forEach(item => {
    const i = ordersItems.findIndex(
      orderItem => orderItem.order_name === returnObj.order_name && item.sku === orderItem.sku
    );
    if (i > -1) {
      ordersItems.splice(i, 1);
    }
  });
}

function filterBySelectedSeller(store, ordersItems) {
  let availableChannelNames = Object.keys(store.state.channels).map(channel_abbr => store.state.channels[channel_abbr].channel_name_en);
  if (store.state.selectedSeller) {
    if (availableChannelNames.indexOf(store.state.selectedSeller) > -1) {
      ordersItems = ordersItems.filter(item => !null || !item.sku.includes('MK'));
    } else {
      const sellerBrands = store.state.sellers.find(seller => seller.seller_name === store.state.selectedSeller)
        .seller_brands;

      ordersItems = ordersItems.filter(item => item.sku.includes('MK') && sellerBrands.map( brand => brand.toLowerCase()).includes(item.seller.toLowerCase()));
    }
  }
  return ordersItems;
}

function filterOutSelectedItems(selectedItems, ordersItems) {
  return ordersItems.filter(item => {
    if (selectedItems.some(el => el.validated && el.order_name === item.order_name && el.sku === item.sku)) {
      return null;
    } else {
      return item;
    }
  });
}

async function setSellersOptions(store) {
  // To set AS selling brands
  let brands = store.state.availableItems
    .filter(item => !item.sku.includes('MK'))
    .map(nonMkItem => nonMkItem.channel);

  brands = [...new Set(brands)];
  let channels = store.state.channels;
  Object.keys(channels).forEach((channel_abbr) => {
    if (brands.indexOf(channel_abbr) > -1) {
      store.setState({ sellers: [{ seller_brands: [channels[channel_abbr].channel_name_en], seller_name: channels[channel_abbr].channel_name_en }] });
    }
    return;
  });
  // To check Sellers for MKitems (if any)
  let mk_shop_ids = store.state.availableItems.filter(item => item.sku.includes('MK')).map(item => item.sku.split('_')[2]);

  if (mk_shop_ids.length > 0) {
    let marketplaceSellers = await store.actions.db.getMarketplaceSellers(mk_shop_ids);
    marketplaceSellers = marketplaceSellers.filter(seller => {
      return store.state.availableItems.find(item => seller.seller_brands.map( brand => brand.toLowerCase()).includes(item.seller.toLowerCase()));
    });
    store.setState({ sellers: [...store.state.sellers, ...marketplaceSellers] });
  }

  return;
}

export const filterBySeller = (store, sellerName) => {
  let filteredItems;

  let availableChannelNames = Object.keys(store.state.channels).map(channel_abbr => store.state.channels[channel_abbr].channel_name_en);
  if (availableChannelNames.indexOf(sellerName) > -1) {
    filteredItems = store.state.availableItems.filter(item => !item.sku.includes('MK'));
  } else {
    const sellerBrands = (store.state.sellers
      .find(seller => seller.seller_name === sellerName).seller_brands)
      .map(item => item.toLowerCase());
    filteredItems = store.state.availableItems.filter(item => {
      const sellerField = item.seller.length > 0 ? item.seller.toLowerCase() : item.vendor.toLowerCase();
      return sellerBrands.indexOf(sellerField) > -1 && item.sku.includes('MK');
    });
  }

  store.setState({ availableItems: filteredItems, selectedSeller: sellerName });
};

// Cerberus desktop only

export const updateItem = (store, item, key, value) => {
  const updatedSelectedItems = [...store.state.selectedItems];
  const itemIndex = updatedSelectedItems.findIndex(el => el.uniq_id === item.uniq_id);
  updatedSelectedItems[itemIndex][key] = value;

  if (key === 'reason' && value._id === 7) {
    updatedSelectedItems[itemIndex].refund_exception = true;
  }

  store.setState({ selectedItems: updatedSelectedItems });
  if (key === 'reason') {
    setNotes(store);
  }
};

function getBlockingReasonsSettings(store) {
  return {
    2: {
      receptionNote: store.state.text.return_processing.looks_different_from_image,
      prepaidLabelNote: buildPrepaidLabelNote('looks_different_from_image'),
      restockingFeeException: false
    },
    3: {
      receptionNote: store.state.text.return_processing.inaccurate_description,
      prepaidLabelNote: buildPrepaidLabelNote('inaccurate_description'),
      restockingFeeException: false
    },
    6: {
      receptionNote: store.state.text.return_processing.received_wrong_item,
      prepaidLabelNote: buildPrepaidLabelNote('received_wrong_item'),
      restockingFeeException: false
    },
    7: {
      receptionNote: store.state.text.return_processing.doa,
      prepaidLabelNote: 'DOA',
      restockingFeeException: true
    },
    9: {
      receptionNote: store.state.text.return_processing.poor_quality,
      prepaidLabelNote: buildPrepaidLabelNote('poor_quality'),
      restockingFeeException: false
    },
    10: {
      receptionNote: store.state.text.return_processing.warranty,
      prepaidLabelNote: 'WARRANTY-GARANTIE',
      restockingFeeException: true
    },
  };
}

function buildPrepaidLabelNote(reason) {
  const msgInFrench = fr.return_processing[reason].replace('Veuillez procéder manuellement.', '').replace('.', '');
  const msgInEnglish = en.return_processing[reason].replace('Please process manually.', '').replace('.', '');
  return `${msgInEnglish} - ${msgInFrench}`;
}

function findBlockingReasonSettings (store) {
  const blockingReasonsSettings = getBlockingReasonsSettings(store);

  const itemWithBlockingReason = store.state.selectedItems.find(
    item => item.reason && item.reason._id in blockingReasonsSettings
  );

  if (itemWithBlockingReason) {
    return blockingReasonsSettings[itemWithBlockingReason.reason._id];
  }

  return null;
}

function setNotes(store) {
  const blockingReasonSettings = findBlockingReasonSettings(store);

  if (blockingReasonSettings) {
    const {
      receptionNote,
      prepaidLabelNote,
      restockingFeeException
    } = blockingReasonSettings;

    store.setState({ receptionNote });
    store.setState({ prepaidLabelNote });
    store.setState({ restockingFeeException });
  } else {
    cleanBlockingReasonsFromNotes(store, 'receptionNote');
    cleanBlockingReasonsFromNotes(store, 'prepaidLabelNote');
    store.setState({ restockingFeeException: false });
  }
}

function cleanBlockingReasonsFromNotes(store, noteType) {
  const blockingReasonsSettings = getBlockingReasonsSettings(store);

  for (const aSetting of Object.values(blockingReasonsSettings)) {
    store.setState({
      [noteType]: store.state[noteType].replace(aSetting[noteType], '')
    });
  }
}
