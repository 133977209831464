import React, { useState, useEffect } from 'react';
import useGlobal from '../../../store';

const FailedReturnsCount = () => {
  const [globalState] = useGlobal();
  const { text, listTags } = globalState;

  const [failedReturnsCount, setFailedReturnsCount] = useState(0);

  useEffect(() => {
    if (listTags) {
      const numberOfReturnErrors = Object.values(listTags).reduce((prev, curr) => curr.total + prev, 0);
      setFailedReturnsCount(numberOfReturnErrors);
    }
  }, [listTags]);

  return <div className='return__failed_return_error_count'>
    <p>{failedReturnsCount} {text.objects_table.failed_returns_count_error}</p>
    <p>{text.objects_table.failed_returns_count_metrics_refresh}</p>
  </div>;
};

export default FailedReturnsCount;
