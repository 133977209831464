import React from 'react';
import useGlobal from '../../store';
import ProcessingItemsItem from './ProcessingItemsItem';

const ProcessingItems = ({ currentProcessingChannel }) => {
  const [globalState, globalActions] = useGlobal();
  const { text, requestStatus, processing, selectedItems, returns, orders } = globalState;
  const skuInput = React.createRef();

  const blockingReasons = {
    6: text.return_processing.received_wrong_item,
    7: text.return_processing.doa,
    9: text.return_processing.poor_quality,
    10: text.return_processing.warranty
  };

  const allReturnsItems = returns.map(aReturn => aReturn.items).flat();
  const itemWithBlockingReason = allReturnsItems.find(
    item => (item.reason && item.reason._id in blockingReasons)
  );

  function addSku(e) {
    e.preventDefault();
    globalActions.notification.hideMessage();
    const skuOrUPC = e.target.skuOrUPC.value;
    if (skuOrUPC.length > 10) {
      globalActions.items.addSelectedItem(skuOrUPC, 'processing');
      skuInput.current.value = '';
      skuInput.current.focus();
    } else {
      globalActions.notification.showMessage(text.return_processing.error_invalid_sku, 'error');
    }
  }

  return (
    <>
      {!processing &&
        requestStatus !== 'LOADING' &&
        itemWithBlockingReason && (
          <h3 id="blocking-reason">{blockingReasons[itemWithBlockingReason.reason._id]}</h3>
        )}

      {!processing &&
        requestStatus === 'SUCCESS' &&
        orders.length > 0 &&
        !itemWithBlockingReason && (
          <form className="item-search__form" onSubmit={addSku}>
            <div className="field has-addons has-addons-centered">
              <div className="control">
                <input
                  className="input"
                  ref={skuInput}
                  name="skuOrUPC"
                  placeholder={text.return_processing.input_sku_placeholder}
                  autoComplete="off"
                  autoFocus
                />
              </div>
              <div className="control">
                <button className="button" type="submit">
                  {text.return_processing.add}
                </button>
              </div>
            </div>
          </form>
        )}

      {!processing && selectedItems.length > 0 && (
        <div className="cards-container">
          {selectedItems.map(item => (
            <ProcessingItemsItem key={item.uniq_id} item={item} currentProcessingChannel={currentProcessingChannel} />
          ))}
        </div>
      )}
    </>
  );
};

export default ProcessingItems;
