import React from 'react';
import useGlobal from '../../store';

const PriceMatchNote = () => {
  const [globalState, globalActions] = useGlobal();
  const { text } = globalState;

  return (
    <div className="price-match-form__note has-text-left">
      <h2 className="label">{text.price_match_form.note}</h2>
      <div className="field">
        <div className="control">
          <textarea
            onInput={e => {
              globalActions.utils.updateStateProperty('priceMatchNote', e.target.value);
            }}
            className="textarea"
            placeholder={text.price_match_form.note_placeholder}
            maxLength="200"
          ></textarea>
        </div>
      </div>
    </div>
  );
};

export default PriceMatchNote;
