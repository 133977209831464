import React, { useEffect } from 'react';
import useGlobal from './store';
import Navbar from './components/Navbar';
import { useAuth0 } from './authService/react-auth0-wrapper';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import LoadingSpinner from './components/UI/LoadingSpinner';
import Homepage from './pages/Homepage.js';
import Returns from './pages/Returns.js';
import ReturnReceiving from './pages/ReturnReceiving.js';
import ReturnProcessing from './pages/ReturnProcessing.js';
import ReturnCreation from './pages/ReturnCreation.js';
import PriceMatches from './pages/PriceMatches';
import PriceMatch from './pages/PriceMatch';
import SkuPriceHistory from './pages/SkuPriceHistory';
//import AltidollarsRefundCampaign from './pages/AltidollarsRefundCampaign';
import Messages from './components/UI/Messages';
import ReturnReceptionNote from './pages/ReturnReceptionNote';
import BulkStoreCredit from './pages/BulkStoreCredit';
import BatchStoreCreditList from './pages/BatchStoreCreditsList';
import AltidollarsFixer from './pages/AltidollarsFixer';
import AltidollarsFixerList from './pages/AltidollarsFixerList';
import Moderation from './pages/Moderation';

function App() {
  const { loading, unparsedAccessToken, user } = useAuth0();
  const [globalState, globalActions] = useGlobal();
  const { agentEmail, requestHeaders } = globalState;

  useEffect(() => {
    !agentEmail && user && user.email && globalActions.utils.updateStateProperty('agentEmail', user.email);
  }, [agentEmail, user, globalActions]);

  useEffect(() => {
    if (!requestHeaders && unparsedAccessToken) {
      const headers = { 'X-API-KEY': unparsedAccessToken };
      globalActions.utils.updateStateProperty('requestHeaders', headers);
    }
  }, [requestHeaders, unparsedAccessToken, globalActions]);

  return (
    <div className="App">
      {loading && <LoadingSpinner />}

      {!loading && (
        <Router>
          <Navbar />
          <div className="container">
            <Messages />
          </div>
          <Switch>
            <Route exact path={'/'} render={() => <Homepage />} />
            <PrivateRoute
              access={['admin', 'cx_agent', 'cx_manager', 'return_manager', 'return_agent']}
              path="/returns"
              component={Returns}
            />
            <PrivateRoute
              access={['admin', 'cx_agent', 'cx_manager']}
              path="/return-creation"
              component={ReturnCreation}
            />
            <PrivateRoute
              access={['admin', 'cx_agent', 'cx_manager']}
              path="/return-reception-note"
              component={ReturnReceptionNote}
            />
            <PrivateRoute
              access={['admin', 'return_manager', 'return_agent']}
              path="/return-receiving"
              component={ReturnReceiving}
            />
            <PrivateRoute
              access={['admin', 'return_manager', 'return_agent']}
              path="/return-processing"
              component={ReturnProcessing}
            />
            <PrivateRoute access={['admin', 'cx_manager', 'cx_agent']} path="/price-matches" component={PriceMatches} />
            <PrivateRoute access={['admin', 'cx_manager', 'cx_agent']} path="/price-match" component={PriceMatch} />
            <PrivateRoute
              access={['admin', 'cx_manager', 'cx_agent']}
              path="/sku-price-history"
              component={SkuPriceHistory}
            />
            <PrivateRoute
              access={['admin', 'marketing_manager', 'marketing_agent']}
              path="/store-credits-bulk-actions"
              component={BulkStoreCredit}
            />
            <PrivateRoute
              access={['admin', 'marketing_manager', 'marketing_agent']}
              path="/store-credits-bulk-actions-list"
              component={BatchStoreCreditList}
            />
            <PrivateRoute
              access={['admin', 'cx_manager', 'cx_agent']}
              path="/altidollars-fixer"
              component={AltidollarsFixer}
            />
            <PrivateRoute
              access={['admin', 'cx_manager', 'cx_agent']}
              path="/altidollars-fixer-list"
              component={AltidollarsFixerList}
            />
            <PrivateRoute
              access={['admin', 'cx_manager', 'cx_agent']}
              path="/moderation"
              component={Moderation}
              />
          </Switch>
        </Router>
      )}
    </div>
  );
}

export default App;
