import React, { /*useState,*/ useEffect } from 'react';
import useGlobal from '../store';
import Dialogs from '../components/UI/Dialogs';
import LoadingSpinner from '../components/UI/LoadingSpinner';

const Receiving = () => {
  const [globalState, globalActions] = useGlobal();
  const { text, requestStatus } = globalState;
  // const [priority, setPriority] = useState(null);

  useEffect(() => {
    globalActions.utils.clearProcessStates();
  }, [globalActions.utils]);

  async function stampReturnObjects(returnObjects, chosenTracking) {
    await Promise.all(
      returnObjects.map(returnObj => {
        if (returnObj.received_at === null || returnObj.received_at === '') {
          const update = chosenTracking
            ? {
              status: 'received',
              bundle_tracking_numbers: [],
              tracking_number: chosenTracking
            }
            : {
              status: 'received'
            };

          const res = globalActions.db.updateReturnObj(update, returnObj._id);

          return res;
        } else if (returnObj.received_at.length > 0) {
          return { status: 'SUCCESS' };
        } else {
          return { status: 'ERROR' };
        }
      })
    ).then(values => {
      if (values.length > 0 && values.every(el => el.status === 'SUCCESS')) {
        globalActions.notification.showMessage(text.return_reception.success_updating, 'success');
        setTimeout(() => {
          globalActions.notification.hideMessage();
        }, 3000);
      } else {
        globalActions.notification.showMessage(text.return_reception.failed_updating, 'error');
      }
    });
  }

  async function createReturnObject(trackingData) {
    let response;
    if (trackingData) {
      response = await globalActions.processing.receiveReturn(trackingData);
    } else {
      response = await globalActions.processing.receiveReturn();
    }
    if (response?.status === 'SUCCESS') {
      globalActions.utils.clearProcessStates();
      globalActions.notification.showMessage(text.return_reception.success_creating, 'success');
    } else {
      globalActions.utils.clearProcessStates();
      globalActions.notification.showMessage(text.return_reception.failed_creating, 'error');
    }
  }

  function displayPopUp(returnObj) {
    const receptionNote = returnObj.reception_note;
    const customerTags = returnObj.customer_tags ? returnObj.customer_tags : '';

    let popUpContent = [];

    if (receptionNote && receptionNote.length > 0) {
      popUpContent.push(receptionNote);
    }

    if (customerTags.includes('RNC')) {
      popUpContent.push(text.return_reception.rnc);
    }

    popUpContent.length > 0 &&
      globalActions.notification.showDialog(text.return_reception.reception_note, popUpContent.join(' | '));
  }

  async function processTracking(tracking) {
    const returnObjects = await globalActions.db.getReturns(
      {
        key: 'tracking',
        value: tracking.number
      }
    );

    if (returnObjects.length > 0) {
      stampReturnObjects(returnObjects);
      displayPopUp(returnObjects[0]);

      // returnObjects[0].priority && setPriority(returnObjects[0].priority);
    } else {
      const bundleReturnObjects = await globalActions.db.getReturns(
        {
          key: 'bundle_tracking',
          value: tracking.number
        }
      );
      if (bundleReturnObjects.length > 0) {
        stampReturnObjects(bundleReturnObjects, tracking.number);
      } else {
        globalActions.utils.updateStateProperty('carrier', tracking.carrier);
        const trackingData = await globalActions.db.getTracking(tracking.number);

        if (trackingData) {
          const orderReturnObjects = await globalActions.db.getReturns(
            { key: 'order', value: trackingData.order_name },
            trackingData.channel
          );

          if (orderReturnObjects.length > 0) {
            stampReturnObjects(orderReturnObjects, tracking.number);
          } else {
            createReturnObject(trackingData);
          }
        } else {
          createReturnObject();
        }
      }
    }
  }

  function handleSearch(e) {
    let trackingNumber = e.target.tracking.value;
    e.preventDefault();
    globalActions.utils.clearProcessStates();

    globalActions.utils.updateStateProperty('requestStatus', 'LOADING');

    //setPriority(null);
    const tracking = globalActions.utils.parseCarrierTrackingBarcode(trackingNumber);

    if (tracking.carrier) {
      processTracking(tracking);
    } else {
      globalActions.utils.updateStateProperty('requestStatus', 'INITIAL');

      globalActions.notification.showDialog(
        text.return_reception.confirm,
        `${text.return_reception.error_invalid_tracking_a} ${tracking.number} ${text.return_reception.error_invalid_tracking_b}`,
        () => processTracking(tracking)
      );
    }
  }

  return (
    <>
      <main className="container">
        <section className="section">
          {/* priority > 0 && (
            <span className="tag is-large is-primary">
              Priority: {priority}&nbsp;&nbsp;
              <span onClick={() => setPriority(null)} className="icon cursor-pointer">
                <i className="fas fa-times-circle"></i>
              </span>
            </span>
          ) */}

          <h1 className="title is-4">{text.return_reception.title}</h1>

          {requestStatus === 'LOADING' && <LoadingSpinner />}

          {requestStatus !== 'LOADING' && (
            <form onSubmit={handleSearch}>
              <div className="field has-addons has-addons-centered">
                <div className="control">
                  <input
                    className="input"
                    name="tracking"
                    placeholder={text.return_reception.tracking_input_placeholder}
                    autoComplete="off"
                    autoFocus={true}
                    required
                  />
                </div>
                <div className="control">
                  <button className="button" type="submit">
                    {text.return_reception.submit}
                  </button>
                </div>
              </div>
            </form>
          )}

          <Dialogs />
        </section>
      </main>
    </>
  );
};

export default Receiving;
