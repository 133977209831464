import React, { useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import useGlobal from '../../store';

const Dialogs = () => {
  const [globalState, globalActions] = useGlobal();
  const [isDisabled, setIsDisabled] = useState(false);
  const { text, dialog } = globalState;

  function confirm(e) {
    e.preventDefault();
    setIsDisabled(true);
    globalActions.notification.confirm(true);
    setTimeout(() => {
      setIsDisabled(false);
    }, 1500);
  }

  function cancel(e) {
    e.preventDefault();
    globalActions.notification.confirm(false);
  }

  const dialogContent = !dialog.open
    ? []
    : [
        <div className="message-modal-container">
          <div className="message is-dark message-dialog">
            <div className="message-header">
              <h2 className="title is-6 has-text-white has-text-centered">{dialog.title}</h2>
            </div>

            <div className="message-body">
              <div className="has-text-weight-bold">{dialog.message}</div>
              {dialog.action === undefined || dialog.action === null ? (
                <form className="one-button">
                  <button id="dialog-ok" className="action button is-link" onClick={cancel}>
                    Ok
                  </button>
                </form>
              ) : (
                <div className="buttons is-centered">
                  <button className="button is-danger is-outlined" onClick={cancel}>
                    {text.dialog.cancel}
                  </button>
                  <button className="button is-dark" onClick={confirm} disabled={isDisabled}>
                    Ok
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      ];

  return (
    <TransitionGroup>
      {dialogContent.map((item, index) => (
        <CSSTransition key={index} timeout={100} classNames="dialog-container dialog">
          {item}
        </CSSTransition>
      ))}
    </TransitionGroup>
  );
};

export default Dialogs;
