const fr = {
  dialog: {
    cancel: 'Annuler'
  },
  store: {
    error_fetching_returns: 'Échec de téléchargement des retours. Veuillez contacter les TIs',
    error_fetching_orders: 'Échec de téléchargement des commandes. Veuillez contacter les TIs',
    success_updating_tracking: 'Données de repérage mises à jours avec succès.',
    error_invalid_sku: 'SKU non-valide ou non-disponible.',
    error_fetching_sku: 'Échec de téléchargement du SKU. Veuillez contacter les TIs.',
    error_empty_reasons_list: 'La liste des raisons de retour est vide.',
    error_fetching_reasons: 'Échec de téléchargement des raisons de retour. Veuillez contacter les TIs.',
    error_label_create_fail: "Échec de création de l'étiquette de retour. Veuillez contacter les TIs.",
    error_fetching_price_matches: 'Échec de téléchargement des ajustements de prix. Veuillez contacter les TIs',
    item_unavailable: 'Article non-disponible - entrez le # de commande',
    item_unavailable_in_order:
      "Cet article n'est pas disponible dans cette commande. Veuillez entrer un nouveau numéro de commande.",
    warranty: 'Garrantie. Veuillez procéder manuellement.',
    doa: 'DOA. Veuillez procéder manuellement.',
    return_post_success: 'Retour(s) envoyé(s) avec succès',
    error_return_post_fail: "Échec d'envoi du/des retour(s)",
    sku_update_success: 'SKUs mis à jour avec succès.',
    error_no_sku_available: 'Aucun article disponible pour cette commande.',
    error_marketplace_title: 'Article Marketplace',
    error_marketplace:
      'Veuillez contacter le Service à la clientèle pour procéder au remboursement. Ne pas rembourser dans Shopify.'
  },
  homepage: {
    sign_in: 'Se connecter',
    sign_out: 'Se déconnecter',
    no_access: "Désolé, vous n'avez pas les accès pour cette section. Veuillez contacter le support technique."
  },
  navbar: {
    returns: 'Retours',
    returns_list: 'Liste des retours',
    return_form: 'Formulaire de retour',
    return_reception_note: 'Créer une note de réception',
    return_receiving: 'Recevoir un retour',
    return_processing: 'Traiter un retour',
    returns_reports: 'Rapports sur les retours',
    price_matches: 'Ajustements de prix',
    price_matches_list: 'Liste des ajustements de prix',
    price_match_form: "Formulaire d'ajustement de prix",
    tools: 'Outils',
    orders: 'Commandes',
    sku_price_history: 'Historique de prix SKU',
    altidollars_refund_campaign: 'Campagne de remboursement Alti Dollars',
    logout: 'Déconnexion',
    store_credits: 'Crédits expirables',
    bulk_store_credit_tool: 'Actions par lots',
    batch_store_credits_list: "Liste d'actions par lots",
    altidollars_fixer: "Correction d'Alti Dollars",
    altidollars_fixer_list: "Liste des corrections d'Alti Dollars"
  },
  objects_table: {
    ALS: 'Alti',
    TLH: 'TLH',
    returns_list_title: 'Liste des retours',
    price_matches_list_title: 'Liste des ajustements de prix',
    bulk_store_credits_list_title: "Liste d'actions par lots",
    list: '',
    edit: 'Éditer',
    status: 'Statut',
    order: 'Commande',
    tracking: 'Repérage',
    bundle: 'Paquet',
    created_at: 'Créé',
    received_at: 'Reçu',
    processed_at: 'Traité',
    total_rows: 'Emails',
    campaign: 'Campagne',
    completed: 'Complétés',
    started_at: 'Débuté',
    finished_at: 'Complété',
    expiry_date: 'Expiration',
    duplicates: 'Registre des duplicatas',
    amount: 'Montant',
    nb_chunks: 'Progression (%)',
    mode: 'Mode',
    sku_count: 'SKUs',
    refunded: 'Remboursés',
    restocked: 'Remis en stock',
    refund: 'Remboursement',
    restock: 'Remise en stock',
    failed: 'Échec',
    channel: 'Canal',
    more_info: "Plus d'info",
    select_filter: 'Sélectionner un filtre',
    open: 'Ouvert',
    processing: 'En traitement',
    processed: 'Traités',
    pending: 'En attente',
    add: 'Ajouter',
    remove: 'Retirer',
    exception: 'Exception',
    received: 'Reçu',
    closed: 'Complété',
    none: 'Aucun',
    partial: 'Partiel',
    full: 'Tous',
    no_sku: 'Pas de SKUs',
    is: 'Est',
    add_filter: 'Ajouter le filtre',
    add_a_filter: 'Ajouter un filtre',
    from: 'Du',
    to: 'Au',
    previous_page: 'Précédante',
    next_page: 'Suivante',
    search: 'Recherche',
    sort_by: 'Trier par',
    default: 'Défaut',
    created_at_desc: 'Date de création (desc)',
    created_at_asc: 'Date de création (asc)',
    completed_at_asc: 'Date de complétion (asc)',
    completed_at_desc: 'Date de complétion (desc)',
    received_at_desc: 'Date de réception (desc)',
    received_at_asc: 'Date de réception (asc)',
    processed_at_desc: 'Date de traitement (desc)',
    processed_at_asc: 'Date de traitement (asc)',
    expiry_date_asc: "Date d'Expiration (asc)",
    expiry_date_desc: "Date d'Expiration (desc)",
    amount_asc: 'Montant (asc)',
    amount_desc: 'Montant (desc)',
    total_rows_asc: 'Total de Lignes (asc)',
    total_rows_desc: 'Total de Lignes (desc)',
    no_receiving: 'Au traitement',
    refund_fails_pending: 'Échecs de remboursement en attente...',
    restock_fails_pending: 'Échecs de mise en inventaire en attente...',
    exceptions_pending: 'Exceptions non résolues en attente...',
    seller: 'Vendeur',
    completed_at: 'Complété',
    total_orders: 'Commandes totales',
    failed_count: 'Échouées',
    customer_email: 'Email client',
    customer_id: 'ID client',
    cx_agent: 'Agent CX',
    modified_lines: 'Modifiés',
    new_total_balance: 'Nouvelle balance',
    old_total_balance: 'Vieille balance',
    failed_returns_count_error: 'erreur(s) de retour',
    failed_returns_count_metrics_refresh: 'Cet indicateur se rafraîchit tous les 5 minutes.'
  },
  return_creation: {
    title: 'Formulaire de retour',
    create: 'Créer',
    void: 'Annuler',
    error_no_selected_item: 'Veuillez sélectionner au moins un article.',
    error_no_return_reason: 'Veuillez choisir une raison pour chaque retour.',
    error_special_characters: "Les caractères spéciaux ne sont pas admis dans la note de l'étiquette.",
    confirm: 'Confirmez',
    process_return: 'Compléter le retour?',
    input_label_note: "Entrer une note d'étiquette de retour",
    label_note_placeholder: "Note d'étiquette de retour (max 35 caractères)",
    input_reception_note: 'Entrer une note de réception',
    reception_note_placeholder: 'Note de réception',
    order: 'Commande',
    items: 'Article(s) retourn(é)s',
    return_reason: 'Raison du retour',
    remove: 'Enlever',
    please_choose: 'Veuillez choisir',
    cond_question_placeholder: 'Info additionnelle',
    submit: 'Soumettre',
    altidollars_refund_bypass:
      'Certains articles seront remboursés en Alti Dollars (prix de vente en-dessous du seuil). Cochez cette case pour un rembousement sur carte de crédit',
    altidollar_refund: 'Rembourser en Alti Dollars',
    altidollar_refund_campaign: 'Automatiquement coché: campagne de remboursement Alti Dollars',
    refund_exception: 'Ne pas rembourser',
    return_label_option: "Options d'étiquette de retour",
    digital_label: 'Nouvelle étiquette',
    label_in_box: 'Étiquette incluse',
    international: 'International',
    tlh: 'TLH étiquette du client',
    restocking_fee_exception: 'Exception du frais de retour',
    add_qr_code: 'Ajouter le code QR au courriel',
    return_to_als: 'Retourner chez Altitude Sports',
    final_sale: 'Article en vente finale (assurez-vous que ce soit une exception valide)'
  },
  return_expected_refund: {
    expected_refund: 'Remboursement attendu pour la commande',
    item: 'article',
    restocking_fee: "Frais d'expédition du retour",
    subtotal: 'Sous-total',
    tax: 'Taxe',
    expired_credits: 'Balance de crédits expirés',
    non_refundable: 'non remboursables',
    refunded_cash: 'Remboursé sur votre méthode de paiement',
    refunded_altidollars: 'Remboursé en Alti Dollars',
    refresh_order: 'Data requis non-disponible. Veuillez mettre à jour la commande dans Shopify.',
    manual_refund_balance: 'Remboursements précédents sur cet achat'
  },
  return_reception: {
    title: 'Recevoir un retour',
    submit: 'Soumettre',
    tracking_input_placeholder: 'Numéro de repérage',
    error_invalid_tracking_a: 'Le numéro de repérage',
    error_invalid_tracking_b:
      "n'est pas d'un service connu (Postes Canada ou Fedex). Voulez-vous quand même le traiter?",
    confirm: 'Confirmez',
    rnc: 'RNC: client abusif',
    success_creating: 'Retour créé et réceptionné avec succès',
    failed_creating: 'Échec de création et réception du retour',
    success_updating: 'Retour réceptionné avec succès',
    failed_updating: 'Échec de réception du retour',
    reception_note: 'Note de réception'
  },
  return_processing: {
    title: 'Traiter un retour',
    confirm: 'Confirmez',
    clear_form: 'Effacer le formulaire?',
    process_return: 'Compléter le traitement du retour?',
    error_no_items: 'Veuillez scanner au moins un article.',
    process_button: 'Traiter',
    void: 'Effacer',
    error_invalid_sku: 'Invalide - un SKU ou UPC devrait avoir au moins 11 chiffres.',
    loading: 'Chargement...',
    warranty: 'Garantie, veuillez procéder manuellement.',
    doa: 'DOA. Veuillez procéder manuellement.',
    looks_different_from_image: 'Différent de l\'image sur le site internet. Veuillez procéder manuellement.',
    received_wrong_item: 'Reçu le mauvais article. Veuillez procéder manuellement.',
    inaccurate_description: 'Mauvaise description de produit. Veuillez procéder manuellement.',
    poor_quality: 'Article de mauvaise qualité. Veuillez procéder manuellement.',
    input_sku_placeholder: 'Entrez UPC ou SKU',
    add: 'Ajouter',
    order: 'Commande',
    order_input_placeholder: '# de commande',
    no_refund: 'Ne pas rembourser',
    altidollars_refund: 'Note: cet article sera remboursé en Alti Dollars',
    validate: 'Valider',
    no_refund_tlh: 'Non remboursé (SKU TLH)',
    no_refund_mp: 'Non remboursé (SKU Marketplace)',
    no_refund_instalments: 'Remboursement manuel (Paybright/Sezzle)',
    remove: 'Enlever',
    restocking_fee_exception: 'Exception du frais de retour',
    rnc: 'RNC: client abusif',
    order_beyond_return_days_a: 'La commande',
    order_beyond_return_days_b: 'a été faite il y a',
    order_beyond_return_days_c: 'jours.',
    error_no_order_found: 'Commande introuvable',
    received_package_found: 'Paquet reçu trouvé. Veuillez entrer le numéro de commande.',
    error_tracking_not_found: 'Numéro de repérage introuvable. Veuillez entrer le numéro de commande et le canal.',
    error_please_enter_tracking: 'Veuillez entrer le numéro de repérage ou la référence de commande.',
    tracking_number: 'Numéro de repérage',
    search: 'Chercher',
    error_empty_input: 'Veuillez entrer un numéro de commande valide.',
    altidollar_refund: 'Rembourser en Alti Dollars',
    cx_agent_exception: "Ne pas décocher (exception d'agent CX)"
  },
  returns_reports: {
    title: 'Rapports sur les retours',
    daily_values: 'Données quotidiennes',
    historical_values: 'Données historique'
  },
  return_page: {
    title: 'Retour',
    order: 'Commande',
    status: 'Statut',
    bundle_id: 'ID du paquet',
    restocking_fee_exception: 'Exception du frais de retour',
    tracking: '# de repérage',
    label_creation_pending: 'En attente de Postes Canada...',
    label_creation_failed:
      "Échec de création de l'étiquette de retour. Veuillez le créer manuellement et entrer le numéro ici.",
    please_input_tracking: "Veuillez entrer le numéro de repérage de l'étiquette de retour (si disponible)...",
    carrier: 'Transporteur',
    deposco_id: 'ID Deposco',
    channel: 'Canal',
    customer_id: 'ID du client',
    customer_tags: 'Tags du client',
    created_at: 'Créé le',
    received_at: 'Reçu le',
    processed_at: 'Traité le',
    prepaid_label_note: "Note d'étiquette de retour",
    reception_note: 'Note de réception',
    returned_articles: 'Articles retournés',
    quantity_received: 'Quantité attendue',
    quantity_ordered: 'Quantité commandée',
    refund: 'Remboursé',
    refunded: 'Remboursé',
    refund_exception: 'Exception de remboursement',
    restock: 'Remettre en stock',
    restocked: 'Remis en stock',
    restock_exception: 'Exception de remise en stock',
    reason: 'Raison',
    add_item: 'Ajouter un article au retour',
    error_no_items: 'Veuillez choisir au moins un article à retourner.',
    error_no_reason: 'Veuillez choisir une raison pour chaque retour.',
    remove_button: "Enlever l'article",
    remove_one_qty: 'Enlever 1 quantité',
    remove_item: "Enlever l'article?",
    success_removing_item: 'Article enlevé avec succès.',
    fail_removing_item: "Échec de retrait d'article.",
    fail_updating_item: 'Échec de sauvegarde des changements.',
    error_sku_update_fail: 'Échec de mise à jour des SKUs',
    edit: 'Éditer',
    retryPrepaidLabel: 'Réessayer d\'obtenir l\'étiquette',
    save: 'Sauvegarder',
    cancel: 'Annuler',
    confirm: 'Confirmer',
    set_tracking: 'Nouveau numéro de repérage: ',
    set_reception_note: 'Nouvelle note de réception: ',
    email_status_sent: "Le courriel d'autorisation de retour a été envoyé au client.",
    email_status_not_sent: "Le courriel d'autorisation de retour n'a pas encore été envoyé au client...",
    open_return_label: "Ouvrir l'étiquette de retour",
    open_qr_code: 'Ouvrir le code QR',
    cant_open_pdf: "Vous n'arrivez toujours pas à ouvrir le PDF?",
    create_new_label: 'Créer une nouvelle étiquette de retour',
    success_creating_label: 'Nouvelle étiquette de retour créée avec succès.',
    failed_creating_label: 'Échec de création de la nouvelle étiquette de retour. - Veuillez contacter les TI.',
    new_label_pending:
      "Postes Canada est non disponible. L'étiquette de retour sera créée et envoyée au client d'ici 24h.",
    return_label_email_pending_sent: 'Un courriel a été envoyé pour informer le client du délai.',
    close_return: "Fermer le retour (résolution de retour à l'expéditeur ou autre)",
    success_closing_return: 'Retour fermé avec succès.',
    failed_closing_return: 'Échec de fermeture du retour.',
    close_return_confirm: 'Fermer le retour?',
    using_included_label: "Le client utilise l'étiquette de retour incluse. Ou sa propre solution.",
    exception_pending: 'Exception en attente...',
    invalid_return_id: 'Retour introuvable - ID de retour invalide',
    resolve_exception: 'Mettre à jour le statut de remboursement et/ou remise en stock',
    yes: 'oui',
    no: 'non',
    error_fetch_data: 'Erreur - échec de chargement des données - Veuillez contacter les TI',
    error_no_available_sku: 'Aucun SKU disponible pour la commande',
    select_skus: 'Sélectionnez le(s) SKU(s) à ajouter.',
    fail_handling_button: 'Échec de remboursement et/ou remise en stock en attente...',
    close_failures: 'Veuillez sélectionner les échecs réglés',
    submit: 'Soumettre',
    refund_fail: 'échec de remboursement',
    restock_fail: 'échec de remise en inventaire',
    no_receiving: 'Au traitement',
    altidollar_refund: 'Remboursement en Alti Dollars',
    set_fee_exception: 'Faire exception sur le frais de retour?',
    charge_restocking_fee: 'Charger le frais de retour?',
    update_qr_code: 'Créer nouveau code QR et étiquette de retour',
    seller: 'Vendu par',
    label_type: "Type d'étiquette de retour",
    destination_return_policy: 'Adresse de retour',
    'digital-label': 'Étiquette numérique',
    'label-in-box': 'Étiquette incluse',
    international: 'International',
    tlh: 'TLH étiquette du client'
  },
  items_selection: {
    input_order: 'Entrez # de commande',
    add: 'Ajouter',
    search: 'Chercher',
    order_number_placeholder: '# de commande',
    no_order_found: 'Commande introuvable',
    loading: 'Chargement...',
    select_returned_items: 'Sélectionner les articles à retourner:',
    select_pricematch_items: 'Sélectionner les articles à ajuster:',
    order: 'Commande',
    paid_price: 'Dernier prix payé',
    no_available_sku: 'Aucun SKU disponible'
  },
  reception_note: {
    title: 'Créer une note de réception',
    order_number_placeholder: 'Numéro de commande',
    tracking_number_placeholder: 'Numéro de repérage du retour',
    note_placeholder: 'Note de réception',
    error_missing_fields: 'Veuillez compléter tous les champs.',
    error_return_exists:
      'Un retour a déjà été créé pour ce numéro de repérage. Veuillez mettre à jour la note de réception',
    error_return_exists_link: 'via la page du retour',
    success: 'Retour et note de réception créés avec succès.',
    fail: 'Échec de création du retour et de la note de réception.',
    error_order_not_found: 'Commande non trouvée.',
    submit: 'Soumettre'
  },
  price_match_page: {
    title: 'Ajustement de prix',
    order: 'Commande',
    channel: 'Canal',
    status: 'Statut',
    created_at: 'Créé le',
    credit_card_refund: 'Remboursé sur carte de crédit',
    discount_code: 'Coupon rabais',
    customer_id: 'ID du client',
    customer_tags: 'Tags du client',
    agent: 'Agent',
    note: 'Note',
    price_matched_articles: 'Articles aux prix ajustés',
    original_paid_price: 'Prix payé initialement',
    current_paid_price: 'Dernier prix payé',
    requested_price: 'Nouveau prix demandé',
    refunded_amount: 'Montant remboursé par qté',
    quantity_ordered: 'Quantité commandée',
    refund_status: 'Statut du remboursement',
    refunded_at: 'Remboursé le',
    line_item_id: "ID de l'article",
    processed: 'traité',
    pending: 'non-traité',
    yes: 'Oui',
    no: 'Non',
    no_code: 'Aucun coupon',
    open: 'Ouvert',
    processing: 'En traitement',
    closed: 'Fermé',
    price_match_type: 'Type',
    internal: "à l'interne",
    external: 'compétiteur',
    active_coupon: 'Coupon actif?'
  },
  price_match_form: {
    title: "Formulaire d'ajustement de prix",
    price_matched_articles: 'Articles aux prix ajustés',
    qty: 'Qté',
    available_prices: "Prix disponibles de l'article:",
    last_paid_price: 'Dernier prix payé (incluant tous les rabais):',
    refunded_amount: 'Montant remboursé',
    new_paid_price: 'Nouveau prix payé',
    no_historical_prices: 'Aucun prix historique disponible.',
    in_house: "AjDePrix à l'interne",
    external: 'AjDePrix Compétiteur',
    external_note: "Entrez l'URL de l'article ou le nom et # de téléphone du compétiteur",
    starting_price: "Nouveau prix de l'article",
    details: 'Détails',
    total: 'Prix réduit total',
    membership_status: 'Statut de membre',
    member: 'Membre',
    non_member: 'Non-membre',
    member_status_note: 'Note: statut membre actuel',
    discount_code: 'Coupon rabais',
    discount_name_placeholder: 'Entrez le titre du coupon',
    discount_nb_placeholder: 'Montant en pourcentage',
    apply_coupon: 'Appliquez le coupon rabais',
    note: 'Laisser une note',
    note_placeholder: 'Laissez une note (ticket Zendesk, etc.)',
    credit_card_refund: 'Rembourser sur la carte de crédit',
    order_not_shipped:
      "Remboursement sur la carte de crédit (la commande n'est pas encore envoyée ou aucun crédits magasin)",
    void: 'Effacer',
    submit: 'Soumettre',
    error_no_items: 'Veuillez sélectionner au moins un article.',
    error_invalid_price: 'Veuillez entrer un prix valide pour chaque article..',
    error_missing_competitor_info:
      'Veuillez entrer les informations du compétiteur pour chaque ajustement de prix externe.',
    error_missing_coupon_info: "Veuillez entrer l'information complète du coupon rabais (titre et pourcentage).",
    error_apply_coupon: 'Veuillez applique le coupon rabais sur au moins un article ou enlevez-le.',
    error_price_too_low: 'Certains des ajustements de prix sont trop bas. Veuillez vérifier avec un superviseur.',
    error_missing_note: 'Veuillez laisser une note. (Numéro du ticket Zendesk et autre).',
    error_negative_amount: 'Un montant remboursé ne peut être négatif ou égal à zéro.',
    confirm: 'Confirmez',
    submit_price_match: "Soumettre l'ajustement de prix?",
    success: 'Ajustement de prix créé avec succès.',
    fail: "Échec de création de l'ajustement de prix.",
    excluded_vendor: 'Marque exclue',
    member_5: 'Rabais membre 5%',
    member_10: 'Rabais membre 10%',
    member_15: 'Rabais membre 15%',
    passed_price_matches: 'Ajustements de prix passés:',
    no_past_pm: 'Aucun ajustement de prix passé.',
    expected_refund: 'Remboursement attendu (avant taxes):'
  },
  sku_price_history: {
    title: "Historique de prix d'un SKU",
    sku_input_placeholder: 'Entre le SKU',
    submit: 'Chercher',
    no_history_found: 'Aucun prix historique trouvé.'
  },
  altidollars_refund_campaign: {
    title: 'Campagne de remboursement Alti Dollars',
    current_campaign: 'Campagne de remboursement Alti Dollars courante',
    deactivate: 'Désactiver la campagne',
    threshold: 'Seuil du pourcentage rabais',
    starting: 'Débute le',
    ending: 'Se termine le',
    no_campaign: 'Pas de campagne active',
    agent: 'Réglée par',
    set_new_campaign: 'Modifier la campagne',
    enter_threshold: 'Entrer le seuil du % rabais',
    threshold_note:
      'Note: si le prix payé par le client (avant rabais membre et coupon) était égal ou suppérieur au seuil, le remboursement sera fait en Alti Dollars',
    select_dates: 'Sélectionner la plage de dates',
    dates_range_note: 'Note: laisser la date de fin vide pour une campagne permanente.',
    submit: 'Soumettre',
    always: 'toujours active',
    from: 'Du',
    to: 'Au',
    error_date: 'Une date de début est requise.'
  },
  store_credits: {
    title: 'Actions par lots',
    choose_file_to_upload: 'Choisissez le fichier à télécharger (CSV)',
    card_header: 'Ajouter / Retirer des crédits expirables',
    add: 'Ajouter',
    remove: 'Retirer',
    reset_form: 'Réinitialiser',
    all_fields_required: 'Veuillez vérifier si tous les champs ont été remplis',
    something_went_wrong: 'Un problème est survenu. Veuillez réessayer',
    wrong_file_format: 'Veuillez télécharger un fichier .CSV valide',
    expiry_date: "Date d'expiration",
    credit_value: 'Montant du crédit',
    invalid_email_csv_data: 'Veuillez vérifier la validité des données du CSV à la ligne: ',
    remove_duplicates_only: 'Effacer uniquement les crédits donnés en double',
    negative_value_not_allowed: 'Le montant du crédit doit être un nombre entier positif.',
    too_much_credit_value: 'Le montant du crédit est trop élevé',
    confirm_value_to_be_added: 'Le montant semble élevé, merci de vérifier que le montant voulu est bien de : $',
    date_already_passed: "La date d'expiration est déjà passée. Veuillez choisir une date future.",
    post_success: 'Votre campagne sera traitée sous peu',
    post_error: "Erreur d'envoi de l'action par lots. Veuillez contacter les TI",
    csv_info: ' Téléchargez un fichier CSV avec la liste des courriels ciblés. Aucune entête est nécessaire.',
    confirm: 'Confirmez',
    confirm_text: 'Veuillez confirmer pour envoyer la requête.',
    campaign_name: 'Nom de la campagne (25 caractères max)'
  },
  altidollars_fixer: {
    title: "Correction de balance d'Alti Dollars",
    subtitle: "Régler la balance d'Alti Dollars du client",
    customer_email: 'Email du client',
    submit: 'Soumettre'
  },
  months: [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Decembre'
  ],
  weekdays_long: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
  weekdays_short: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa']
};

export default fr;
