import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import useGlobal from '../store';
import ObjectsTable from '../components/ObjectsTable';
import AltidollarsFixerTableHeader from '../components/ObjectsTable/AltidollarsFixer/AltidollarsFixerTableHeader';
import AltidollarsFixerTableItem from '../components/ObjectsTable/AltidollarsFixer/AltidollarsFixerTableItem';

const AltidollarsFixerList = () => {
  let match = useRouteMatch();
  const [globalState] = useGlobal();
  const { text } = globalState;

  const sortOptions = [
    {
      value: 'created_at.1',
      text: text.objects_table.created_at_asc
    },
    {
      value: 'created_at.-1',
      text: text.objects_table.created_at_desc,
      selected: "selected"
    }
  ];

  return (
    <>
      <main className="container">
        <section className="section">
          <Switch>
            <Route path={`${match.path}`}>
              <ObjectsTable
                section="altidollars-fixer-list"
                ListHeader={AltidollarsFixerTableHeader}
                ListItem={AltidollarsFixerTableItem}
                sortOptions={sortOptions}
                title={text.altidollars_fixer.title}
              />
            </Route>
          </Switch>
        </section>
      </main>
    </>
  );
};

export default AltidollarsFixerList;
