import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import useGlobal from '../store';
import ObjectsTable from '../components/ObjectsTable';
import PriceMatchPage from '../components/PriceMatchPage';
import PriceMatchesTableItem from "../components/ObjectsTable/PriceMatches/PriceMatchesTableItem";
import PriceMatchesTableHeader from "../components/ObjectsTable/PriceMatches/PriceMatchesTableHeader";

const Returns = () => {
  let match = useRouteMatch();

  const [globalState] = useGlobal();
  const { text } = globalState;

  const sortOptions = [
    {
      value: 'created_at.1',
      text: text.objects_table.created_at_asc
    },
    {
      value: 'created_at.-1',
      text: text.objects_table.created_at_desc,
      selected: "selected"
    }
  ]

  return (
    <>
      <main className="container">
        <section className="section">
          <Switch>
            <Route path={`${match.path}/:priceMatchID`}>
              <PriceMatchPage />
            </Route>

            <Route path={`${match.path}`}>
              <ObjectsTable section="price-matches" ListHeader={PriceMatchesTableHeader}
                ListItem={PriceMatchesTableItem} sortOptions={sortOptions} title={text.objects_table.price_matches_list_title} />
            </Route>
          </Switch>
        </section>
      </main>
    </>
  );
};

export default Returns;
