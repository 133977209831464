import React, { useState } from 'react';
import useGlobal from '../../../store';

const AltidollarsFixerTableHeader = () => {
  const [globalState] = useGlobal();
  const { text } = globalState;

  const [headerFields] = useState({
    created_at: '',
    customer_email: '',
    customer_id: '',
    modified_lines: '',
    new_total_balance: 'desktop',
    old_total_balance: 'desktop',
    cx_agent: 'desktop'
  });

  const headList = Object.keys(headerFields).map(field => {
    return (
      <div
        className={
          headerFields[field] === 'desktop' ? 'objects-table__item__cell desktop' : 'objects-table__item__cell'
        }
        key={field}
      >
        {text.objects_table[field]}
      </div>
    );
  });

  return <li className="altidollars-fixer-list__item has-text-weight-bold">{headList}</li>;
};

export default AltidollarsFixerTableHeader;
