export const formatArticleTitle = (lang, item) => {
  return `${item.vendor} - ${lang === 'fr' ? item.title.split('|-|')[1] : item.title.split('|-|')[0]} - ${
    item.variant_title ? item.variant_title.replace('Altitude Sports', '') : ''
  }`;
};

export const formatDate = dateString => {
  return new Date(dateString).toLocaleString('en-CA', {
    timeZone: 'America/New_York'
  });
};

export const isFinalSale = (productTags, mode) => {
    if (productTags === '') {
        return false;
    }
    else {
        const finalSaleTags = [
            'Climbing Harnesses',
            'Quickdraws',
            'Carabiners',
            'Child Trailers for Bikes',
            'Pulleys',
            'Slings & Webbing',
            'Belay Devices',
            'Climbing Crash Pads',
            'Ascenders',
            'Fixed Protection',
            'Climbing Ropes',
            'Avalanche Packs & Airbags',
            'Food Beverages & Energy',
            'Face Masks & Balaclavas',
            'Child Carriers',
            'Child Trailers for Ski',
            'Child Bike Seat',
            'City Strollers',
            'Running Strollers',
            'Panties',
            'Boxers',
            'Briefs'
        ];
        return productTags.some(tag => finalSaleTags.includes(tag))
    }
};
