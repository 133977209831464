import { setCookie } from '../../utils/cookies';
import setLangText from '../../utils/lang';

// SCAN ONLY FUNCTIONS:

export const setScanMode = (store, mode) => {
  store.setState({ mode: mode });
};

// GENERAL FUNCTIONS

export const toggleLang = store => {
  const newLang = store.state.lang === 'en' ? 'fr' : 'en';
  store.setState({ lang: newLang, text: setLangText(newLang) });
  setCookie('cerberus_lang', newLang, 180);
};

export const clearProcessStates = store => {
  store.setState({
    requestStatus: 'INITIAL',
    returns: [],
    tracking: null,
    destinationReturnPolicy: {},
    carrier: null,
    orders: [],
    availableItems: [],
    selectedItems: [],
    restockingFeeException: false,
    prepaidLabelNote: '',
    receptionNote: '',
    returnLabelType: 'digital-label',
    addQRcodeToEmail: false,
    sellers: [],
    selectedSeller: null,
    als_warehouse_return: false,
    newReturnType: 'standard',
    membership: null,
    discountCode: {
      title: '',
      value: '',
      value_type: 'percentage'
    },
    priceMatchNote: '',
    creditCardException: false,
    notShippedYet: false,
    priceMatches: [],
    dialog: {
      open: false,
      message: '',
      action: null
    },
    message: {
      open: false,
      message: ''
    },
    listData: null,
    listType: null,
    listTags: null,
    bypassedAltidollarsRefundCampaign: false,
    altidollarsRefundsHistory: [],
    selectedOpenedReturn: null,
  });
};

export const updateStateProperty = (store, key, value) => {
  store.setState({
    [key]: value
  });
};

export const parseCarrierTrackingBarcode = (store, barcode) => {
  let tracking;

  if (barcode.length >= 16 && barcode.includes('7283733')) {
    tracking = {
      carrier: 'cp',
      number: '7283733' + barcode.split('7283733')[1].substring(9, 0)
    };
  } else if (barcode.length === 34) {
    tracking = {
      carrier: 'fedex',
      number: barcode.substr(barcode.length - 14)
    };
  } else {
    tracking = { carrier: null, number: barcode };
  }

  return tracking;
};

// RETURNS LIST

export const filtersStateToQueryString = (store, filtersState) => {
  return filtersState
    .map(filter => {
      if (filter.value === 'null') {
        return '';
      } else if (filter.name === 'limit' || filter.name === 'page') {
        return `${filter.name}=${filter.value}`;
      } else if (filter.name.includes('sort')) {
        return `${filter.name}=${filter.value}`;
      } else if (filter.type === 'value') {
        return `${filter.name}.${filter.value}=1`;
      } else if (filter.type === 'range') {
        const start = filter.value.split('from ')[1].split(' to')[0];
        const end = filter.value.split('from ')[1].split('to ')[1];
        return `${filter.name}.start=${start}&${filter.name}.end=${end}`;
      } else if (filter.type === 'search') {
        return `${filter.name}=${filter.value}`;
      } else {
        return '';
      }
    })
    .filter(el => el !== '')
    .join('&');
};

// RETURN OBJECTS FUNCTIONS

export const calculateRestockingFee = (store, orderAndSelectedItems, plusTaxes) => {
  const { orders, returns, selectedItems, channels } = store.state;
  let channelSrc = orderAndSelectedItems ? orderAndSelectedItems.channel : orders[0].channel;
  const freeReturnBrands = channels[channelSrc].return_policy.return_shipping_fee.free_return_brands;
  const noFreeReturnBrandsDate = Date.parse('2022-06-01T00:00:00.000Z');

  let selectedItemsOrders = selectedItems.map(item =>
    orders.find(order => order.order_number.toString() === item.order_name)
  );

  // At least 1 of the returned item is a free return brand and belongs to an order created before noFreeReturnBrandsDate
  const hasFreeReturnBrand =
    selectedItems.some(item =>
      freeReturnBrands.indexOf(item.vendor) > -1
      && orders.some(order => order.order_number.toString() === item.order_name && Date.parse(order.created_at) < noFreeReturnBrandsDate)
    );

  selectedItemsOrders = [...new Set(selectedItemsOrders)];

  const wasNotShippedInCanada = selectedItemsOrders.some(
    order => order.line_items[0].destination_location && order.line_items[0].destination_location.country_code !== 'CA'
  );

  const isMember = selectedItemsOrders.some(
    order => order.customer && order.customer.tags && order.customer.tags.indexOf('group_member') > -1
  );

  const ordersNames = selectedItems.map(item => item.order_name);

  const isFirstReturn = !ordersNames.every(orderName => {
    return returns.some(
      returnObj =>
        returnObj.order_name === orderName
        && (returnObj.status === 'closed' || returnObj.status === 'processing')
    );
  });
  const isNoRestockingFeeChannel = selectedItemsOrders.some(order => channels[order.channel].return_policy.no_restocking_fee);

  const isException = store.state.restockingFeeException ? store.state.restockingFeeException : false;

  const isMarketplace = selectedItems.every(item => item.sku.indexOf('MK') > -1);
  const nbOfOrders = selectedItemsOrders.filter(
    order =>
      !channels[order.channel].return_policy.no_restocking_fee &&
      selectedItems
        .filter(item => item.order_name === order.order_number.toString())
        .some(item => !item.refund_exception)
  ).length;

  // ** Periodic Free Return Promo ** //
  const altiPromoDates = [
    // Oct 13-15 Free return Promo
    {
      start: Date.parse('2020-10-12T22:00:00-04:00'),
      end: Date.parse('2020-10-15T04:00:00-04:00'),
    },
    // Dec 7-10 Free return Promo
    {
      start: Date.parse('2020-12-07T08:00:00-04:00'),
      end: Date.parse('2020-12-11T04:00:00-04:00'),
    },
    // Apr 1-19 Free return Promo
    {
      start: Date.parse('2021-03-31T22:00:00-04:00'),
      end: Date.parse('2021-04-20T04:00:00-04:00'),
    },
  ];
  const isFreeReturnPromotionDated =
    isFirstReturn &&
    orders.some((order) => {
      const orderDate = Date.parse(order.created_at);
      return altiPromoDates.some(({ start, end }) => orderDate >= start && orderDate <= end);
    });

  // ** Promo Code Ends ** //
  const endFreeMarketplaceReturnsAfter = new Date('2022-06-01T00:00:00.000Z').getTime();
  const hasFreeMarketplaceReturn = selectedItemsOrders.some(
    (order) => Date.parse(order.created_at) < endFreeMarketplaceReturnsAfter
  );

  // Check conditions in which we DO NOT charge the Restocking Fee
  if (
    isException ||
    wasNotShippedInCanada ||
    isNoRestockingFeeChannel
  ) {
    return 0;

  } else if (
    isFirstReturn &&
    (
      isMember ||
      isFreeReturnPromotionDated ||
      (isMarketplace && hasFreeMarketplaceReturn) ||
      hasFreeReturnBrand
    )
  ) {
    return 0;
  }

  // If reached here, we are charging a restock fee on the order
  const restockingFee = Date.parse(selectedItemsOrders[0].created_at) > Date.parse(process.env.REACT_APP_NEW_RESTOCKING_FEE_DATE) ? process.env.REACT_APP_NEW_RESTOCKING_FEE : process.env.REACT_APP_RESTOCKING_FEE;

  if (plusTaxes) {
    const orderObject = orders.find(order => orderAndSelectedItems.order === order.order_number.toString());
    const taxRate = orderObject.line_items[0].tax_lines.map(el => parseFloat(el.rate)).reduce((a, b) => a + b, 0);

    return Math.round((restockingFee / nbOfOrders) * (1 + taxRate) * 100) / 100;
  }
  return restockingFee / nbOfOrders;
};

const isTransactionInstalment = ({ gateway }) => [
  'paybright',
  'sezzle',
  'sezzle_sandbox',
].includes(gateway);

export const isTransactionInstalmentSuccess = (transaction) => {
  return isTransactionInstalment(transaction) &&
      transaction.status === 'success';
};

export const isTransactionMoneySuccess = (transaction) => {
  return transaction.kind !== 'refund' &&
      (transaction.kind === 'capture' || isTransactionInstalment(transaction)) &&
      transaction.status === 'success'
};
