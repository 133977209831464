import React from 'react';
import useGlobal from '../../store';

const ObjectsTablePagination = ({ objectsMeta, section }) => {
  const [globalState, globalActions] = useGlobal();
  const { text } = globalState;
  const currentPage = objectsMeta.currentPage;

  function changePage(page) {
    globalActions.objectsList.updateObjects([{ name: 'page', value: parseInt(page), type: 'value' }], section);
  }

  return (
    <div className="section is-centered">
      <nav className="pagination is-rounded is-centered" role="navigation" aria-label="pagination">
        {currentPage > 1 && (
          <button onClick={() => changePage(currentPage - 1)} className="pagination-previous">
            {text.objects_table.previous_page}
          </button>
        )}
        {(
          <button onClick={() => changePage(currentPage + 1)} className="pagination-next">
            {text.objects_table.next_page}
          </button>
        )}
        <ul className="pagination-list">
          {currentPage > 1 && (
            <li>
              <button onClick={() => changePage(1)} className="pagination-link">
                1
              </button>
            </li>
          )}
          {currentPage > 3 && (
            <li>
              <span className="pagination-ellipsis">&hellip;</span>
            </li>
          )}
          {currentPage > 2 && (
            <li>
              <button onClick={() => changePage(currentPage - 1)} className="pagination-link is-hidden-mobile">
                {currentPage - 1}
              </button>
            </li>
          )}
          <li>
            <button className="pagination-link is-current">{currentPage}</button>
          </li>
          {(
            <li>
              <button onClick={() => changePage(currentPage + 1)} className="pagination-link is-hidden-mobile">
                {currentPage + 1}
              </button>
            </li>
          )}
          {(
            <li>
              <span className="pagination-ellipsis">&hellip;</span>
            </li>
          )}
        </ul>
      </nav>
    </div>
  );
};

export default ObjectsTablePagination;
