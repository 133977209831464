import React, { useState } from 'react';
import useGlobal from '../../store';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';

const ObjectsTableFilters = ({ section }) => {
  const [globalState, globalActions] = useGlobal();
  const { lang, text, channels } = globalState;
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const filterSelect = React.createRef();
  const dayPickerProps = {
    locale: lang,
    months: text.months,
    weekdaysLong: text.weekdays_long,
    weekdaysShort: text.weekdays_short
  };

  let fields = {};
  if (section === 'price-matches') {
    fields = {
      status: (
        <div className="select">
          <select name="filterValue">
            <option value="processing">{text.objects_table.processing}</option>
            <option value="closed">{text.objects_table.closed}</option>
          </select>
        </div>
      ),
      created_at: (
        <>
          <div className="select datePickerFirst">
            <DayPickerInput
              placeholder={text.objects_table.from}
              onDayChange={day => setStartDate(day)}
              dayPickerProps={dayPickerProps}
            />
          </div>
          <div className="select">
            <DayPickerInput
              placeholder={text.objects_table.to}
              onDayChange={day => setEndDate(day)}
              dayPickerProps={dayPickerProps}
            />
          </div>
        </>
      ),
      refunded: (
        <div className="select">
          <select name="filterValue">
            <option value="none">{text.objects_table.none}</option>
            <option value="partial">{text.objects_table.partial}</option>
            <option value="full">{text.objects_table.full}</option>
          </select>
        </div>
      ),
      channel: (
        <div className="select">
          <select name="filterValue">
            {Object.keys(channels).map(name => (
              <option key={channels[name].channel_id} value={name}>
                {name}
              </option>
            ))}
          </select>
        </div>
      )
    };
  } else if (section === 'returns') {
    fields = {
      status: (
        <div className="select">
          <select name="filterValue">
            <option value="open">{text.objects_table.open}</option>
            <option value="exception">{text.objects_table.exception}</option>
            <option value="received">{text.objects_table.received}</option>
            <option value="processing">{text.objects_table.processing}</option>
            <option value="closed">{text.objects_table.closed}</option>
          </select>
        </div>
      ),
      created_at: (
        <>
          <div className="select datePickerFirst">
            <DayPickerInput
              placeholder={text.objects_table.from}
              onDayChange={day => setStartDate(day)}
              dayPickerProps={dayPickerProps}
            />
          </div>
          <div className="select">
            <DayPickerInput
              placeholder={text.objects_table.to}
              onDayChange={day => setEndDate(day)}
              dayPickerProps={dayPickerProps}
            />
          </div>
        </>
      ),
      received_at: (
        <>
          <div className="select datePickerFirst">
            <DayPickerInput
              placeholder={text.objects_table.from}
              onDayChange={day => setStartDate(day)}
              dayPickerProps={dayPickerProps}
            />
          </div>
          <div className="select">
            <DayPickerInput
              placeholder={text.objects_table.to}
              onDayChange={day => setEndDate(day)}
              dayPickerProps={dayPickerProps}
            />
          </div>
        </>
      ),
      processed_at: (
        <>
          <div className="select datePickerFirst">
            <DayPickerInput
              placeholder={text.objects_table.from}
              onDayChange={day => setStartDate(day)}
              dayPickerProps={dayPickerProps}
            />
          </div>
          <div className="select">
            <DayPickerInput
              placeholder={text.objects_table.to}
              onDayChange={day => setEndDate(day)}
              dayPickerProps={dayPickerProps}
            />
          </div>
        </>
      ),
      refunded: (
        <div className="select">
          <select name="filterValue">
            <option value="none">{text.objects_table.none}</option>
            <option value="partial">{text.objects_table.partial}</option>
            <option value="full">{text.objects_table.full}</option>
            <option value="failed">{text.objects_table.failed}</option>
          </select>
        </div>
      ),
      restocked: (
        <div className="select">
          <select name="filterValue">
            <option value="none">{text.objects_table.none}</option>
            <option value="partial">{text.objects_table.partial}</option>
            <option value="full">{text.objects_table.full}</option>
            <option value="failed">{text.objects_table.failed}</option>
          </select>
        </div>
      ),
      channel: (
        <div className="select">
          <select name="filterValue">
            {Object.keys(channels).map(name => (
              <option key={channels[name].channel_id} value={name}>
                {name}
              </option>
            ))}
          </select>
        </div>
      )
    };
  } else if (section === 'store-credits-bulk-actions-list') {
    fields = {
      status: (
        <div className="select">
          <select name="filterValue">
            <option value="processing">{text.objects_table.processing}</option>
            <option value="processed">{text.objects_table.processed}</option>
          </select>
        </div>
      ),
      created_at: (
        <>
          <div className="select datePickerFirst">
            <DayPickerInput
              placeholder={text.objects_table.from}
              onDayChange={day => setStartDate(day)}
              dayPickerProps={dayPickerProps}
            />
          </div>
          <div className="select">
            <DayPickerInput
              placeholder={text.objects_table.to}
              onDayChange={day => setEndDate(day)}
              dayPickerProps={dayPickerProps}
            />
          </div>
        </>
      ),
      expiry_date: (
        <>
          <div className="select datePickerFirst">
            <DayPickerInput
              placeholder={text.objects_table.from}
              onDayChange={day => setStartDate(day)}
              dayPickerProps={dayPickerProps}
            />
          </div>
          <div className="select">
            <DayPickerInput
              placeholder={text.objects_table.to}
              onDayChange={day => setEndDate(day)}
              dayPickerProps={dayPickerProps}
            />
          </div>
        </>
      ),
      mode: (
        <div className="select">
          <select name="filterValue">
            <option value="add">{text.objects_table.add}</option>
            <option value="remove">{text.objects_table.remove}</option>
          </select>
        </div>
      )
    };
  }

  const [fieldOptions, setFieldOptions] = useState(fields.status);

  function handleSubmitFilter(e) {
    e.preventDefault();
    const field = e.target.filterField.value;

    if (startDate && endDate) {
      globalActions.objectsList.updateObjects(
        [
          {
            name: field,
            value: `from ${startDate.toISOString().split('T')[0]} to ${endDate.toISOString().split('T')[0]}`,
            type: 'range'
          }
        ],
        section
      );
    } else if (e.target.filterValue) {
      const value = e.target.filterValue.value;
      globalActions.objectsList.updateObjects([{ name: field, value, type: 'value' }], section);
    }

    setStartDate(null);
    setEndDate(null);
    filterSelect.current.value = 'status';
    setFieldOptions(fields.status);
  }

  return (
    <div className="objects-table__filters">
      <div className="box">
        <label className="label">{text.objects_table.select_filter}</label>
        <form onSubmit={handleSubmitFilter}>
          <div className="field has-addons has-addons-centered">
            <div className="control">
              <div className="select">
                <select ref={filterSelect} name="filterField" onChange={e => setFieldOptions(fields[e.target.value])}>
                  {Object.keys(fields).map(field => (
                    <option key={field} value={field}>
                      {text.objects_table[field]}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="control">
              <div className="select">
                <select name="filterComparator">
                  <option value="is">{text.objects_table.is}</option>
                  {/* Futur sprint: <option value="is_not">Is not</option> */}
                </select>
              </div>
            </div>
          </div>
          <div className="field">{fieldOptions}</div>
          <div className="field">
            <button type="submit" className="button is-dark">
              {text.objects_table.add_filter}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ObjectsTableFilters;
