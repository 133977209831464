import React, { useEffect, useState } from 'react';
import useGlobal from '../store';
import ProcessingSearchForm from '../components/ProcessingForm/ProcessingSearchForm';
import ProcessingItems from '../components/ProcessingForm/ProcessingItems';
import ProcessingRestockingFees from '../components/ProcessingForm/ProcessingRestockingFees';
import ProcessingButtons from '../components/ProcessingForm/ProcessingButtons';
import Dialogs from '../components/UI/Dialogs';

const Processing = () => {
  const [globalState, globalActions] = useGlobal();
  const { text /* , currentCampaign */ } = globalState;
  const [currentProcessingChannel, setCurrentProcessingChannel] = useState(null);

  useEffect(() => {
    globalActions.utils.clearProcessStates();
  }, [globalActions.utils]);

  function handleCurrentChannel(val) {
    setCurrentProcessingChannel(val);
  }

  return (
    <>
      <main className="container">
        <section className="section">
          <h1 className="title is-4">{text.return_processing.title}</h1>
          <ProcessingSearchForm handleCurrentChannel={handleCurrentChannel} />
          <ProcessingItems currentProcessingChannel={currentProcessingChannel} />
          <ProcessingRestockingFees />
          <ProcessingButtons />
          <Dialogs />
        </section>
      </main>
    </>
  );
};

export default Processing;
