import React, { useState, useEffect } from 'react';
import useGlobal from '../../store';
import ObjectsTableFilters from './ObjectsTableFilters';
import ObjectsTableFailsWarning from './ObjectsTableFailsWarning';

const ObjectsTableTopbar = ({ section, sortOptions }) => {
  const [globalState, globalActions] = useGlobal();
  const { text, objectsQueryFilters } = globalState;
  const [filtersOpened, setFiltersOpened] = useState(false);
  const [searchKeyword, updateSearchKeyword] = useState('');
  const sortSelect = React.createRef();
  const [currentSearch, setCurrentSearch] = useState(null);

  useEffect(() => {
    const sort = objectsQueryFilters.find(el => el.name.includes('sort'));
    if (sort && sort.value !== 'null') {
      sortSelect.current.value = `${sort.name.replace('sort.', '')}.${sort.value}`;
    }

    const search = objectsQueryFilters.find(el => el.type === 'search');
    search && setCurrentSearch(search);
  }, [objectsQueryFilters, sortSelect]);

  function formatTagValue(filterValue) {
    if (filterValue.includes('from')) {
      return filterValue
        .replace('from', text.objects_table.from.toLocaleLowerCase())
        .replace('to', text.objects_table.to.toLocaleLowerCase());
    } else {
      return text.objects_table[filterValue].toLocaleLowerCase();
    }
  }

  const filtersTags = objectsQueryFilters.map((filter, index) => {
    if (filter.name !== 'page' && filter.name !== 'q' && !filter.name.includes('sort')) {
      return (
        <span key={index} className="filters-tags tag">
          {`${text.objects_table[filter.name]}: ${formatTagValue(filter.value)}`}
          <span onClick={() => globalActions.objectsList.updateObjects([{ ...filter, delete: true }], section)}>
            <i className="fas fa-times-circle" />
          </span>
        </span>
      );
    } else {
      return null;
    }
  });


  function handleSortChange(e) {
    e.preventDefault();
    const value = e.target.value === 'null' ? null : e.target.value;
    if (value) {
      globalActions.objectsList.updateObjects(
        [
          {
            name: `sort.${value.split('.')[0]}`,
            value: value.split('.')[1],
            type: 'value'
          }
        ],
        section
      );
    } else {
      globalActions.objectsList.updateObjects(
        [
          {
            name: 'sort',
            value: 'null',
            type: 'value'
          }
        ],
        section
      );
    }
  }

  function handleSubmitSearch(e) {
    e.preventDefault();

    if (searchKeyword.length > 0) {
      globalActions.objectsList.updateObjects(
        [
          {
            name: 'q',
            value: searchKeyword,
            type: 'search'
          }
        ],
        section
      );
    } else {
      globalActions.objectsList.updateObjects([{ ...currentSearch, delete: true }], section);
    }
  }

  function handleAddFilter(e) {
    e.preventDefault();
    setFiltersOpened(true);
  }

  return (
    <div className="objects-table__topbar">
      <div className="level objects-table__topbar__top">
        <form onSubmit={handleSubmitSearch} className="field has-addons has-addons-centered is-marginless">
          <div className="control">
            <input
              onChange={e => updateSearchKeyword(e.target.value)}
              className="input is-rounded"
              placeholder={text.objects_table.search}
              required
            />
          </div>
          <div className="control">
            <button type="submit" className="button">
              <span className="icon">
                <i className="fas fa-search"></i>
              </span>
            </button>
          </div>

          <div className="control">
            <button onClick={handleAddFilter} className="button">
              {text.objects_table.add_a_filter}
            </button>
          </div>
        </form>

        <div className="objects-table__sort-by is-flex centered">
          <label>{text.objects_table.sort_by}:&nbsp;</label>
          <div className="select is-rounded">
            <select onChange={handleSortChange} ref={sortSelect}>
              {sortOptions.map(option => (
                <option key={option.value} selected={option?.selected} value={option.value}>
                  {option.text}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className="tags custom are-medium">
        {currentSearch && (
          <span className="filters-tags tag">
            {text.objects_table.search}: {currentSearch.value}
            <span
              onClick={() => globalActions.objectsList.updateObjects([{ ...currentSearch, delete: true }], section)}
            >
              <i className="fas fa-times-circle" />
            </span>
          </span>
        )}

        {filtersTags}
      </div>

      <div className={`modal ${filtersOpened ? 'is-active' : ''}`}>
        <div onClick={() => setFiltersOpened(false)} className="modal-background"></div>
        <div className="modal-content">
          <ObjectsTableFilters section={section} />
        </div>
        <button onClick={() => setFiltersOpened(false)} className="modal-close is-large" aria-label="close"></button>
      </div>

      {section === 'returns' && <ObjectsTableFailsWarning />}
    </div>
  );
};

export default ObjectsTableTopbar;
