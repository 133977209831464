import React, { useEffect } from 'react';
import useGlobal from '../store';
import ReturnForm from '../components/ReturnForm';

const Creating = () => {
  const [globalState, globalActions] = useGlobal();
  const { text /* , currentCampaign */ } = globalState;

  useEffect(() => {
    globalActions.utils.clearProcessStates();
  }, [globalActions.utils]);

  return (
    <>
      <main className="container">
        <section className="section">
          <h1 className="title is-4 has-text-left">{text.return_creation.title}</h1>

          <ReturnForm />
        </section>
      </main>
    </>
  );
};

export default Creating;
