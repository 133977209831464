import React, { useState } from 'react';
import useGlobal from '../../../store';

const BulkStoreCreditsTableHeader = () => {
  const [globalState] = useGlobal();
  const { text } = globalState;

  const [headerFields] = useState({
    campaign: 'desktop',
    amount: '',
    expiry_date: '',
    mode: '',
    total_rows: 'desktop', // Emails
    completed: 'desktop',
    status: '',
    created_at: 'desktop',
    started_at: 'desktop',
    finished_at: 'desktop',
    nb_chunks: 'desktop',  // Percent bar
  });

  const headList = Object.keys(headerFields).map(field => {
    return (
      <div
        className={
          headerFields[field] === 'desktop' ? 'objects-table__item__cell desktop' : 'objects-table__item__cell'
        }
        key={field}
      >
        {text.objects_table[field]}
      </div>
    );
  });

  return <li className="bulk-credits-table__item has-text-weight-bold">{headList}</li>;
};

export default BulkStoreCreditsTableHeader;
