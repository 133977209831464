import React from 'react'
import { Switch, Route, useRouteMatch} from "react-router-dom";
import useGlobal from "../store";

const Moderation = () => {
    let match = useRouteMatch();
    const [globalState] = useGlobal();
    const { text } = globalState;


    return (
        <>
            <main className="container">
                <section className="section">
                    <Switch>
                        <Route path={`${match.path}/:reviewID`}>
                        </Route>

                        <Route path={`${match.path}`}>
                            {/*<ObjectsTable*/}
                            {/*    section="reviews"*/}
                            {/*    ListHeader={}*/}
                            {/*    ListItem={}*/}
                            {/*    sortOptions={sortOptions}*/}
                            {/*    title={text.objects_table}*/}
                            {/*/>*/}
                        </Route>
                    </Switch>
                </section>
            </main>
        </>
    )
}

export default Moderation
