import React, { useState, useEffect } from 'react';
import useGlobal from '../../store';
import { formatArticleTitle } from '../../utils/formatters';
import ProductImg from '../UI/ProductImg';

const PriceMatchSkusItem = ({ item }) => {
  const [globalState, globalActions] = useGlobal();
  const { lang, text, priceMatches, membership, orders, selectedItems, discountCode, channels } = globalState;
  const [lineItemCurrentPrice, setLineItemCurrentPrice] = useState(null);
  const [startingPrice, setStartingPrice] = useState(null);
  const [priceDetails, setPriceDetails] = useState('');
  const [requestedTotal, setRequestedTotal] = useState(' - ');

  const productId = orders[0].line_items.find(lineItem => lineItem.sku === item.sku).product_id;

  useEffect(() => {
    if (priceMatches.length > 0 && globalActions.priceMatch.getLineItemCurrentPrice(item, priceMatches)) {
      setLineItemCurrentPrice(globalActions.priceMatch.getLineItemCurrentPrice(item, priceMatches));
    } else {
      setLineItemCurrentPrice(item.paid_price);
    }

    const originalPriceWithoutDiscounts = orders[0].line_items.find(lineItem => lineItem.sku === item.sku).price;

    setStartingPrice(originalPriceWithoutDiscounts ? Number(originalPriceWithoutDiscounts) : Number(item.paid_price));
  }, [item, priceMatches, globalActions, orders]);

  useEffect(() => {
    if (membership && orders.length > 0) {
      const vendorsAt10Percent = ['Deuter', 'Icebreaker'];
      const vendor = orders[0].line_items.find(el => el.sku === item.sku).vendor;

      if (vendorsAt10Percent.includes(vendor)) {
        globalActions.items.updateItem(item, 'member_type', '10% member discount');
      } else {
        globalActions.items.updateItem(item, 'member_type', '5% member discount');
      }
    } else if (!membership && orders.length > 0) {
      globalActions.items.updateItem(item, 'member_type', 'none');
    }
  }, [item, membership, orders, globalActions]);

  useEffect(() => {
    const itemState = selectedItems.find(el => el.sku === item.sku);
    let percentagesArr = [];
    let amountDiscount;
    let total = startingPrice || 0;
    let details = `$${startingPrice}`;

    function totalTimesPercentages(total, percentagesArr) {
      let updatedTotal = total;
      percentagesArr.sort().forEach(percent => {
        updatedTotal *= percent;
      });
      return updatedTotal;
    }

    if (itemState.discount) {
      if (discountCode.value_type === 'percentage') {
        percentagesArr.push((100 - discountCode.value) / 100);
      } else if (discountCode.value_type === 'amount') {
        amountDiscount = discountCode.value;
      }
    }

    if (itemState.member_type !== 'none') {
      if (itemState.member_type === '5% member discount') {
        percentagesArr.push(0.95);
      } else if (itemState.member_type === '10% member discount') {
        percentagesArr.push(0.9);
      } else if (itemState.member_type === '15% member discount') {
        percentagesArr.push(0.85);
      }
    }

    if (amountDiscount && percentagesArr.length > 0) {
      const amountPercent = (item.paid_price - amountDiscount) / item.paid_price;
      const memberBetterThanAmount = percentagesArr.some(percent => percent < amountPercent);

      if (memberBetterThanAmount) {
        total = totalTimesPercentages(total, percentagesArr) - amountDiscount;
        details += ` x ${percentagesArr.join(' x ')} - $${amountDiscount}`;
      } else {
        total = totalTimesPercentages(total - amountDiscount, percentagesArr);
        details = `(${details} - $${amountDiscount}) x ${percentagesArr.join(' x')}`;
      }
    } else if (!amountDiscount && percentagesArr.length > 0) {
      total = totalTimesPercentages(total, percentagesArr);
      details += ` x ${percentagesArr.join(' x')}`;
    } else if (amountDiscount) {
      total -= amountDiscount;
      details += ` - $${amountDiscount}`;
    }

    setPriceDetails(details);
    setRequestedTotal(total.toFixed(2));
  }, [globalState, startingPrice, discountCode, item, selectedItems]);

  useEffect(() => {
    globalActions.items.updateItem(item, 'requested_price', parseFloat(requestedTotal));
  }, [item, requestedTotal, globalActions.items]);

  function toggleDiscountCode(e) {
    e.preventDefault();
    const discountState = selectedItems.find(el => el.sku === item.sku).discount;
    globalActions.items.updateItem(item, 'discount', !discountState);
  }

  function cleanedHistory(priceHistory) {
    const mappedHistory = priceHistory.map(history => {
      return {
        id: history._id.$oid,
        price: history.price.$numberDouble,
        date: new Date(parseInt(history.price_date.$date.$numberLong)).toLocaleDateString(),
        is_recent_price: history.is_recent_price
      };
    });

    return mappedHistory.reduce((acc, current) => {
      const x = acc.find(item => item.date === current.date && item.price === current.price);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
  }

  return (
    <li className="price-match-form__sku message has-text-left">
      <div className="message-header">
        <ProductImg item={item} size="45px" margin="0 0.5em 0 0" />
        <p>
          <a
            href={`${channels[orders[0].channel].shop_url}/products/${productId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {`${formatArticleTitle(lang, item)} - ${item.sku} - ${text.price_match_form.qty}: ${item.quantity_ordered}`}
          </a>
        </p>
        <button
          onClick={() => globalActions.items.removeSelectedItem(item)}
          className="delete"
          aria-label="delete"
        ></button>
      </div>

      <div className="message-body price-match-form__sku__body">
        <label className="label">{`${text.price_match_form.last_paid_price} $${lineItemCurrentPrice}`}</label>

        <div className="price-match-form__sku__body-top">
          <div className="price-match-form__sku__available-prices">
            <label className="label">{text.price_match_form.available_prices}</label>
            {item.price_history && item.price_history.length > 0 && (
              <ul className="is-flex wrap price-match-form__price-tags">
                {cleanedHistory(item.price_history).map(history => (
                  <li key={history.id} className="price-match-form__price-tag">
                    <span
                      onClick={() => setStartingPrice(parseFloat(history.price))}
                      className={`tag is-medium price-match-form__price-tag__span ${history.is_recent_price !== undefined && history.is_recent_price === false
                        ? 'is-orange'
                        : 'is-primary'
                        }`}
                    >
                      ${parseFloat(history.price)} <span className="is-size-7">&nbsp; {history.date}</span>
                    </span>
                  </li>
                ))}
              </ul>
            )}

            {item.price_history.length === 0 && <p>{text.price_match_form.no_historical_prices}</p>}
          </div>

          <div className="price-match-form__sku__options">
            <div className="field">
              <div className="control">
                <div className="select">
                  <select
                    onChange={e => globalActions.items.updateItem(item, 'price_match_type', e.target.value)}
                    value={selectedItems.find(el => el.sku === item.sku).price_match_type}
                  >
                    <option value="internal">{text.price_match_form.in_house}</option>
                    <option value="external">{text.price_match_form.external}</option>
                  </select>
                </div>
              </div>
            </div>

            {membership && (
              <div className="field">
                <div className="control">
                  <div className="select">
                    <select
                      onChange={e => globalActions.items.updateItem(item, 'member_type', e.target.value)}
                      value={selectedItems.find(el => el.sku === item.sku).member_type}
                    >
                      <option value="none">{text.price_match_form.excluded_vendor}</option>
                      <option value="5% member discount">{text.price_match_form.member_5}</option>
                      <option value="10% member discount">{text.price_match_form.member_10}</option>
                      <option value="15% member discount">{text.price_match_form.member_15}</option>
                    </select>
                  </div>
                </div>
              </div>
            )}

            {(discountCode.title.length > 0 || discountCode.value.length > 0) && (
              <div className="field">
                <div className="control discount-code is-flex">
                  <label className="checkbox-container" onClick={e => toggleDiscountCode(e)}>
                    <input
                      name="apply-discount-code"
                      type="checkbox"
                      readOnly
                      checked={selectedItems.find(el => el.sku === item.sku).discount}
                    />
                    <span className="checkmark" />
                    {text.price_match_form.apply_coupon}
                  </label>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="price-match-form__sku__body-bottom">
          <div className="field price-match-form__sku__price-input">
            <label className="label">{text.price_match_form.starting_price}:</label>
            <p className="control has-icons-left">
              <input
                onChange={e => setStartingPrice(parseFloat(e.target.value))}
                value={(startingPrice && startingPrice) || false}
                className="input"
                type="number"
                min="0.00"
                step="0.01"
              />
              <span className="icon is-small is-left">
                <i className="fas fa-dollar-sign"></i>
              </span>
            </p>
          </div>

          <div className="field price-match-form__sku__price-breakdown">
            <label className="label">{text.price_match_form.details}:</label>
            <p className="price-match-form__sku__details has-text-grey">{priceDetails}</p>
          </div>

          <div className="field price-match-form__sku__price-total">
            <label className="label">{text.price_match_form.total}:</label>
            <p className="is-size-4 has-text-primary has-text-weight-bold">${requestedTotal}</p>
          </div>
        </div>

        {item.price_match_type === 'external' && (
          <div className="field">
            <label className="label">{text.price_match_form.external_note}</label>
            <div className="control">
              <input
                onInput={e => globalActions.items.updateItem(item, 'competitor_url', e.target.value)}
                className="input"
                type="text"
                placeholder="Competitor's info"
              />
            </div>
          </div>
        )}
      </div>
    </li>
  );
};

export default PriceMatchSkusItem;
