import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import useGlobal from '../store';
import ObjectsTable from "../components/ObjectsTable";
import ReturnPage from "../components/ReturnPage";
import ReturnsTableItem from "../components/ObjectsTable/Returns/ReturnsTableItem";
import ReturnsTableHeader from "../components/ObjectsTable/Returns/ReturnsTableHeader";
import FailedReturnsCount from "../components/ObjectsTable/Returns/FailedReturnsCount";

const Returns = () => {
  let match = useRouteMatch();
  const [globalState] = useGlobal();
  const { text } = globalState;

  const sortOptions = [
    {
      value: 'created_at.1',
      text: text.objects_table.created_at_asc
    },
    {
      value: 'created_at.-1',
      text: text.objects_table.created_at_desc,
      selected: "selected"
    },
    {
      value: 'received_at.1',
      text: text.objects_table.received_at_asc
    },
    {
      value: 'received_at.-1',
      text: text.objects_table.received_at_desc
    },
    {
      value: 'processed_at.1',
      text: text.objects_table.processed_at_asc
    },
    {
      value: 'processed_at.-1',
      text: text.objects_table.processed_at_desc
    }
  ];

  return (
    <>
      <main className="container">
        <section className="section">
          <Switch>
            <Route path={`${match.path}/:returnID`}>
              <ReturnPage />
            </Route>

            <Route path={`${match.path}`}>
              <FailedReturnsCount />
              <ObjectsTable
                section="returns"
                ListHeader={ReturnsTableHeader}
                ListItem={ReturnsTableItem}
                sortOptions={sortOptions}
                title={text.objects_table.returns_list_title}
              />
            </Route>
          </Switch>
        </section>
      </main>
    </>
  );
};

export default Returns;
