import { v4 as uuid } from 'uuid';

export const setTracking = (store, tracking) => {
  store.setState({ tracking });
};

export const processReturnObjects = async (store, mode) => {
  store.setState({ requestStatus: 'LOADING', processing: true });

  let itemsSortedByOrders = selectedItemsPerOrder(store);
  setExpectedRefunds(store, itemsSortedByOrders);
  itemsSortedByOrders = flattenedSameSkus(itemsSortedByOrders);
  itemsSortedByOrders = formatFields(itemsSortedByOrders);

  const bundleId = uuid();
  const ordersStringForLabel = itemsSortedByOrders.map(el => el.channel[0] + el.order).join('-');
  const bundleCount = itemsSortedByOrders.length;

  await Promise.all(
    itemsSortedByOrders.map(async orderAndSelectedItems => {
      const existingReturnObjectFromForm = store.state.returns.find(
        returnObj =>
          (returnObj.status === 'open' || returnObj.status === 'received') &&
          returnObj.order_name === orderAndSelectedItems.order &&
          returnObj.channel === orderAndSelectedItems.channel
      );

      // Prepare and send the return update
      if (existingReturnObjectFromForm && mode === 'updating') {
        const orderObj = store.state.orders.find(
          order =>
            order.order_number.toString() === orderAndSelectedItems.order &&
            order.channel === orderAndSelectedItems.channel
        );

        const update = updateFieldsForAddItemsToReturnFormObj(
          store,
          orderObj,
          orderAndSelectedItems,
          bundleId,
          bundleCount,
          existingReturnObjectFromForm
        );

        const result = await store.actions.db.updateReturnObj(update, store.state.selectedOpenedReturn._id);
        return result;
      }

      if (existingReturnObjectFromForm && mode === 'processing') {
        const orderObj = store.state.orders.find(
          order =>
            order.order_number.toString() === orderAndSelectedItems.order &&
            order.channel === orderAndSelectedItems.channel
        );

        const update = updateFieldsForReturnFormObj(
          store,
          orderObj,
          orderAndSelectedItems,
          bundleId,
          bundleCount,
          existingReturnObjectFromForm
        );

        const result = store.actions.db.updateReturnObj(update, existingReturnObjectFromForm._id);
        return result;
      } else {
        let existingReturnObjectFromReceiving;

        if (mode === 'processing' && store.state.tracking && store.state.tracking.tracking_number.length > 0) {
          existingReturnObjectFromReceiving = await store.actions.db.getReturns({
            key: 'tracking',
            value: store.state.tracking.tracking_number
          });
        }

        if (
          existingReturnObjectFromReceiving &&
          existingReturnObjectFromReceiving.length > 0 &&
          existingReturnObjectFromReceiving[0].items.length === 0
        ) {
          const returnObj = existingReturnObjectFromReceiving[0];
          const orderObj = store.state.orders.find(
            order =>
              order.order_number.toString() === orderAndSelectedItems.order &&
              order.channel === orderAndSelectedItems.channel
          );
          const update = updateFieldsForReceptionCreatedObj(
            store,
            returnObj,
            orderObj,
            orderAndSelectedItems,
            bundleId,
            bundleCount
          );

          const result = await store.actions.db.updateReturnObj(update, returnObj._id);
          return result;
        } else {
          const newReturnObject = createReturnObj(store, orderAndSelectedItems);

          let formattedNewReturnObj;
          if (mode === 'processing') {
            formattedNewReturnObj = formatNewReturnObjForProcessing(
              store,
              newReturnObject,
              orderAndSelectedItems,
              bundleId,
              bundleCount
            );
          } else if (mode === 'creating') {
            formattedNewReturnObj = formatNewReturnObjForCreating(newReturnObject, bundleId, bundleCount);
          }

          const result = await store.actions.db.postReturnObj(formattedNewReturnObj);
          return result;
        }
      }
    })
  ).then(async results => {
    if (results && results.length > 0 && results.every(el => el.status === 'SUCCESS')) {
      if (mode === 'creating' && store.state.returnLabelType === 'digital-label') {
        await store.actions.db.prepaidLabelCP(bundleId, ordersStringForLabel);
      } else if (
        mode === 'creating' &&
        store.state.returnLabelType !== 'digital-label' &&
        store.state.returnLabelType !== 'tlh'
      ) {
        await store.actions.db.sendReturnAuthorizationEmail(bundleId);
      }

      store.actions.utils.clearProcessStates();
      store.actions.notification.showMessage(store.state.text.store.return_post_success, 'success');

      if (mode === 'creating' || mode === 'updating') {
        setTimeout(() => {
          window.location = '/returns/' + results[0].newReturnObjId;
        }, 1500);
      }

      setTimeout(() => {
        store.actions.notification.hideMessage();
      }, 3000);
    } else {
      store.actions.utils.clearProcessStates();
      store.actions.notification.showMessage(store.state.text.store.error_return_post_fail, 'error');
    }

    store.setState({ processing: false });
  });
};

export const createReceptionNoteReturnObject = async store => {
  const newReturnObject = createReturnObj(store, null, 'receptionNote');
  const bundleId = uuid();
  const formattedNewReturnObj = formatNewReturnObjForCreating(newReturnObject, bundleId, 1);

  const response = await store.actions.db.postReturnObj(formattedNewReturnObj);
  return response;
};

export const receiveReturn = async (store, trackingData) => {
  const returnObj = createReturnObj(store);

  returnObj.status = 'received';
  returnObj.bundle_id = null;
  returnObj.bundle_count = null;

  if (trackingData) {
    returnObj.order = `${trackingData.order_name}--${trackingData.shopify_order_id}`;
    returnObj.order_name = trackingData.order_name;
    returnObj.channel = trackingData.channel;
    returnObj.tracking_number = trackingData.tracking_number;
  }

  const res = await store.actions.db.postReturnObj(returnObj);
  return res;
};

export const processAddedSkus = async (store, returnObjId) => {
  store.setState({ returnFetchStatus: 'LOADING' });
  let itemsSortedByOrders = selectedItemsPerOrder(store);
  itemsSortedByOrders = flattenedSameSkus(itemsSortedByOrders);
  itemsSortedByOrders = formatFields(itemsSortedByOrders);

  const returnObj = store.state.returns.find(el => el._id === returnObjId);
  const update = {
    items: returnObj.items.concat(itemsSortedByOrders[0].items)
  };
  const res = await store.actions.db.updateReturnObj(update, returnObj._id);

  if (res && res.status === 'SUCCESS') {
    store.actions.utils.clearProcessStates();
    return 'SUCCESS';
  } else {
    return 'FAIL';
  }
};

export const toggleRestockingFeeException = store => {
  store.setState({
    restockingFeeException: !store.state.restockingFeeException
  });
};

function selectedItemsPerOrder(store) {
  const ordersData = store.state.orders.map(order => {
    return {
      orderNb: order.order_number.toString(),
      channel: order.channel
    };
  });

  const selectedItemsPerOrder = ordersData
    .map(orderData => {
      return {
        order: orderData.orderNb,
        channel: orderData.channel,
        items: store.state.selectedItems.filter(
          item => item.order_name === orderData.orderNb && item.channel === orderData.channel
        )
      };
    })
    .filter(el => el.items.length > 0);

  //store.setState({ selectedItems: [] });
  return selectedItemsPerOrder;
}

function flattenedSameSkus(items) {
  const flattenedSameSkus = items.map(el => {
    const mergedItems = [];
    el.items.forEach(item => {
      const index = mergedItems.findIndex(mergedItem => mergedItem.sku === item.sku);
      if (index > -1) {
        mergedItems[index].quantity_received++;
      } else {
        item.quantity_received = 1;
        mergedItems.push(item);
      }
    });

    return { ...el, items: mergedItems };
  });

  return flattenedSameSkus;
}

function formatFields(itemsSortedByOrders) {
  const updatedItemsPerOrder = [...itemsSortedByOrders];

  updatedItemsPerOrder.forEach((orderAndSelectedItems, i) => {
    const updatedItems = orderAndSelectedItems.items.map(el => {
      const obj = { ...el };
      delete obj.channel;
      delete obj.order_name;
      delete obj.uniq_id;
      delete obj.validated;

      return {
        ...obj,
        refund_status: null,
        refunded_at: null,
        restock_status: null,
        restocked_at: null
      };
    });
    updatedItemsPerOrder[i].items = updatedItems;
  });

  return updatedItemsPerOrder;
}

function setPriority(store, orderObj, orderAndSelectedItems) {
  if (orderObj.customer && orderObj.customer.tags && orderObj.customer.tags.includes('group_member')) {
    return 1;
  } else if (
    orderAndSelectedItems.items.find(el => el.reason) &&
    orderAndSelectedItems.items.find(el => el.reason._id !== null)
  ) {
    return 2;
  } else if (store.state.newReturnType === 'warranty') {
    return 3;
  } else if (store.state.newReturnType === 'doa') {
    return 4;
  } else {
    return 0;
  }
}

function createReturnObj(store, orderAndSelectedItems, receptionNote) {
  const fromReceiving = !orderAndSelectedItems && !receptionNote;

  let orderObj;

  if (fromReceiving) {
    orderObj = null;
  } else if (receptionNote) {
    orderObj = store.state.orders[0];
    orderAndSelectedItems = {
      order: orderObj.order_number.toString(),
      items: []
    };
  } else {
    orderObj = store.state.orders.find(
      order =>
        order.order_number.toString() === orderAndSelectedItems.order && order.channel === orderAndSelectedItems.channel
    );
  }

  let customerTags;
  if (fromReceiving) {
    customerTags = null;
  } else {
    customerTags = orderObj.customer && orderObj.customer.tags ? orderObj.customer.tags : '';
  }

  const expectedRefund = store.state.expectedRefunds.find(el => el.order === orderAndSelectedItems.order);

  const returnObject = {
    bypass_altidollars_refund_campaign: store.state.bypassedAltidollarsRefundCampaign,
    bundle_tracking_numbers: [],
    carrier: store.state.carrier ? store.state.carrier : null,
    channel: fromReceiving ? null : orderObj.channel,
    created_at: null,
    created_from: 'back-office',
    customer_id: fromReceiving ? null : orderObj.customer_id.toString(),
    customer_tags: customerTags,
    deposco_id: fromReceiving ? null : 'CR--' + orderObj._id,
    expected_refund: expectedRefund ? expectedRefund.data : null,
    items: fromReceiving || receptionNote ? [] : orderAndSelectedItems.items,
    order: fromReceiving ? null : orderObj._id,
    order_name: fromReceiving ? null : orderAndSelectedItems.order,
    priority: fromReceiving ? null : setPriority(store, orderObj, orderAndSelectedItems),
    processed_at: null,
    prepaid_label_note: fromReceiving ? null : store.state.prepaidLabelNote,
    reception_note: fromReceiving ? null : store.state.receptionNote,
    restocking_fee: store.state.restockingFeeException ? 0 : null,
    restocking_fee_exception: store.state.restockingFeeException,
    received_at: null,
    return_label_type: store.state.returnLabelType,
    seller: setSeller(store, orderAndSelectedItems),
    als_warehouse_return: store.state.als_warehouse_return,
    skip_new_prepaid_label: store.state.returnLabelType !== 'digital-label',
    tax_lines: fromReceiving
      ? null
      : orderObj.line_items[0].tax_lines.map(el => {
          return { rate: el.rate, title: el.title };
        }),
    tracking_number: `${
      store.state.tracking && store.state.tracking.tracking_number ? store.state.tracking.tracking_number : ''
    }`
  };

  if (store.state.addQRcodeToEmail) {
    returnObject.qr_code_send_to_client = true;
  }

  if (orderObj && orderObj.tags && orderObj.tags.includes('reusable_package')) {
    returnObject.reusable_package = true;
  }

  return returnObject;
}

function setSeller(store, orderAndSelectedItems) {
  if (store.state.selectedSeller) {
    return store.state.selectedSeller;
  } else if (store.state.orders.length > 0) {
    return store.state.channels[store.state.orders[0].channel].channel_name_en;
  } else {
    return null;
  }
}

function formatNewReturnObjForProcessing(store, newReturnObject, orderAndSelectedItems, bundleId, bundleCount) {
  newReturnObject.bundle_id = bundleId;
  newReturnObject.bundle_count = bundleCount;
  newReturnObject.status = 'processing';
  newReturnObject.restocking_fee_exception = store.state.restockingFeeException;
  newReturnObject.restocking_fee = store.actions.utils.calculateRestockingFee(orderAndSelectedItems, 'plus-taxes');

  return newReturnObject;
}

function formatNewReturnObjForCreating(newReturnObject, bundleId, bundleCount) {
  newReturnObject.bundle_id = bundleId;
  newReturnObject.bundle_count = bundleCount;
  newReturnObject.status = 'open';
  return newReturnObject;
}

function updateFieldsForReturnFormObj(
  store,
  orderObj,
  orderAndSelectedItems,
  bundleId,
  bundleCount,
  existingReturnObjectFromForm
) {
  const expectedRefund = store.state.expectedRefunds.find(el => el.order === orderAndSelectedItems.order);

  const update = {
    bundle_id: bundleId,
    bundle_count: bundleCount,
    customer_id: orderObj.customer_id.toString(),
    customer_tags: orderObj.customer && orderObj.customer.tags ? orderObj.customer.tags : '',
    deposco_id: 'CR--' + orderObj._id,
    expected_refund: expectedRefund ? expectedRefund.data : null,
    items: orderAndSelectedItems.items,
    restocking_fee_exception: store.state.restockingFeeException,
    restocking_fee: store.actions.utils.calculateRestockingFee(orderAndSelectedItems, 'plus-taxes'),
    status: 'processing',
    tax_lines: orderObj.line_items[0].tax_lines.map(el => {
      return { rate: el.rate, title: el.title };
    })
  };

  if (existingReturnObjectFromForm) {
    update.bundle_tracking_numbers = [];
    update.tracking_number = store.state.tracking.tracking_number;
  }

  return update;
}

function updateFieldsForAddItemsToReturnFormObj(
  store,
  orderObj,
  orderAndSelectedItems,
  bundleId,
  bundleCount,
  existingReturnObjectFromForm
) {
  const expectedRefund = store.state.expectedRefunds.find(el => el.order === orderAndSelectedItems.order);

  const update = {
    bundle_id: bundleId,
    bundle_count: bundleCount,
    customer_id: orderObj.customer_id.toString(),
    customer_tags: orderObj.customer && orderObj.customer.tags ? orderObj.customer.tags : '',
    deposco_id: 'CR--' + orderObj._id,
    expected_refund: expectedRefund ? expectedRefund.data : null,
    items: orderAndSelectedItems.items,
    restocking_fee_exception: store.state.restockingFeeException,
    restocking_fee: store.actions.utils.calculateRestockingFee(orderAndSelectedItems, 'plus-taxes'),
    status: 'open',
    tax_lines: orderObj.line_items[0].tax_lines.map(el => {
      return { rate: el.rate, title: el.title };
    })
  };

  if (existingReturnObjectFromForm) {
    update.bundle_tracking_numbers = [];
    update.tracking_number = store.state.tracking.tracking_number;
  }

  return update;
}

function updateFieldsForReceptionCreatedObj(store, returnObj, orderObj, orderAndSelectedItems, bundleId, bundleCount) {
  const expectedRefund = store.state.expectedRefunds.find(el => el.order === orderAndSelectedItems.order);

  return {
    bundle_count: bundleCount,
    bundle_id: bundleId,
    carrier: returnObj.carrier ? returnObj.carrier : null,
    channel: orderObj.channel,
    customer_id: orderObj.customer_id.toString(),
    customer_tags: orderObj.customer && orderObj.customer.tags ? orderObj.customer.tags : '',
    deposco_id: 'CR--' + orderObj._id,
    expected_refund: expectedRefund ? expectedRefund.data : null,
    items: orderAndSelectedItems.items,
    order: orderObj._id,
    order_name: orderAndSelectedItems.order,
    prepaid_label_note: store.state.prepaidLabelNote,
    priority: setPriority(store, orderObj, orderAndSelectedItems),
    reception_note: store.state.receptionNote,
    restocking_fee: store.actions.utils.calculateRestockingFee(orderAndSelectedItems, 'plus-taxes'),
    restocking_fee_exception: store.state.restockingFeeException,
    skip_new_prepaid_label: store.state.returnLabelType !== 'digital-label',
    status: 'processing',
    tax_lines: orderObj.line_items[0].tax_lines.map(el => {
      return { rate: el.rate, title: el.title };
    }),
    tracking_number: `${
      store.state.tracking && store.state.tracking.tracking_number ? store.state.tracking.tracking_number : ''
    }`
  };
}

function setExpectedRefunds(store, itemsSortedByOrders) {
  itemsSortedByOrders.forEach(el => {
    const restockingFee = store.actions.utils.calculateRestockingFee(el);
    const refundedItems = el.items.filter(item => item.refund_exception === false);
    const orderObj = store.state.orders.find(
      order => order.order_number.toString() === el.order && order.channel === el.channel
    );

    if (orderObj.transactions && orderObj.total_price_set) {
      store.actions.expectedRefund.getExpectedRefund(restockingFee, refundedItems, store.state.priceMatches, orderObj);
    }
  });
}
