import React from 'react';
import useGlobal from '../../store';
import ProductImg from '../UI/ProductImg';

const PriceMatch = () => {
  const [globalState] = useGlobal();
  const { text, priceMatches } = globalState;

  const sortedPM = priceMatches.sort((a, b) =>
    a.created_at < b.created_at ? 1 : b.created_at < a.created_at ? -1 : 0
  );

  return (
    <div className="price-match-form__past">
      <h2 className="label price-match-form__past__title has-text-left">
        {text.price_match_form.passed_price_matches}
      </h2>

      {sortedPM.map(pm => {
        return (
          <div key={pm._id} className="box has-text-left">
            <h3 className="label">
              <a href={`/price-matches/${pm._id}`} target="_blank" rel="noopener noreferrer">
                {pm.created_at.split('T')[0]}{' '}
                <span className="icon price-match-form__past__icon">
                  <i className="fas fa-external-link-alt"></i>
                </span>
              </a>
            </h3>

            <div>
              {pm.items.map(item => {
                return (
                  <li key={item.sku} className="price-match-form__past__item is-flex">
                    <ProductImg item={item} size="50px" margin="0.25em 0.5em 0.25em 0" />
                    <div>
                      <p>
                        <b>{item.sku}</b>
                      </p>
                      <ul className="price-match-form__past__item-info">
                        <li>
                          {text.price_match_form.last_paid_price} ${item.line_item_current_price},
                        </li>
                        <li>
                          {text.price_match_form.refunded_amount}: $
                          {Number(item.refunded_amount) / item.quantity_ordered},
                        </li>
                        <li>
                          {text.price_match_form.new_paid_price}: ${item.requested_price}
                        </li>
                      </ul>
                    </div>
                  </li>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PriceMatch;
