module.exports.Channels = {
  ALS: {
    channel_id: 1,
    channel_name_en: 'Altitude Sports',
    channel_name_fr: 'Altitude Sports',
    return_policy: {
      no_restocking_fee: false,
      return_shipping_fee: {
        charged: true,
        free_return_brands: ['Canada Goose', "Arc'teryx", 'Vallier', 'Veilance', 'Nobis']
      },
      eligible_days: 100
    },
    price_match_policy: {
      allowed: true,
      always_money: false
    },
    shop_url: process.env.REACT_APP_ALTI_ADMIN,
    store_credits_policy: {
      name: 'Alti Dollars'
    },
    membership_policy: {
      has_membership: true
    }
  },
  TLH: {
    channel_id: 2,
    channel_name_en: 'The Last Hunt',
    channel_name_fr: 'La Dernière Chasse',
    return_policy: {
      no_restocking_fee: true,
      return_shipping_fee: {
        charged: false,
        free_return_brands: []
      },
      eligible_days: 1000
    },
    price_match_policy: {
      allowed: true,
      always_money: true
    },
    shop_url: process.env.REACT_APP_TLH_ADMIN,
    store_credits_policy: null,
    membership_policy: {
      has_membership: false
    }
  }
};
