import React from 'react';
import { formatDate } from '../../../utils/formatters';

const AltidollarsFixerTableItem = ({ tableItemObj }) => {
  return (
    <li className="altidollars-fixer-list__item">
      <div className="objects-table__item__cell">{formatDate(tableItemObj.created_at).split(',')[0]}</div>

      <div className="objects-table__item__cell">{tableItemObj.customer_email}</div>

      <div className="objects-table__item__cell">{tableItemObj.customer_id}</div>

      <div className="objects-table__item__cell">{tableItemObj.modified_lines}</div>

      <div className="objects-table__item__cell desktop">
        {tableItemObj.new_total_balance || tableItemObj.new_total_balance === 0
          ? tableItemObj.new_total_balance.toFixed(2)
          : '-'}
        $
      </div>

      <div className="objects-table__item__cell desktop">
        {tableItemObj.old_total_balance || tableItemObj.old_total_balance === 0
          ? tableItemObj.old_total_balance.toFixed(2)
          : '-'}
        $
      </div>

      <div className="objects-table__item__cell desktop">{tableItemObj.cx_agent}</div>
    </li>
  );
};

export default AltidollarsFixerTableItem;
