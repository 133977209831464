import React, { useState, useEffect } from 'react';
import useGlobal from '../../store';

const RestockingFees = () => {
  const [globalState, globalActions] = useGlobal();
  const { text, restockingFeeException, selectedItems, returns } = globalState;
  const [isCXAgentException, setIsCXAgentException] = useState(false);

  function toggleRestockingException(event) {
    event.preventDefault();
    globalActions.processing.toggleRestockingFeeException();
  }

  useEffect(() => {
    setIsCXAgentException(false);
    if (selectedItems.length > 0) {
      const exceptionReturns = returns.filter(
        returnObj =>
          returnObj.restocking_fee_exception && (returnObj.status === 'open' || returnObj.status === 'received')
      );

      const exception = exceptionReturns.some(returnObj =>
        selectedItems.some(item => item.order_name === returnObj.order_name)
      );

      if (exception) {
        globalActions.utils.updateStateProperty('restockingFeeException', true);
        setIsCXAgentException(true);
      }
    }
  }, [selectedItems, returns, globalActions.processing, globalActions.utils]);

  return (
    <div className="restocking-fee__container">
      <label className="checkbox-container" onClick={event => toggleRestockingException(event)}>
        <input name="restocking-fee" type="checkbox" checked={restockingFeeException} readOnly />
        <span className="checkmark" />
        {text.return_processing.restocking_fee_exception}{' '}
        {isCXAgentException && (
          <span className="is-size-7 has-text-weight-bold"> ({text.return_processing.cx_agent_exception})</span>
        )}
      </label>
    </div>
  );
};

export default RestockingFees;
