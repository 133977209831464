import React, { useState } from 'react';
import useGlobal from '../../store';

const ReturnPageManualResolution = ({ type, display, close, returnObj }) => {
  const [globalState, globalActions] = useGlobal();
  const { text } = globalState;
  const [refundedSkus, setRefundedSkus] = useState([]);
  const [restockedSkus, setRestockedSkus] = useState([]);

  function toggleRefundedSku(sku) {
    const addedIndex = refundedSkus.findIndex(el => el === sku);

    if (addedIndex > -1) {
      const updatedSkus = [...refundedSkus];
      updatedSkus.splice(addedIndex, 1);
      setRefundedSkus(updatedSkus);
    } else {
      const updatedSkus = [...refundedSkus];
      updatedSkus.push(sku);
      setRefundedSkus(updatedSkus);
    }
  }

  function toggleRestockedSku(sku) {
    const addedIndex = restockedSkus.findIndex(el => el === sku);

    if (addedIndex > -1) {
      const updatedSkus = [...restockedSkus];
      updatedSkus.splice(addedIndex, 1);
      setRestockedSkus(updatedSkus);
    } else {
      const updatedSkus = [...restockedSkus];
      updatedSkus.push(sku);
      setRestockedSkus(updatedSkus);
    }
  }

  async function resolve() {
    const updatedItems = [...returnObj.items].map(item => {
      const refundedSkuIndex = refundedSkus.findIndex(sku => sku === item.sku);
      if (refundedSkuIndex > -1) {
        item.refund_status = 'processed';
        item.refunded_at = { $date: { $numberLong: Date.now().toString() } };
      }

      const restockedSkuIndex = restockedSkus.findIndex(sku => sku === item.sku);
      if (restockedSkuIndex > -1) {
        item.restock_status = 'processed';
        item.restocked_at = { $date: { $numberLong: Date.now().toString() } };
      }

      if (typeof item.refunded_at === 'string') {
        const date = {
          $date: {
            $numberLong: new Date(item.refunded_at).getTime().toString()
          }
        };
        item.refunded_at = date;
      }

      if (typeof item.restocked_at === 'string') {
        const date = {
          $date: {
            $numberLong: new Date(item.restocked_at).getTime().toString()
          }
        };
        item.restocked_at = date;
      }

      return item;
    });

    const notClosed = updatedItems.some(
      item => item.refund_status !== 'processed' || item.restock_status !== 'processed'
    );

    const update = notClosed
      ? {
          items: updatedItems
        }
      : {
          items: updatedItems,
          status: 'closed'
        };

    const response = await globalActions.db.updateReturnObj(update, returnObj._id);
    response.status === 'SUCCESS' && close();
  }

  function getItemJSX(item) {
    return (
      <li key={item.sku} className="return-exception__sku">
        <span className="has-text-weight-bold">{item.sku}</span>

        {item.refund_status !== 'processed' && (
          <label className="checkbox-container">
            <input
              onChange={() => toggleRefundedSku(item.sku)}
              name="refund-exception"
              type="checkbox"
              checked={refundedSkus.find(el => el.sku === item.sku)}
            />
            <span className="checkmark" />
            {text.return_page.refunded}
          </label>
        )}

        {item.refund_status === 'processed' && <span className="has-text-grey-light">{text.return_page.refunded}</span>}

        {item.restock_status !== 'processed' && (
          <label className="checkbox-container">
            <input
              onChange={() => toggleRestockedSku(item.sku)}
              name="restock-exception"
              type="checkbox"
              checked={restockedSkus.find(el => el.sku === item.sku)}
            />
            <span className="checkmark" />
            {text.return_page.restock}
          </label>
        )}

        {item.restock_status === 'processed' && (
          <span className="has-text-grey-light">{text.return_page.restocked}</span>
        )}
      </li>
    );
  }

  return (
    <div className={`return-exception modal ${display ? 'is-active' : ''}`}>
      <div onClick={close} className="modal-background"></div>
      <div className="modal-content">
        <div className="message">
          <div className="message-header">
            <p>{type === 'exception' ? text.return_page.resolve_exception : text.return_page.close_failures}</p>
          </div>
          <div className="message-body">
            <ul>
              {returnObj.items.map(item => {
                if (type === 'exception') {
                  if (item.refund_exception || item.restock_exception) {
                    return getItemJSX(item);
                  }
                } else if (type === 'failure') {
                  if (item.refund_status === 'failed' || item.restock_status === 'failed') {
                    return getItemJSX(item);
                  }
                }
                return null;
              })}
            </ul>

            <button onClick={close} className="button is-danger is-outlined">
              {text.return_page.cancel}
            </button>
            <button onClick={resolve} className="button is-dark">
              {text.return_page.submit}
            </button>
          </div>
        </div>
      </div>
      <button onClick={close} className="modal-close is-large" aria-label="close"></button>
    </div>
  );
};

export default ReturnPageManualResolution;
