import React, { useEffect } from 'react';
import useGlobal from '../store';
import PriceMatchForm from '../components/PriceMatchForm';

const PriceMatch = () => {
  const [globalState, globalActions] = useGlobal();
  const { text } = globalState;

  useEffect(() => {
    globalActions.utils.clearProcessStates();
  }, [globalActions.utils]);

  return (
    <main className="container">
      <section className="section">
        <h1 className="title is-4 has-text-left">{text.price_match_form.title}</h1>
        <PriceMatchForm />
      </section>
    </main>
  );
};

export default PriceMatch;
