import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import qrcode from 'qrcode';
import useGlobal from '../../store';
import ReturnPageTable from './ReturnPageTable';
import ReturnPageManualResolution from './ReturnPageManualResolution';
import ReturnPageSkus from './ReturnPageSkus';
import LoadingSpinner from '../UI/LoadingSpinner';
import Dialog from '../UI/Dialogs';

const ReturnPage = () => {
  const { returnID } = useParams();
  const [globalState, globalActions] = useGlobal();
  const { text, requestStatus, reasons, /* currentCampaign,  */addQRcodeToEmail } = globalState;
  const [returnObj, setReturnObj] = useState(null);
  const [base64, setBase64] = useState('');
  const [displayReturnLabel, setDisplayReturnLabel] = useState(false);
  const [qrCodeBase64, setQRCodeBase64] = useState('');
  const [displayQRCode, setDisplayQRCode] = useState(false);
  const [exceptionModal, displayExceptionModal] = useState(false);
  const [failureModal, displayFailureModal] = useState(false);
  const [refreshing, setRefreshing] = useState(false);

  const fetchReturn = useCallback(async () => {
    setRefreshing(true);

    const returns = await globalActions.db.getReturns({
      key: 'id',
      value: returnID
    });

    if (returns.length > 0) {
      setReturnObj(returns[0]);
    }
    setRefreshing(false);
  }, [globalActions, returnID]);

  useEffect(() => {
    globalActions.utils.clearProcessStates();
    fetchReturn();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (reasons.length < 1) {
      globalActions.db.getReasonsList();
    }
    // eslint-disable-next-line
  }, []);

  /* useEffect(() => {
    if (!currentCampaign) {
      globalActions.altidollarsRefundCampaign.getAltidollarsRefundCurrentCampaign();
    }
    // eslint-disable-next-line
  }, []); */

  async function openReturnLabel() {
    setRefreshing(true);
    if (base64.length === 0) {
      const returnObjects = await globalActions.db.getReturns({
        key: 'bundle',
        value: returnObj.bundle_id
      });


      const ordersStringForLabel = returnObjects.map(el => el.channel[0] + el.order_name).join('-');

      const pdfBase64 = await globalActions.db.prepaidLabelCP(returnObj.bundle_id, ordersStringForLabel);

      if (pdfBase64) {
        setBase64(encodeURI(pdfBase64));
        setDisplayReturnLabel(true);
      }
    } else {
      setDisplayReturnLabel(true);
    }
    setRefreshing(false);
  }

  async function openQRCode() {
    const qrCodeBase64 = await qrcode.toDataURL(returnObj.cp_qr_code.url);
    setQRCodeBase64(qrCodeBase64);
    setDisplayQRCode(true);
  }

  function toggleAddQRcodeToEmail(e) {
    e.preventDefault();
    globalActions.utils.updateStateProperty('addQRcodeToEmail', !addQRcodeToEmail);
  }

  async function createNewReturnLabel(forNewQRCode) {
    setBase64('');
    setRefreshing(true);

    const returnObjects = await globalActions.db.getReturns({
      key: 'bundle',
      value: returnObj.bundle_id
    });

    const ordersStringForLabel = returnObjects.map(el => el.channel[0] + el.order_name).join('-');

    await Promise.all(
      returnObjects.map(async returnObject => {
        const update = {
          cp_prepaid_label_link: null,
          tracking_number: '',
          skip_new_prepaid_label: false,
          return_label_type: 'digital-label'
        };

        if (addQRcodeToEmail) {
          update.qr_code_send_to_client = true;
        }

        if (forNewQRCode) {
          update.qr_code_send_to_client = true;
          update.authorization_email_sent = false;
          update.return_label_email_sent = false;
        }

        const response = await globalActions.db.updateReturnObj(update, returnObject._id);
        return response;
      })
    );

    const pdfBase64 = await globalActions.db.prepaidLabelCP(returnObj.bundle_id, ordersStringForLabel);

    if (pdfBase64) {
      globalActions.notification.showMessage(text.return_page.success_creating_label, 'success');
    } else {
      globalActions.notification.showMessage(text.return_page.failed_creating_label, 'error');
    }

    setReturnObj(null);
    globalActions.utils.updateStateProperty('addQRcodeToEmail', false);
    fetchReturn();
  }

  async function closeReturnObject() {
    const update = {
      status: 'closed',
      closed_when_received: 'reception note'
    };
    const response = await globalActions.db.updateReturnObj(update, returnObj._id);
    if (response.status === 'SUCCESS') {
      globalActions.notification.showMessage(text.return_page.success_closing_return, 'success');
      fetchReturn();
      setTimeout(() => {
        globalActions.notification.hideMessage();
      }, 3000);
    } else {
      globalActions.notification.showMessage(text.return_page.failed_closing_return, 'error');
    }
  }

  function resolveEdgeCases() {
    globalActions.notification.showDialog(text.return_page.confirm, text.return_page.close_return_confirm, () => {
      closeReturnObject();
    });
  }

  return (
    <>
      {(!returnObj || refreshing) && <LoadingSpinner />}

      {returnObj && !refreshing && (
        <>
          <h1 className="title is-size-5">
            {text.return_page.title} {returnID}
          </h1>

          <ReturnPageTable returnObj={returnObj} />

          {returnObj.status === 'open' && returnObj.cp_prepaid_label_link && !returnObj.skip_new_prepaid_label && (
            <div className="is-flex flow-col centered">
              {returnObj.return_label_email_sent && <p className="label">{text.return_page.email_status_sent}</p>}
              {!returnObj.return_label_email_sent && <p className="label">{text.return_page.email_status_not_sent}</p>}
              <div className="return__buttons_group">
                <button onClick={openReturnLabel} className="button is-link return__buttons">
                  {text.return_page.open_return_label}
                </button>
                {returnObj.cp_qr_code && (
                <button onClick={openQRCode} className="button is-link return__buttons">
                  {text.return_page.open_qr_code}
                </button>
              )}
              </div>

              <button
                onClick={() => createNewReturnLabel('forNewQRCode')}
                className="button is-link return__buttons"
              >
                {text.return_page.update_qr_code}
              </button>
              {returnObj.cp_qr_code && (
                  <a href={returnObj.cp_qr_code.url} target="_blank" rel="noopener noreferrer" className="return__pdf_link is-link mt-3">
                    {text.return_page.cant_open_pdf}
                  </a>
                )}
            </div>
          )}

          {returnObj.status === 'received' && (
            <button onClick={resolveEdgeCases} className="button is-danger">
              {text.return_page.close_return}
            </button>
          )}

          {returnObj.status === 'open' && returnObj.skip_new_prepaid_label && (
            <>
              <p className="label">{text.return_page.using_included_label}</p>
              <button
                onClick={() => {
                  globalActions.notification.showDialog(
                    text.return_page.confirm,
                    text.return_page.create_new_label + '?',
                    createNewReturnLabel
                  );
                }}
                className="button is-link return__buttons"
              >
                {text.return_page.create_new_label}
              </button>
              <div className="return-page__add-qr-code">
                <label className="checkbox-container" onClick={e => toggleAddQRcodeToEmail(e)}>
                  <input name="add-qr-code" type="checkbox" readOnly checked={addQRcodeToEmail} />
                  <span className="checkmark" />
                  {text.return_creation.add_qr_code}
                </label>
              </div>
            </>
          )}

          {returnObj.status === 'open' && returnObj.prepaid_label_error && (
            <>
              <p className="label">{text.return_page.new_label_pending}</p>
              {returnObj.return_label_email_pending_sent && (
                <p className="label">{text.return_page.return_label_email_pending_sent}</p>
              )}
            </>
          )}

          {returnObj.status === 'exception' && (
            <>
              <button onClick={() => displayExceptionModal(true)} className="button is-danger">
                {text.return_page.exception_pending}
              </button>

              <ReturnPageManualResolution
                type="exception"
                display={exceptionModal}
                close={() => {
                  displayExceptionModal(false);
                  fetchReturn();
                }}
                returnObj={returnObj}
              />
            </>
          )}

          {returnObj.status !== 'exception' &&
            returnObj.items.some(item => item.refund_status === 'failed' || item.restock_status === 'failed') && (
              <>
                <button onClick={() => displayFailureModal(true)} className="button is-danger">
                  {text.return_page.fail_handling_button}
                </button>

                {returnObj.refund_fail_reason && returnObj.refund_fail_reason.refund && (
                  <p className="is-size-7 label">
                    ***{text.return_page.reason}:{' '}
                    {returnObj.refund_fail_reason.refund.error.errors
                      ? returnObj.refund_fail_reason.refund.error.errors[0]
                      : returnObj.refund_fail_reason.refund.error}
                    .
                  </p>
                )}

                <ReturnPageManualResolution
                  type="failure"
                  display={failureModal}
                  close={() => {
                    displayFailureModal(false);
                    fetchReturn();
                  }}
                  returnObj={returnObj}
                />
              </>
            )}

          <ReturnPageSkus returnObj={returnObj} fetchReturn={fetchReturn} />

          {base64.length > 0 && (
            <div className={`modal ${displayReturnLabel ? 'is-active' : null}`}>
              <div onClick={() => setDisplayReturnLabel(false)} className="modal-background"></div>
              <div className="modal-content return__return-label">
                <div className="box">
                  <iframe
                    src={`data:application/pdf;base64, ${base64}`}
                    title="Return label"
                    height="100%"
                    width="100%"
                  ></iframe>
                </div>
              </div>
              <button
                onClick={() => setDisplayReturnLabel(false)}
                className="modal-close is-large"
                aria-label="close"
              ></button>
            </div>
          )}

          {qrCodeBase64.length > 0 && (
            <div className={`modal ${displayQRCode ? 'is-active' : null}`}>
              <div onClick={() => setDisplayQRCode(false)} className="modal-background"></div>
              <div className="modal-content return__qr-code">
                <div className="box">
                  <img src={qrCodeBase64} alt="QR code" className="return__qr-code__img"></img>
                </div>
              </div>
              <button
                onClick={() => setDisplayQRCode(false)}
                className="modal-close is-large"
                aria-label="close"
              ></button>
            </div>
          )}
        </>
      )}

      {requestStatus === 'SUCCESS' && !returnObj && <p className="label">{text.return_page.invalid_return_id}</p>}

      <Dialog />
    </>
  );
};

export default ReturnPage;
