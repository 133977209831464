import React from 'react';
import { useAuth0 } from '../authService/react-auth0-wrapper';
import useGlobal from '../store';

const Homepage = () => {
  const { loginWithRedirect, isAuthenticated, logout, loading } = useAuth0();
  const [globalState] = useGlobal();
  const { text } = globalState;

  return (
    <main className="container">
      <section className="section">
        <h1 className="title is-3">Cerberus</h1>
        <img src="/images/cerberus_square.png" alt="cerberus-logo" width="280px"></img>
        <div>
          {!isAuthenticated && !loading && (
            <button className="button is-dark is-medium" value="login" onClick={loginWithRedirect}>
              {text.homepage.sign_in}
            </button>
          )}

          {isAuthenticated && (
            <button
              className="button is-dark is-medium"
              value="login"
              onClick={() => logout({ returnTo: process.env.REACT_APP_BASE_URL })}
            >
              {text.homepage.sign_out}
            </button>
          )}
        </div>
      </section>
    </main>
  );
};

export default Homepage;
