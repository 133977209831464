import React from 'react';
import useGlobal from '../../store';

const PriceMatchPageSkus = ({ priceMatchObj }) => {
  const [globalState] = useGlobal();
  const { text } = globalState;

  const formatDate = dateString => {
    return new Date(dateString).toLocaleString('en-CA', {
      timeZone: 'America/New_York'
    });
  };

  return (
    <>
      <div className="price-match__skus">
        <h2 className="title is-size-5 has-text-left">{text.price_match_page.price_matched_articles}</h2>
        {priceMatchObj.items.map(item => (
          <div key={item.sku} className="box price-match__sku">
            <ul>
              <li>
                <strong>SKU: {item.sku}</strong>
              </li>
              <li>
                {text.price_match_page.original_paid_price}: ${item.paid_price}
              </li>
              <li>
                {text.price_match_page.current_paid_price}: ${item.line_item_current_price}
              </li>
              <li>
                {text.price_match_page.requested_price}: ${item.requested_price}
              </li>
              <li>
                {text.price_match_page.refunded_amount}: ${Number(item.refunded_amount) / item.quantity_ordered}
              </li>
              <li>
                {text.price_match_page.quantity_ordered}: {item.quantity_ordered}
              </li>
              <li>
                {text.price_match_page.refund_status}:{' '}
                {item.refund_status === null ? text.price_match_page.pending : text.price_match_page.processed}
              </li>
              <li>
                {text.price_match_page.refunded_at}: {item.refunded_at === null ? 'null' : formatDate(item.refunded_at)}
              </li>
              <li>
                {text.price_match_page.line_item_id}: {item.line_item_id}
              </li>
              <li>
                {text.price_match_page.line_item_id}: {item.line_item_id}
              </li>
              <li>
                {text.price_match_page.price_match_type}:{' '}
                {item.price_match_type ? text.price_match_page[item.price_match_type] : ''}
              </li>
              <li>
                {text.price_match_page.active_coupon}:{' '}
                {item.active_discount ? text.price_match_page.yes : text.price_match_page.no}
              </li>
            </ul>
          </div>
        ))}
      </div>
    </>
  );
};

export default PriceMatchPageSkus;
