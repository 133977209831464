import React, { useState, useEffect } from 'react';
import useGlobal from '../store';
import LoadingSpinner from '../components/UI/LoadingSpinner';

const SkuPriceHistory = () => {
  const [globalState, globalActions] = useGlobal();
  const { text, channels } = globalState;
  const [skuLoadingStatus, setSkuLoadingStatus] = useState('INITIAL');
  const [skusHistory, setSkuHistory] = useState([]);

  useEffect(() => {
    globalActions.utils.clearProcessStates();
  }, [globalActions.utils]);

  async function handleSearch(e) {
    e.preventDefault();

    setSkuLoadingStatus('LOADING');

    const sku = e.target.sku.value.replace(/ /g, '');
    const channel = e.target.channel.value;

    const skuHistory = await globalActions.db.getPriceHistory(sku, channel);

    setSkuHistory(skuHistory);
    setSkuLoadingStatus('SUCCESS');
  }

  return (
    <>
      <main className="container">
        <section className="section">
          <h1 className="title is-4">{text.sku_price_history.title}</h1>

          <form onSubmit={handleSearch}>
            <div className="field has-addons has-addons-centered">
              <div className="control">
                <input
                  className="input"
                  name="sku"
                  placeholder={text.sku_price_history.sku_input_placeholder}
                  autoComplete="off"
                  autoFocus={true}
                  required
                />
              </div>
              <div className="control">
                <div className="select">
                  <select name="channel">
                    {Object.keys(channels).map(name => (
                      <option key={channels[name].channel_id} value={name}>
                        {name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="control">
                <button className="button is-link" type="submit">
                  {text.sku_price_history.submit}
                </button>
              </div>
            </div>
          </form>

          {skuLoadingStatus === 'LOADING' && <LoadingSpinner />}

          {skuLoadingStatus === 'SUCCESS' &&
            skusHistory.length > 0 &&
            skusHistory.map(hist => {
              const date = new Date(Number(hist.price_date.$date.$numberLong));
              const formatedDate = date.toLocaleDateString();
              return (
                <span
                  key={hist._id.$oid}
                  className={`tag is-medium ${
                    hist.is_recent_price !== undefined && hist.is_recent_price === false ? 'is-orange' : 'is-primary'
                  }`}
                  style={{ margin: '0.5em' }}
                >
                  ${hist.price.$numberDouble} - {formatedDate}
                </span>
              );
            })}

          {skuLoadingStatus === 'SUCCESS' && skusHistory.length === 0 && (
            <p className="label">{text.sku_price_history.no_history_found}</p>
          )}
        </section>
      </main>
    </>
  );
};

export default SkuPriceHistory;
