export const updateUrl = (store, section) => {
  const filtersQueryString = store.actions.utils.filtersStateToQueryString(store.state.objectsQueryFilters);

  const filtersQueryStringDisplay = filtersQueryString.length > 0 ? '?' + filtersQueryString : '';
  window.history.pushState({}, '', '/' + section + filtersQueryStringDisplay);
};

export const updateObjects = (store, filters, section) => {
  updateObjectsQueryFiltersState(store, filters);
  updateUrl(store, section);
  if (section === 'price-matches') {
    store.actions.db.getPriceMatchesList();
  } else if (section === 'returns') {
    store.actions.db.getReturnsListTagsData();
    store.actions.db.getReturnsList();
  } else if (section === 'store-credits-bulk-actions-list') {
    store.actions.db.getBulkCreditList();
  } else if (section === 'altidollars-fixer-list') {
    store.actions.db.getAltidollarsBalanceFixesList();
  }
};

export const browserBackObjects = (store, paramsString, section) => {
  store.setState({ listFetchStatus: 'LOADING', listData: [], objectsQueryFilters: [] });
  urlParamsToObjectsQueryFiltersState(store, paramsString);
  if (section === 'price-matches') {
    store.actions.db.getPriceMatchesList();
  } else if (section === 'returns') {
    store.actions.db.getReturnsListTagsData();
    store.actions.db.getReturnsList();
  } else if (section === 'store-credits-bulk-actions-list') {
    store.actions.db.getBulkCreditList();
  } else if (section === 'altidollars-fixer-list') {
    store.actions.db.getAltidollarsBalanceFixesList();
  }
};

function urlParamsToObjectsQueryFiltersState(store, paramsString) {
  const params = paramsString ? paramsString : window.location.search;
  if (params.length > 0) {
    const objectsQueryFilters = [];
    const createdAt = [];
    const receivedAt = [];
    const processedAt = [];
    params
      .replace('?', '')
      .split('&')
      .forEach(param => {
        if (param.includes('created_at.start')) {
          createdAt.push({
            name: 'created_at',
            value: `from ${param.split('=')[1]} to `,
            type: 'range'
          });
        } else if (param.includes('created_at.end')) {
          createdAt[0].value += param.split('=')[1];
        } else if (param.includes('received_at.start')) {
          receivedAt.push({
            name: 'received_at',
            value: `from ${param.split('=')[1]} to `,
            type: 'range'
          });
        } else if (param.includes('received_at.end')) {
          receivedAt[0].value += param.split('=')[1];
        } else if (param.includes('processed_at.start')) {
          processedAt.push({
            name: 'processed_at',
            value: `from ${param.split('=')[1]} to `,
            type: 'range'
          });
        } else if (param.includes('processed_at.end')) {
          processedAt[0].value += param.split('=')[1];
        } else if (param.includes('page')) {
          objectsQueryFilters.push({
            name: param.split('=')[0],
            value: param.split('=')[1],
            type: 'value'
          });
        } else if (param.includes('sort')) {
          objectsQueryFilters.push({
            name: param.split('=')[0],
            value: param.split('=')[1],
            type: 'value'
          });
        } else if (param.includes('q')) {
          objectsQueryFilters.push({
            name: param.split('=')[0],
            value: param.split('=')[1],
            type: 'search'
          });
        } else {
          objectsQueryFilters.push({
            name: param.split('=')[0].split('.')[0],
            value: param.split('=')[0].split('.')[1],
            type: 'value'
          });
        }
      });

    store.setState({
      objectsQueryFilters: [...objectsQueryFilters, ...createdAt, ...receivedAt, ...processedAt]
    });
  }
}

function updateObjectsQueryFiltersState(store, filters) {
  const newFilters = [...filters];
  const oldFilters = [...store.state.objectsQueryFilters];

  const currentPageIndex = oldFilters.findIndex(el => el.name === 'page');
  if (currentPageIndex > -1) {
    oldFilters.splice(currentPageIndex, 1);
  }

  if (newFilters.findIndex(el => el.delete) > -1) {
    const deletedFilterIndex = oldFilters.findIndex(
      el => el.name === newFilters[0].name && el.value === newFilters[0].value
    );
    oldFilters.splice(deletedFilterIndex, 1);
    store.setState({ objectsQueryFilters: oldFilters });
  } else {
    const newSortIndex = newFilters.findIndex(el => el.name.includes('sort'));
    if (newSortIndex > -1) {
      oldFilters.splice(
        oldFilters.findIndex(el => el.name.includes('sort')),
        1
      );
    }

    const newSearchIndex = newFilters.findIndex(el => el.name === 'q');
    if (newSearchIndex > -1) {
      oldFilters.splice(
        oldFilters.findIndex(el => el.name === 'q'),
        1
      );
    }

    store.setState({ objectsQueryFilters: oldFilters.concat(newFilters) });
  }
}
