export const showDialog = (store, title, message, action, cancelAction) => {
  store.setState({
    dialog: {
      open: true,
      title,
      message,
      action,
      cancelAction
    }
  });
};

export const confirm = (store, confirmed) => {
  if (confirmed) {
    store.state.dialog.action !== undefined && store.state.dialog.action();
    store.setState({
      dialog: {
        open: false,
        message: '',
        action: null,
        cancelAction: null
      }
    });
  } else {
    store.state.dialog.cancelAction !== undefined && store.state.dialog.cancelAction();
    store.setState({
      dialog: {
        open: false,
        message: '',
        action: null,
        cancelAction: null
      }
    });
  }
};

export const showMessage = (store, message, type, errorMessage) => {
  window.scrollTo(0, 0);
  store.setState({
    message: {
      open: true,
      message,
      type,
      errorMessage
    }
  });
};

export const hideMessage = store => {
  store.setState({
    message: {
      open: false,
      title: '',
      message: '',
      type: ''
    }
  });
};
