import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import useGlobal from '../store';
import Dialogs from '../components/UI/Dialogs';
import LoadingSpinner from '../components/UI/LoadingSpinner';

const ReturnReceptionNote = () => {
  const [globalState, globalActions] = useGlobal();
  const { text, channels } = globalState;
  const [submitStatus, setSubmitStatus] = useState('INITIAL');

  useEffect(() => {
    globalActions.utils.clearProcessStates();
  }, [globalActions.utils]);

  async function submitReceptionNote(e) {
    setSubmitStatus('LOADING');
    e.preventDefault();
    const orderName = e.target.order_name.value;
    const channel = e.target.channel_select.value;
    const trackingNumber = e.target.return_tracking.value.replace(/ /g, '');
    const receptionNote = e.target.reception_note.value;

    const validForm =
      orderName.length > 0 && channel.length > 0 && trackingNumber.length > 0 && receptionNote.length > 0;

    if (!validForm) {
      globalActions.notification.showMessage(text.reception_note.error_missing_fields, 'error');
      setSubmitStatus('INITIAL');
      return;
    }

    globalActions.utils.updateStateProperty('tracking', {
      tracking_number: trackingNumber
    });
    globalActions.utils.updateStateProperty('receptionNote', receptionNote);
    globalActions.utils.updateStateProperty('returnLabelType', 'label-in-box');

    const orders = await globalActions.db.getOrders(orderName, channel);

    if (orders.length > 0) {
      const returns = await globalActions.db.getReturns({
        key: 'tracking',
        value: trackingNumber
      });

      const existingReturnObj = returns.find(returnObj => returnObj.channel === channel);

      if (existingReturnObj) {
        globalActions.notification.showMessage(
          <p>
            {text.reception_note.error_return_exists}{' '}
            <Link to={`/returns/${existingReturnObj._id}`}>{text.reception_note.error_return_exists_link}</Link>
          </p>,
          'error'
        );
      } else {
        const response = await globalActions.processing.createReceptionNoteReturnObject();

        if (response.status === 'SUCCESS') {
          globalActions.notification.showMessage(text.reception_note.success, 'success');
          setTimeout(() => {
            window.location = '/returns/' + response.newReturnObjId;
          }, 1000);
        } else {
          globalActions.notification.showMessage(text.reception_note.fail, 'error');
        }
      }
    } else {
      globalActions.notification.showMessage(text.reception_note.error_order_not_found, 'error');
    }

    setSubmitStatus('INITIAL');
  }

  return (
    <>
      <main className="container">
        <section className="section">
          <h1 className="title is-4">{text.reception_note.title}</h1>

          {submitStatus === 'LOADING' && <LoadingSpinner />}

          {submitStatus !== 'LOADING' && (
            <form onSubmit={submitReceptionNote} className="return-reception-note__form" /* onSubmit={} */>
              <div className="field has-addons has-addons-centered">
                <div className="control is-expanded">
                  <input
                    className="input"
                    name="order_name"
                    placeholder={text.reception_note.order_number_placeholder}
                    autoComplete="off"
                    autoFocus={true}
                  />
                </div>
                <div className="control">
                  <div className="select">
                    <select name="channel_select">
                      {Object.keys(channels).map(name => (
                        <option key={channels[name].channel_id} value={name}>
                          {name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <div className="field">
                <div className="control">
                  <input
                    className="input"
                    name="return_tracking"
                    placeholder={text.reception_note.tracking_number_placeholder}
                    autoComplete="off"
                  />
                </div>
              </div>

              <div className="field">
                <div className="control">
                  <textarea
                    className="textarea"
                    name="reception_note"
                    placeholder={text.reception_note.note_placeholder}
                    maxLength="200"
                  />
                </div>
              </div>

              <div className="field is-grouped is-grouped-centered">
                <div className="control">
                  <button className="button is-link is-medium">{text.reception_note.submit}</button>
                </div>
              </div>
            </form>
          )}
          <Dialogs />
        </section>
      </main>
    </>
  );
};

export default ReturnReceptionNote;
