import React from 'react';
import useGlobal from '../../store';
import PriceMatchSkusItem from './PriceMatchSkusItem';

const PriceMatchSkus = () => {
  const [globalState] = useGlobal();
  const { text, selectedItems, requestStatus } = globalState;

  return (
    <div className="price-match-form__skus">
      <h2 className="label price-match-form__skus__title">{text.price_match_form.price_matched_articles}</h2>
      {requestStatus === 'SUCCESS' && selectedItems.length > 0 && selectedItems.find(item => item.price_history) && (
        <ul className="price-match-form__skus__list">
          {selectedItems.map(item => (
            <PriceMatchSkusItem key={item.sku} item={item} />
          ))}
        </ul>
      )}
    </div>
  );
};

export default PriceMatchSkus;
