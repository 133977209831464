import React, { useState, useEffect, useCallback } from 'react';
import useGlobal from '../../store';

const ReturnExpectedRefund = ({ refundedItems }) => {
  const [globalState, globalActions] = useGlobal();
  const { text, orders, priceMatches, selectedItems, restockingFeeException } = globalState;
  const [refundData, setRefundData] = useState(null);

  const calculateExpectedRefundBreakdown = useCallback(() => {
    const orderObject = orders.find(el => el.order_number.toString() === refundedItems[0].order_name);
    const restockingFee = globalActions.utils.calculateRestockingFee();

    if (orderObject && orderObject.transactions && orderObject.total_price_set) {
      const data = globalActions.expectedRefund.getExpectedRefund(
        restockingFee,
        refundedItems,
        priceMatches,
        orderObject
      );
      setRefundData(data);
    }
  }, [refundedItems, orders, priceMatches, globalActions.expectedRefund, globalActions.utils]);

  useEffect(() => {
    calculateExpectedRefundBreakdown();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems, restockingFeeException]);

  const formatMoney = amount => `$${(Math.round(amount * 100) / 100).toFixed(2)}`;

  return (
    <div className="return-form__expected-refund">
      <label className="label">
        {text.return_expected_refund.expected_refund} #{refundedItems[0].order_name}
      </label>
      {refundData && (
        <ul className="return-form__expected-refund__list">
          <li className="return-form__expected-refund__list-item">
            <span>
              {refundedItems.length} {text.return_expected_refund.item}
              {refundedItems.length > 1 ? 's' : ''}
            </span>
            <span>{formatMoney(refundData.itemsRefundTotalBeforeTaxes)}</span>
          </li>
          {refundData.restockingFee > 0 && refundData.itemsRefundTotalBeforeTaxes > 0 && (
            <li className="return-form__expected-refund__list-item">
              <span>{text.return_expected_refund.restocking_fee}</span>
              <span>-{formatMoney(refundData.restockingFee)}</span>
            </li>
          )}
          {refundData.totalRefundBeforeTaxes > 0 && refundData.restockingFee > 0 && (
            <li className="return-form__expected-refund__list-item">
              <span>{text.return_expected_refund.subtotal}</span>
              <span>{formatMoney(refundData.totalRefundBeforeTaxes)}</span>
            </li>
          )}
          {refundData.totalRefundBeforeTaxes > 0 &&
            refundData.itemsRefundTotalBeforeTaxes > 0 &&
            refundData.taxLines.map(tax => (
              <li key={tax.title} className="return-form__expected-refund__list-item">
                <span>
                  {text.return_expected_refund.tax} {tax.title} %{tax.rate}
                </span>
                <span>{formatMoney((refundData.taxesTotal * tax.rate) / refundData.totalTaxRate)}</span>
              </li>
            ))}
          <li className="return-form__expected-refund__list-item has-text-weight-bold">
            <span>Total</span>
            {refundData.totalRefundBeforeTaxes > 0 && (
              <span>
                {!refundData.expirableCreditsBalance > 0 ? 'CAN' : ''} {formatMoney(refundData.totalRefundAfterTaxes)}
              </span>
            )}
            {refundData.totalRefundBeforeTaxes <= 0 && <span>CAN $0.00</span>}
          </li>
          {(refundData.expirableCreditsBalance > 0 ||
            refundData.totalRefundInAltidollarsAfterTaxes > 0 ||
            refundData.nonRefundableManualBalance > 0) &&
            refundData.totalRefundInMoneyAfterTaxes > 0 && (
              <li className="return-form__expected-refund__list-item return-form__expected-refund__list-item-detail">
                <span>{text.return_expected_refund.refunded_cash}</span>
                <span>{formatMoney(refundData.totalRefundInMoneyAfterTaxes)}</span>
              </li>
            )}
          {refundData.totalRefundInAltidollarsAfterTaxes > 0 && (
            <li className="return-form__expected-refund__list-item return-form__expected-refund__list-item-detail">
              <span>{text.return_expected_refund.refunded_altidollars}</span>
              <span>{formatMoney(refundData.totalRefundInAltidollarsAfterTaxes)}</span>
            </li>
          )}
          {refundData.expirableCreditsBalance > 0 && (
            <li className="return-form__expected-refund__list-item return-form__expected-refund__list-item-detail">
              <span>
                {text.return_expected_refund.expired_credits} ({text.return_expected_refund.non_refundable})
              </span>
              <span>{formatMoney(refundData.expirableCreditsBalance)}</span>
            </li>
          )}
          {refundData.nonRefundableManualBalance > 0 && (
            <li className="return-form__expected-refund__list-item return-form__expected-refund__list-item-detail">
              <span>{text.return_expected_refund.manual_refund_balance}</span>
              <span>{formatMoney(refundData.nonRefundableManualBalance)}</span>
            </li>
          )}
        </ul>
      )}

      {!refundData && <p>{text.return_expected_refund.refresh_order}</p>}
    </div>
  );
};

export default ReturnExpectedRefund;
