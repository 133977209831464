import React, { useState } from 'react';
import useGlobal from '../store';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import Dialogs from '../components/UI/Dialogs';
import LoadingSpinner from '../components/UI/LoadingSpinner';

const BulkStoreCredit = () => {
  const [globalState, globalActions] = useGlobal();
  const { lang, text } = globalState;

  // Data Fields
  const [expiryDateValue, setexpiryDateValue] = useState('');
  const [creditValue, setcreditValue] = useState('');
  const [duplicatesOnly, setDuplicatesOnly] = useState(false);
  const [campaignName, setCampaignName] = useState('');

  // Helpers
  const [creditAddMode, setCreditAddMode] = useState(true);
  const [loading, setLoading] = useState(false);

  const dayPickerProps = {
    locale: lang,
    months: text.months,
    weekdaysLong: text.weekdays_long,
    weekdaysShort: text.weekdays_short
  };

  function handleUpload(e) {
    e.preventDefault();

    //Err Handling Area
    if (!expiryDateValue || !creditValue) {
      globalActions.notification.showMessage(text.store_credits.all_fields_required);
      return;
    } else if (creditValue <= 0) {
      globalActions.notification.showMessage(text.store_credits.negative_value_not_allowed);
    } else if (creditValue > 100) {
      globalActions.notification.showMessage(text.store_credits.too_much_credit_value);
    } else if (creditAddMode && expiryDateValue.getTime() < new Date().getTime()) {
      globalActions.notification.showMessage(text.store_credits.date_already_passed);
    } else {
      let popupText =
        creditValue > 30 ? text.store_credits.confirm_value_to_be_added + creditValue : text.store_credits.confirm_text;
      globalActions.notification.showDialog(text.store_credits.confirm, popupText, () => {
        let selectedFile = document.getElementById('uploadcsv').files[0];
        let reader = new FileReader();
        reader.readAsText(selectedFile);
        reader.onload = async () => {
          const emails = CSVToArray(reader.result);

          //Create Payload
          if (emails) {
            const emailsWithoutDuplicates = [...new Set(emails)];

            let jsonPayload = {
              mode: creditAddMode ? 'add' : 'remove',
              expiryDate: expiryDateValue.toISOString().split('T')[0],
              removeMode: creditAddMode ? null : duplicatesOnly ? 'duplicates' : 'all',
              campaignName,
              creditValue,
              emails: emailsWithoutDuplicates
            };

            setLoading(true);

            //Post Payload
            const resp = await globalActions.db.postBulkCreditCsvData(jsonPayload);
            setLoading(false);
            if (resp.status === 'SUCCESS') {
              globalActions.notification.showMessage(text.store_credits.post_success, 'success');
              resetForm();
            } else {
              globalActions.notification.showMessage(text.store_credits.post_error, 'error');
            }
          } else {
            return;
          }
        };
      });
    }
  }

  function resetForm() {
    setcreditValue('');
    setexpiryDateValue('');
    setCampaignName('');
    document.getElementById('uploadcsv').value = '';
  }

  function CSVToArray(strData) {
    const strDelimiter = ',';
    const objPattern = new RegExp('(\\,|\\r?\\n|\\r|^)(?:"([^"]*(?:""[^"]*)*)"|([^"\\,\\r\\n]*))', 'gi');

    const arrData = [[]];
    let arrMatches = null;

    while ((arrMatches = objPattern.exec(strData))) {
      const strMatchedDelimiter = arrMatches[1];
      if (strMatchedDelimiter.length && strMatchedDelimiter !== strDelimiter) {
        arrData.push([]);
      }

      let strMatchedValue;
      if (arrMatches[2]) {
        strMatchedValue = arrMatches[2].replace(new RegExp('""', 'g'), '"');
      } else {
        strMatchedValue = arrMatches[3];
      }
      arrData[arrData.length - 1].push(strMatchedValue);
    }

    const emailArray = arrData.flat().filter(el => el.length > 0);

    const emailRe = /\S+@\S+\.\S+/;
    const wrongEmailIndex = emailArray.findIndex(email => !emailRe.test(email));
    if (wrongEmailIndex > -1) {
      globalActions.notification.showMessage(text.store_credits.invalid_email_csv_data + (wrongEmailIndex + 1));
      return false;
    }

    return emailArray;
  }

  function validateCSVFormat(event) {
    if (event.target && event.target.files[0]) {
      let fileNameArr = event.target.files[0].name.split('.');

      if (fileNameArr[fileNameArr.length - 1].toLowerCase() !== 'csv') {
        globalActions.notification.showMessage(text.store_credits.wrong_file_format);
        return;
      }
    } else {
      globalActions.notification.showMessage(text.store_credits.something_went_wrong); //In case of cancelling the upload
    }
  }

  return (
    <main className="container">
      <section className="section">
        <h1 className="title is-4 has-text-left mb-2">{text.store_credits.title}</h1>
        <div className="columns is-centered">
          <div className="column is-6-widescreen is-8-desktop is-8-tablet">
            {loading && <LoadingSpinner />}
            {!loading && (
              <form onSubmit={handleUpload}>
                <div className="card">
                  <header className="card-header pr-1 pt-1">
                    <p className="card-header-title">{text.store_credits.card_header}</p>
                    <div className="field mt-1">
                      <div className="control">
                        <label className="switch">
                          <input
                            type="checkbox"
                            onChange={() => setCreditAddMode(!creditAddMode)} // To be changed when remove backend is ready
                            checked={creditAddMode}
                          />
                          <span className="slider"></span>
                        </label>
                      </div>
                    </div>
                  </header>

                  <div className="card-content">
                    <div className="content">
                      <div className="columns">
                        <div className="column">
                          <div className="field">
                            <div className="control">
                              <input
                                type="text"
                                className="input"
                                autoFocus={true}
                                maxLength="25"
                                placeholder={text.store_credits.campaign_name}
                                value={campaignName}
                                onChange={e => setCampaignName(e.target.value.toString())}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="columns">
                        <div className="column is-6">
                          <div className="field">
                            <div className="control">
                              <input
                                type="number"
                                className="input"
                                min="0"
                                placeholder={text.store_credits.credit_value}
                                value={creditValue}
                                onChange={e => setcreditValue(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="column is-6">
                          <div className="select bulk-store-credits__date-select">
                            <DayPickerInput
                              value={expiryDateValue}
                              placeholder={text.store_credits.expiry_date}
                              onDayChange={date => setexpiryDateValue(date)}
                              dayPickerProps={dayPickerProps}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="columns is-centered">
                        <div className="column">
                          <div className="field">
                            <div className="control">
                              <input
                                className="input"
                                name="csv_upload"
                                placeholder={text.store_credits.choose_file_to_upload}
                                autoComplete="off"
                                type="file"
                                id="uploadcsv"
                                onChange={event => validateCSVFormat(event)}
                                required
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {!creditAddMode && (
                        <div className="columns is-left">
                          <div className="column">
                            <div className="field">
                              <div className="control">
                                <input
                                  type="checkbox"
                                  className="checkbox"
                                  onChange={e => setDuplicatesOnly(e.target.checked)}
                                  checked={duplicatesOnly}
                                />{' '}
                                {text.store_credits.remove_duplicates_only}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="coulmns is-centered has-text-grey-light is-size-7 mb-2">
                        {text.store_credits.csv_info}
                      </div>

                      <div className="columns is-centered mt-2">
                        <div className="control">
                          <button className={'button ' + (creditAddMode ? 'is-success' : 'is-danger')} type="submit">
                            {creditAddMode ? text.store_credits.add : text.store_credits.remove}
                          </button>
                          <button className="button is-dark ml-4" type="button" onClick={resetForm}>
                            {text.store_credits.reset_form}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>

        <Dialogs />
      </section>
    </main>
  );
};

export default BulkStoreCredit;
