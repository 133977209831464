import React from 'react';
import useGlobal from '../../store';

const ProcessingButtons = () => {
  const [globalState, globalActions] = useGlobal();
  const { text, selectedItems } = globalState;

  function voidReturnObj(e) {
    e.preventDefault();
    globalActions.notification.showDialog(
      text.return_processing.confirm,
      text.return_processing.clear_form,
      globalActions.utils.clearProcessStates
    );
  }

  function submitProcess(e) {
    e.preventDefault();
    if (selectedItems.length > 0) {
      globalActions.notification.showDialog(text.return_processing.confirm, text.return_processing.process_return, () =>
        globalActions.processing.processReturnObjects('processing')
      );
    } else {
      globalActions.notification.showMessage(text.return_processing.error_no_items, 'error');
    }
  }

  return (
    <div className="buttons is-centered">
      <button className="button is-dark" onClick={voidReturnObj}>
        {text.return_processing.void}
      </button>
      <button className="button is-link" onClick={submitProcess}>
        {text.return_processing.process_button}
      </button>
    </div>
  );
};

export default ProcessingButtons;
