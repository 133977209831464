import React, { useState } from 'react';
import useGlobal from '../../store';
import { formatArticleTitle } from '../../utils/formatters';
import ProductImg from '../UI/ProductImg';

const ProcessingItemsItem = ({ item, currentProcessingChannel }) => {
  const [globalState, globalActions] = useGlobal();
  const { lang, text, selectedItems, channels } = globalState;
  const [orderChannel, setOrderChannel] = useState('ALS');
  const [isRefundException] = useState(item.refund_exception);
  const [isAltidollarRefundCampaign] = useState(item.altidollar_refund);

  async function validateItemOrder(e, item) {
    e.preventDefault();

    const orderNb = e.target.validate_item_order.value;
    if (orderNb.length > 0) {
      await globalActions.items.updateValidatedItem(item, orderNb, orderChannel);
    }

    const $validateInput = document.querySelector('[name="validate_item_order"]');
    if ($validateInput) {
      document.querySelector('[name="validate_item_order"]').value = '';
      document.querySelector('[name="validate_item_order"]').focus();
    }
  }

  function toggleAltidollarsRefund(e) {
    e.preventDefault();
    const selectedItem = selectedItems.find(el => el.uniq_id === item.uniq_id);
    const current = item.altidollar_refund;
    globalActions.items.updateItem(selectedItem, 'altidollar_refund', !current);
  }

  return (
    <div className="card card--sku">
      <div className="card-content is-flex">
        <ProductImg item={item} size="100px" margin="0.1em 1.5em 0.1em 0" />
        <div className="has-text-left">
          <p className="has-text-weight-bold">{item.sku}</p>
          <p>{formatArticleTitle(lang, item)}</p>

          <>
            {item.validated ? (
              <p>
                {text.return_processing.order}: {item.channel}-{item.order_name}
              </p>
            ) : (
                <form className="sku_form" onSubmit={e => validateItemOrder(e, item)}>
                  <div className="field has-addons has-addons-centered">
                    <div className="control">
                      <div className="select">
                        <select onChange={e => setOrderChannel(e.target.value)}>
                          {Object.keys(channels).map(name => (
                            <option key={channels[name].channel_id} value={name}>
                              {name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="control">
                      <input
                        className="input"
                        name="validate_item_order"
                        placeholder={text.return_processing.order_input_placeholder}
                        autoComplete="off"
                        autoFocus
                      />
                    </div>

                    <div className="control">
                      <button className="button is-link">{text.return_processing.validate}</button>
                    </div>
                  </div>
                </form>
              )}
          </>

          {item.sku.indexOf('MK') === -1 && (
            <div>
              <label
                className="processing__sku__refund-exception checkbox-container"
                onClick={e => {
                  globalActions.items.toggleItemRefundException(e, item.uniq_id);
                }}
              >
                <input name={item.uniq_id} type="checkbox" checked={item.refund_exception} readOnly />
                <span className="checkmark"></span>
                {text.return_processing.no_refund}
                {isRefundException && <span className="has-text-weight-bold"> (CX)</span>}
              </label>

              {!item.isInstalmentsGateway && channels[currentProcessingChannel].store_credits_policy && (
                <label
                  className="processing__sku__altidollar-refund checkbox-container"
                  onClick={toggleAltidollarsRefund}
                >
                  <input name="altidollar-refund" type="checkbox" checked={item.altidollar_refund} readOnly />
                  <span className="checkmark" />
                  {text.return_processing.altidollar_refund}
                  {isAltidollarRefundCampaign && <span className="has-text-weight-bold"> (CX)</span>}
                </label>
              )}
            </div>
          )}

          {item.sku.indexOf('MK') > -1 && (
            <div>
              <strong>{text.return_processing.no_refund_mp}</strong>
            </div>
          )}
        </div>
      </div>
      <div className="card-footer">
        <button
          className="button is-danger"
          onClick={() => {
            globalActions.items.removeSelectedItem(item);
            document.querySelector('[name="skuOrUPC"]').focus();
          }}
        >
          {text.return_processing.remove}
        </button>
      </div>
    </div>
  );
};

export default ProcessingItemsItem;
