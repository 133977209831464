import React, { useState, useEffect } from 'react';
import useGlobal from '../../store';
import ItemsSelection from '../ItemsSelection';
import ReturnExpectedRefund from './ReturnExpectedRefund';
import ReturnedItems from './ReturnedItems';
import ReturnNotes from './ReturnNotes';
import Dialog from '../UI/Dialogs';

const ReturnForm = () => {
  const [globalState, globalActions] = useGlobal();
  const {
    text,
    selectedItems,
    prepaidLabelNote,
    restockingFeeException,
    returnLabelType,
    addQRcodeToEmail,
    requestStatus,
    selectedSeller,
    channel,
    isInternational,
    selectedOpenedReturn
  } = globalState;
  const [restockingFee, setRestockingFee] = useState(0);
  const [alsWarehouseReturn, setAlsWarehouseReturn] = useState(false);

  function setReturnLabelType(e) {
    e.preventDefault();
    globalActions.utils.updateStateProperty('returnLabelType', e.target.value);
  }

  function toggleAddQRcodeToEmail(e) {
    e.preventDefault();
    globalActions.utils.updateStateProperty('addQRcodeToEmail', !addQRcodeToEmail);
  }

  function toggleAlsWarehouseReturn(e) {
    e.preventDefault();
    setAlsWarehouseReturn(!alsWarehouseReturn);
    globalActions.utils.updateStateProperty('als_warehouse_return', !alsWarehouseReturn);
  }

  async function submitReturnForm() {
    if (selectedItems.length < 1) {
      globalActions.notification.showMessage(text.return_creation.error_no_selected_item, 'error');
    } else if (selectedItems.find(el => el.reason === null || el.reason._id === null)) {
      globalActions.notification.showMessage(text.return_creation.error_no_return_reason, 'error');
    } else if (prepaidLabelNote.match(/[!@#$%^&*(),.?":{}|<>]/)) {
      globalActions.notification.showMessage(text.return_creation.error_special_characters, 'error');
    } else {
      globalActions.notification.showDialog(text.return_creation.confirm, text.return_creation.process_return, () => {
        if (document.querySelector('[name="order_name"]')) {
          document.querySelector('[name="order_name"]').value = '';
        }
        const mode = !selectedOpenedReturn ? 'creating' : 'updating';
        globalActions.processing.processReturnObjects(mode);
      });
    }
  }

  function orderRefundedItems(items) {
    const orders = [...new Set(items.map(item => item.order_name))];
    return orders.map(order => {
      return {
        name: order,
        items: items.filter(item => item.order_name === order && item.refund_exception === false),
        nb: orders.length
      };
    });
  }

  useEffect(() => {
    if (selectedItems.length > 0 && !restockingFeeException) {
      const restockingFee = globalActions.utils.calculateRestockingFee();
      setRestockingFee(restockingFee);
    } else {
      setRestockingFee(0);
    }
  }, [selectedItems, restockingFeeException, globalActions.utils, channel, isInternational]);

  return (
    <div className="return-form">
      <Dialog />
      <div className="columns is-multiline">
        <div className="column is-4">
          <ItemsSelection mode="return-creating" />
        </div>
        <div className="column is-8">
          <ReturnedItems />
        </div>
        {(restockingFee > 0 || restockingFeeException) && (
          <div className="column is-12">
            <label
              className="checkbox-container"
              onClick={e => {
                e.preventDefault();
                globalActions.processing.toggleRestockingFeeException();
              }}
            >
              <input name="return-form_no-return-label" type="checkbox" readOnly checked={restockingFeeException} />
              <span className="checkmark" />
              {text.return_creation.restocking_fee_exception}
            </label>
          </div>
        )}

        {selectedItems.length > 0 &&
          orderRefundedItems(selectedItems).map(order => (
            <div key={order.name} className={order.items.length > 0 ? 'column is-6' : ''}>
              {requestStatus !== 'LOADING' && order.items.length > 0 && (
                <ReturnExpectedRefund refundedItems={order.items} />
              )}
            </div>
          ))}
        <div className="column is-9">
          <ReturnNotes />
        </div>
        <div className="column is-3">
          {!selectedOpenedReturn && (
            <div className="return-form_no-return-label field">
              <label className="label">{text.return_creation.return_label_option}</label>
              <div className="select">
                <select onChange={setReturnLabelType}>
                  <option disabled={ isInternational } selected={ !isInternational } value="digital-label">{text.return_creation.digital_label}</option>
                  <option disabled={ isInternational } value="label-in-box">{text.return_creation.label_in_box}</option>
                  <option disabled={ isInternational } value="tlh">{text.return_creation.tlh}</option>
                  <option disabled={ !isInternational } selected={ isInternational } value="international">{text.return_creation.international}</option>
                </select>
              </div>
            </div>
          )}

          {!selectedOpenedReturn && returnLabelType === 'digital-label' && (
            <label className="checkbox-container return-form__add-qr-code" onClick={e => toggleAddQRcodeToEmail(e)}>
              <input name="add-qr-code" type="checkbox" readOnly checked={addQRcodeToEmail} />
              <span className="checkmark" />
              {text.return_creation.add_qr_code}
            </label>
          )}

          {!selectedOpenedReturn && selectedSeller && selectedSeller !== 'Altitude Sports' && returnLabelType === 'digital-label' && (
            <label
              className="checkbox-container return-form__ship-to-als-toggle"
              onClick={e => toggleAlsWarehouseReturn(e)}
            >
              <input name="als-warehouse-return" type="checkbox" readOnly checked={alsWarehouseReturn} />
              <span className="checkmark" />
              {text.return_creation.return_to_als}
            </label>
          )}

          <div className="return-form__action-btns">
            <button onClick={submitReturnForm} className="button is-link is-medium is-fullwidth">
              {!selectedOpenedReturn ? text.return_creation.create : text.return_creation.update}
            </button>
            <button
              onClick={() => {
                globalActions.utils.clearProcessStates();

                const $orderNameInput = document.querySelector('[name=order_name]');
                if ($orderNameInput) {
                  $orderNameInput.value = '';
                }
              }}
              className="button is-dark is-medium is-fullwidth"
            >
              {text.return_creation.void}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReturnForm;
