import React, { useState, useEffect } from 'react';
import useGlobal from '../../store';
import { formatDate } from '../../utils/formatters';

const ReturnPageTable = ({ returnObj }) => {
  const [globalState, globalActions] = useGlobal();
  const { text, channels } = globalState;
  const editable = returnObj.status === 'open' || returnObj.status === 'received';
  const [trackingEditMode, setTrackingEditMode] = useState(false);
  const [tracking, updateTracking] = useState(returnObj.tracking_number);
  const trackingInput = React.createRef();
  const [carrier, updateCarrier] = useState(returnObj.carrier);
  const [receptionNoteEditMode, setReceptionNoteEditMode] = useState(false);
  const [receptionNote, updateReceptionNote] = useState(returnObj.reception_note);
  const receptionNoteInput = React.createRef();
  const [feeExceptionEditMode, setFeeExceptionEditMode] = useState(false);
  const [retryLabelCount, setRetryLabelCount] = useState(returnObj.cp_retry_count);
  const [feeException, setFeeException] = useState(returnObj.restocking_fee_exception);

  const editColStyle = editable
    ? {
      gridTemplateColumns: '15em 1fr 10em'
    }
    : {};

    useEffect(() => {
        returnObj.cp_retry_count = retryLabelCount;
    }, [returnObj, retryLabelCount]);

  function saveNewTracking() {
    const trackingNb = trackingInput.current.value;
    let carrier;
    if (trackingNb.length === 14) {
      carrier = 'fedex';
    } else if (trackingNb.includes('7283733')) {
      carrier = 'cp';
    } else {
      carrier = null;
    }

    const update = {
      carrier,
      skip_new_prepaid_label: true,
      tracking_number: trackingNb.replace(/ /g, '')
    };

    function confirmNewTracking() {
      globalActions.db.updateReturnObj(update, returnObj._id);
      updateTracking(update.tracking_number);
      updateCarrier(update.carrier);
      setTrackingEditMode(false);
    }

    function cancelNewTracking() {
      setTrackingEditMode(false);
    }

    globalActions.notification.showDialog(
      text.return_page.confirm,
      `${text.return_page.set_tracking} ${update.tracking_number}?`,
      confirmNewTracking,
      cancelNewTracking
    );
  }

  function saveNewReceptionNote() {
    const update = {
      reception_note: receptionNoteInput.current.value
    };

    function confirmNewReceptionNote() {
      globalActions.db.updateReturnObj(update, returnObj._id);
      updateReceptionNote(update.reception_note);
      setReceptionNoteEditMode(false);
    }

    function cancelNewReceptionNote() {
      setReceptionNoteEditMode(false);
    }

    globalActions.notification.showDialog(
      text.return_page.confirm,
      `${text.return_page.set_reception_note} "${update.reception_note}"?`,
      confirmNewReceptionNote,
      cancelNewReceptionNote
    );
  }

  function saveNewRestockingFeeStatus() {
    const update = {
      restocking_fee_exception: feeException
    };

    function confirmNewFeeException() {
      globalActions.db.updateReturnObj(update, returnObj._id);
      setFeeException(update.restocking_fee_exception);
      setFeeExceptionEditMode(false);
    }

    function cancelNewFeeException() {
      setFeeExceptionEditMode(false);
    }

    globalActions.notification.showDialog(
      text.return_page.confirm,
      update.restocking_fee_exception ? text.return_page.set_fee_exception : text.return_page.charge_restocking_fee,
      confirmNewFeeException,
      cancelNewFeeException
    );
  }

  function receivedAt() {
    if (returnObj.processed_at && !returnObj.received_at) {
      return text.return_page.no_receiving;
    } else if (returnObj.received_at) {
      return formatDate(returnObj.received_at);
    } else {
      return '';
    }
  }

  function removeRetryCounter() {
      const newRetryLabelCount = returnObj.cp_retry_count - 1

      globalActions.db.updateReturnObj({ cp_retry_count: newRetryLabelCount }, returnObj._id);

      setRetryLabelCount(newRetryLabelCount);
  }

  const destination_name = returnObj?.destination_return_policy?.return_to?.destination_name;
  const civic_number = returnObj?.destination_return_policy?.return_to?.destination_address?.civic_number;
  const street_name = returnObj?.destination_return_policy?.return_to?.destination_address?.street_name;
  const city_name = returnObj?.destination_return_policy?.return_to?.destination_address?.city_name;
  const state = returnObj?.destination_return_policy?.return_to?.destination_address?.state;
  const country = returnObj?.destination_return_policy?.return_to?.destination_address?.Country;
  const zip_code = returnObj?.destination_return_policy?.return_to?.destination_address?.zip_code;

  return (
    <div className="return__table">
      <div className="return__table__row" style={editColStyle}>
        <div className="return__table__cell">{text.return_page.order}:</div>
        <div className="return__table__cell">
          {returnObj.order && returnObj.channel && (
            <a
              href={`${channels[returnObj.channel].shop_url}/orders/${returnObj.order.split('--')[1]}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {returnObj.order_name}
            </a>
          )}
        </div>
        {editable && <div className="return__table__cell"></div>}
      </div>

      <div className="return__table__row" style={editColStyle}>
        <div className="return__table__cell">{text.return_page.status}:</div>
        <div className="return__table__cell">{returnObj.status}</div>
        {editable && <div className="return__table__cell"></div>}
      </div>

      <div className="return__table__row" style={editColStyle}>
        <div className="return__table__cell">{text.return_page.bundle_id}:</div>
        <div className="return__table__cell">{returnObj.bundle_id}</div>
        {editable && <div className="return__table__cell"></div>}
      </div>

      <div className="return__table__row" style={editColStyle}>
        <div className="return__table__cell">{text.return_page.seller}:</div>
        <div className="return__table__cell">{returnObj.seller ? returnObj.seller : null}</div>
        {editable && <div className="return__table__cell"></div>}
      </div>

      <div className="return__table__row" style={editColStyle}>
        <div className="return__table__cell">{text.return_page.restocking_fee_exception}:</div>
        <div className="return__table__cell">
          {!feeExceptionEditMode && <span>{feeException ? text.return_page.yes : text.return_page.no}</span>}
          {feeExceptionEditMode && (
            <div className="select">
              <select onChange={e => setFeeException(e.target.value === 'true')} value={feeException}>
                <option value="true">{text.return_page.yes}</option>
                <option value="false">{text.return_page.no}</option>
              </select>
            </div>
          )}
        </div>
        {editable && (
          <div className="return__table__cell">
            {!feeExceptionEditMode && (
              <button onClick={() => setFeeExceptionEditMode(true)} className="button is-link">
                {text.return_page.edit}
              </button>
            )}
            {feeExceptionEditMode && (
              <>
                <button onClick={saveNewRestockingFeeStatus} className="button is-link return__save-btn">
                  {text.return_page.save}
                </button>
                <button onClick={() => setFeeExceptionEditMode(false)} className="button is-dark">
                  {text.return_page.cancel}
                </button>
              </>
            )}
          </div>
        )}
      </div>

      <div className="return__table__row" style={editColStyle}>
        <div className="return__table__cell">{text.return_page.tracking}:</div>
        <div className="return__table__cell">
          {trackingEditMode && (
            <input autoFocus ref={trackingInput} className="input" type="text" placeholder={tracking}></input>
          )}
          {!trackingEditMode && (
            <>
              {tracking && tracking.length > 0 && <span>{tracking}</span>}
              {(!tracking || tracking.length === 0) &&
                editable &&
                returnObj.status !== 'received' &&
                !returnObj.skip_new_prepaid_label &&
                (!returnObj.cp_retry_count || retryLabelCount < 5) && (
                  <span className="is-italic">{text.return_page.label_creation_pending}</span>
                )}

              {(!tracking || tracking.length === 0) &&
                editable &&
                returnObj.status !== 'received' &&
                !returnObj.skip_new_prepaid_label &&
                returnObj.cp_retry_count &&
                retryLabelCount >= 5 && (
                  <div>
                      <span className="is-italic">
                          {text.return_page.label_creation_failed}
                      </span>
                      <button onClick={() => removeRetryCounter()} className="button is-link">{text.return_page.retryPrepaidLabel}</button>
                  </div>
                )}

              {(!tracking || tracking.length === 0) &&
                editable &&
                returnObj.status !== 'received' &&
                returnObj.skip_new_prepaid_label && (
                  <span className="is-italic">{text.return_page.please_input_tracking}</span>
                )}
            </>
          )}
        </div>
        {editable && (
          <div className="return__table__cell">
            {!trackingEditMode && returnObj.status !== 'received' && (
              <button onClick={() => setTrackingEditMode(true)} className="button is-link">
                {text.return_page.edit}
              </button>
            )}
            {trackingEditMode && (
              <>
                <button onClick={saveNewTracking} className="button is-link return__save-btn">
                  {text.return_page.save}
                </button>
                <button onClick={() => setTrackingEditMode(false)} className="button is-dark">
                  {text.return_page.cancel}
                </button>
              </>
            )}
          </div>
        )}
      </div>

      <div className="return__table__row" style={editColStyle}>
        <div className="return__table__cell">{text.return_page.destination_return_policy}:</div>
        <div className="return__table__cell">
          {destination_name}<br />
          {civic_number} {street_name}<br />
          {city_name}{state != null ? ', ' : ''}{state}{country != null ? ', ' : ''}{country}<br />
          {zip_code}
        </div>
        {editable && <div className="return__table__cell"></div>}
      </div>

      <div className="return__table__row" style={editColStyle}>
        <div className="return__table__cell">{text.return_page.label_type}:</div>
        <div className="return__table__cell">
          {returnObj.return_label_type ? text.return_page[returnObj.return_label_type] : null}
        </div>
        {editable && <div className="return__table__cell"></div>}
      </div>

      <div className="return__table__row" style={editColStyle}>
        <div className="return__table__cell">{text.return_page.carrier}:</div>
        <div className="return__table__cell">{carrier ? carrier : ''}</div>
        {editable && <div className="return__table__cell"></div>}
      </div>

      <div className="return__table__row" style={editColStyle}>
        <div className="return__table__cell">{text.return_page.deposco_id}:</div>
        <div className="return__table__cell">{returnObj.deposco_id}</div>
        {editable && <div className="return__table__cell"></div>}
      </div>

      <div className="return__table__row" style={editColStyle}>
        <div className="return__table__cell">{text.return_page.channel}:</div>
        <div className="return__table__cell">{returnObj.channel}</div>
        {editable && <div className="return__table__cell"></div>}
      </div>

      <div className="return__table__row" style={editColStyle}>
        <div className="return__table__cell">{text.return_page.customer_id}:</div>
        <div className="return__table__cell">
          {returnObj.channel && returnObj.customer_id && <a
            href={`${channels[returnObj.channel].shop_url}/customers/${returnObj.customer_id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {returnObj.customer_id}
          </a>}
        </div>
        {editable && <div className="return__table__cell"></div>}
      </div>

      <div className="return__table__row" style={editColStyle}>
        <div className="return__table__cell">{text.return_page.customer_tags}:</div>
        <div className="return__table__cell">{returnObj.customer_tags}</div>
        {editable && <div className="return__table__cell"></div>}
      </div>

      <div className="return__table__row" style={editColStyle}>
        <div className="return__table__cell">{text.return_page.created_at}:</div>
        <div className="return__table__cell">
          {returnObj.created_at === null ? 'null' : formatDate(returnObj.created_at)}
        </div>
        {editable && <div className="return__table__cell"></div>}
      </div>

      <div className="return__table__row" style={editColStyle}>
        <div className="return__table__cell">{text.return_page.received_at}:</div>
        <div className="return__table__cell">{receivedAt()}</div>
        {editable && <div className="return__table__cell"></div>}
      </div>

      <div className="return__table__row" style={editColStyle}>
        <div className="return__table__cell">{text.return_page.processed_at}:</div>
        <div className="return__table__cell">
          {returnObj.processed_at === null ? 'null' : formatDate(returnObj.processed_at)}
        </div>
        {editable && <div className="return__table__cell"></div>}
      </div>

      <div className="return__table__row" style={editColStyle}>
        <div className="return__table__cell">{text.return_page.prepaid_label_note}:</div>
        <div className="return__table__cell">{returnObj.prepaid_label_note}</div>
        {editable && <div className="return__table__cell"></div>}
      </div>

      <div className="return__table__row" style={editColStyle}>
        <div className="return__table__cell">{text.return_page.reception_note}:</div>
        <div className="return__table__cell">
          {receptionNoteEditMode && (
            <input autoFocus ref={receptionNoteInput} className="input" type="text" placeholder={receptionNote}></input>
          )}
          {!receptionNoteEditMode && <span>{receptionNote}</span>}
        </div>
        {editable && (
          <div className="return__table__cell">
            {!receptionNoteEditMode && (
              <button onClick={() => setReceptionNoteEditMode(true)} className="button is-link">
                {text.return_page.edit}
              </button>
            )}
            {receptionNoteEditMode && (
              <>
                <button onClick={saveNewReceptionNote} className="button is-link return__save-btn">
                  {text.return_page.save}
                </button>
                <button onClick={() => setReceptionNoteEditMode(false)} className="button is-dark">
                  {text.return_page.cancel}
                </button>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ReturnPageTable;
