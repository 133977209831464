import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth0 } from '../authService/react-auth0-wrapper';
import useGlobal from '../store';
import userPermissions from '../utils/userPermissions';

const PrivateRoute = ({ component: Component, path, access, ...rest }) => {
  const { loading, isAuthenticated, loginWithRedirect, accessToken } = useAuth0();
  const [globalState] = useGlobal();
  const { text } = globalState;
  const [hasAccess, setHasAccess] = useState(false);

  useEffect(() => {
    if (loading || isAuthenticated) {
      setHasAccess(userPermissions(accessToken, access));
      return;
    }
    const fn = async () => {
      await loginWithRedirect({
        appState: { targetUrl: path }
      });
    };
    fn();
  }, [loading, isAuthenticated, loginWithRedirect, path, access, accessToken]);

  const render = props =>
    isAuthenticated === true ? (
      <Component {...props} />
    ) : (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: props.location }
        }}
      />
    );

  return (
    <>
      {hasAccess && <Route path={path} render={render} {...rest} />}
      {!hasAccess && (
        <main className="container">
          <section className="section">
            <h1 className="title is-5">{text.homepage.no_access}</h1>
          </section>
        </main>
      )}
    </>
  );
};

export default PrivateRoute;
